import {
  makeStyles,
} from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme:Theme) => ({
  loadingAvatar: {
    animation: `$myEffect 3000ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite',
  },
  '@keyframes myEffect': {
    '0%': { transform: 'translate(0,  0px)' },
    '50%': { transform: ' translate(0, 7px)' },
    '100%': { transform: ' translate(0, -0px)' },
  },
}));

export default useStyles;
