import React from 'react';
import { TextField, Typography } from '@mui/material';
import AlertDialog from '../AlertDialog';
import avatarFinger from '../../assets/avatar_finger.svg';
import analytics from '../../utils/analytics';

type PasswordDialogProps = {
    isTriggered: boolean,
    handleSubmitPassword: (password:string)=>void;
    button: React.ReactElement,
}

export default function PasswordDialog(props:PasswordDialogProps) {
  const {
    isTriggered, handleSubmitPassword, button,
  } = props;
  const [password, setPassword] = React.useState('');
  React.useEffect(() => {
    if (isTriggered) {
      analytics.page('PLR - DOCUMENTOS', 'CONTRASEÑA PDF');
    }
  }, [isTriggered]);
  const closeAction = () => {
    analytics.page('PLR - DOCUMENTOS', 'PDF BLOQUEADO');
  };
  return (
    <AlertDialog
      dialogContent={(
        <div style={{ textAlign: 'center' }}>
          <div>
            <object
              data={avatarFinger}
              width="70px"
              aria-labelledby="Avatar"
            />
          </div>
          <Typography fontWeight="bold" gutterBottom>
            El archivo PDF que
            subiste está bloqueado

          </Typography>
          <Typography gutterBottom maxWidth={350}>
            Por favor ingresa la
            contraseña del PDF para que podamos acceder a él

          </Typography>
          <br />
          <TextField
            style={{ maxWidth: 300 }}
            placeholder="Contraseña PDF"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </div>
      )}
      button={button}
      isTriggered={isTriggered}
      action={() => handleSubmitPassword(password)}
      closeAfterSubmit
      submitButtonDisabled={password.length === 0}
      onCloseAction={closeAction}
    />
  );
}
