import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { prettifyRut, checkRut } from 'react-rut-formatter';
import {
  TextField,
  Grid,
  Typography,
  Paper,
  Button,
  Alert,
  Fade,
  Snackbar,
} from '@mui/material';
import { verifyEmail } from '../../utils/helpers';
import { postSignUp } from '../../queries/account';
import avatar from '../../assets/avatar_excited.svg';
import useStyles from './styles';
import analytics from '../../utils/analytics';

type RegisterFormProps = {
  givenIdNumber: string|'',
  givenFirstName: string|'',
  givenLastName: string|''
}

export default function RegisterForm(props: RegisterFormProps) {
  const { givenIdNumber, givenFirstName, givenLastName } = props;
  const classes = useStyles();
  const history = useHistory();
  const [idNumber, setIdNumber] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [warning, setWarning] = React.useState('');
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    setIdNumber(givenIdNumber);
    setFirstName(givenFirstName);
    setLastName(givenLastName);
    analytics.page('PU - LOGIN', 'CREAR CUENTA');
  }, [givenIdNumber, givenFirstName, givenLastName]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setWarning('');
  };
  const createAccountSubmit = async () => {
    setError(false);
    setWarning('');
    if (!verifyEmail(email)) {
      return setWarning('Por favor ingrese un E-mail válido.');
    }
    if ([idNumber, firstName, lastName, email].includes('')) {
      return setError(true);
    }
    try {
      await postSignUp(idNumber, firstName, lastName, email);
      const hash = window.location.hash.includes('UR') ? '#UR' : '';
      return history.push(`/account/set-password${hash}`);
    } catch (e) {
      if (e === 400) {
        setWarning('Ya tienes cuenta con nosotros, solo debes iniciar sesión para ingresar.');
        return setTimeout(() => {
          history.push('/account/login');
        }, 2000);
      }
      if (e === 412) {
        setError(true);
        return setWarning('Este e-mail ya se encuentra en uso. Si piensas que es un error, por favor contáctate con nosotros.');
      } if (e === 422) {
        setError(true);
        return setWarning('Recuerde completar todos los campos.');
      }
      return setWarning('Ha ocurrido un error');
    }
  };

  return (
    <Fade
      in
      {...({ timeout: 1000 })}
    >
      <Grid
        item
        xs={12}
        lg={5}
        md={6}
        sm={10}
      >
        <Paper className={classes.paper} elevation={1} style={{ borderRadius: '10px' }}>
          <div>
            <object data={avatar} width="35px" aria-labelledby="Bancamin" />
            <Typography variant="h5" align="center">
              <b>¡Te damos la bienvenida!</b>
            </Typography>
            <Typography variant="h6" align="center" style={{ marginBottom: 10 }}>
              <b>Para comenzar debes crear tu cuenta.</b>
            </Typography>
            <Typography variant="body1" align="center" style={{ marginBottom: 10 }}>
              Ingresa tus datos personales
            </Typography>
            <Snackbar open={!!warning} autoHideDuration={5000} onClose={handleClose}>
              <Alert severity="warning">{warning}</Alert>
            </Snackbar>

            <TextField
              style={{ marginBlock: 10 }}
              value={prettifyRut(idNumber)}
              placeholder="Ingresa tu RUT"
              variant="outlined"
              error={error && idNumber === ''}
              helperText={(error && idNumber === '') ? 'Por favor ingrese su RUT' : ''}
              onChange={(e) => setIdNumber(e.target.value)}
              onBlur={(e) => checkRut(e.target.value)}
              required
            />
            <TextField
              style={{ marginBottom: 10 }}
              value={firstName}
              placeholder="Ingresa tu nombre"
              error={error && firstName === ''}
              helperText={(error && firstName === '') ? 'Por favor ingrese su nombre' : ''}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              style={{ marginBottom: 10 }}
              value={lastName}
              placeholder="Ingresa tu apellido"
              error={error && lastName === ''}
              helperText={(error && lastName === '') ? 'Por favor ingrese su apellido' : ''}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              style={{ marginBottom: 10 }}
              value={email}
              placeholder="Ingresa tu email"
              error={error && email === ''}
              helperText={(error && email === '') ? 'Por favor ingrese su email' : ''}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div style={{ width: '100%' }}>
              <Typography marginBottom="2%">
                <Link
                  to="/preloan"
                  target="_self"
                  rel="noopener"
                  className={classes.link}
                  style={{ fontWeight: 'bold' }}
                >
                  ¿Primera vez aquí? ¡Solicita un producto con nosotros!
                </Link>
              </Typography>
              <Typography marginBottom="2%">
                <Link
                  to="/account/login"
                  target="_self"
                  rel="noopener"
                  className={classes.link}
                >
                  ¿Ya tienes cuenta? Inicia sesión
                </Link>
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              style={{ marginBottom: 10 }}
              onClick={createAccountSubmit}
            >
              Crear
            </Button>
          </div>
        </Paper>
      </Grid>
    </Fade>
  );
}
