import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import BancameAlert from '../Alert';
import bancoBice from './enums';
import useStyles from './styles';
import avatarStressed from '../../assets/avatar_stressed.svg';
import analytics from '../../utils/analytics';

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

interface TransferDetailsDialogInterface{
  hasDelinquency: boolean,
  unpaidDelinquencyAmount: number,
  unpaidInstallmentAmount: number,
  paymentMethod: string,
}

function TransferDetailsDialog(props:TransferDetailsDialogInterface) {
  const {
    paymentMethod,
    hasDelinquency,
    unpaidDelinquencyAmount,
    unpaidInstallmentAmount,
  } = props;
  const totalToDeposit = unpaidInstallmentAmount + unpaidDelinquencyAmount;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleClickOpen = (isLogin = false) => {
    setOpen(true);
    if (isLogin) {
      analytics.page('PU - LOGIN', 'DEUDA PENDIENTE');
    } else {
      analytics.page('PU - TU CREDITO', 'DATOS DE PAGO');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const copyTextToClipboard = () => {
    analytics.track('Click Botón', {
      text: 'Copiar',
      initiator: 'usuario',
    });
    navigator.clipboard.writeText(
      `Bancame Trust Spa
      77.673.115-3
      Banco BICE
      Cuenta Corriente
      01-38366-3
      info@banca.me`,
    );
  };

  const handleOpenWhatsApp = () => {
    analytics.track('Click Botón', {
      text: 'Listo, yá transferí',
      initiator: 'usuario',
    });
    window.open(
      `https://api.whatsapp.com/send/?phone=56920711980&text=¡Hola!+Hice+una+trasferencia+por+el+monto+total+del+prepago+por+$${hasDelinquency}.`,
    );
  };

  React.useEffect(() => {
    if (hasDelinquency && paymentMethod !== 'dpp') {
      handleClickOpen(true);
    }
  }, []);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleClickOpen()}
        sx={{ margin: { xs: 2, lg: 'auto 5px' } }}
      >
        Datos de pago
      </Button>
      <Dialog
        className={classes.Dialog}
        open={open}
        TransitionComponent={Transition}
        style={{ textAlign: 'center', margin: 0 }}
        maxWidth="md"
      >
        {(!hasDelinquency) && (
        <DialogTitle
          style={{ textAlign: 'left' }}
        >
          Datos para la transferencia
        </DialogTitle>
        )}
        <DialogContent>
          {hasDelinquency && paymentMethod === 'pac'
          && (
          <div>
            <img className={classes.avatar} alt="AvatarStressed" src={avatarStressed} />
            <Typography
              variant="body1"
              fontWeight="bold"
              align="center"
              gutterBottom
            >
              Tienes una deuda pendiente
            </Typography>
            <Typography
              variant="body2"
              align="center"
              gutterBottom
              color="text.secondary"
            >
              Debes hacer una transferencia para ponerte al día
            </Typography>
            <Typography
              variant="body1"
              fontWeight="bold"
              align="center"
              gutterBottom
            >
              Desgloce de pagos pendientes
            </Typography>
            <div>
              <Typography
                variant="body2"
                align="center"
                gutterBottom
                color="text.secondary"
                display="inline"
              >
                + Monto de cuotas pendientes
              </Typography>
              <Typography
                variant="body2"
                align="center"
                gutterBottom
                color="secondary"
                fontWeight="bold"
                display="inline"
              >
                {' $ '}
                {unpaidInstallmentAmount.toLocaleString()}
              </Typography>
            </div>
            <div>
              <Typography
                variant="body2"
                align="center"
                gutterBottom
                color="text.secondary"
                display="inline"
              >
                + Intereses devengados
              </Typography>
              <Typography
                variant="body2"
                align="center"
                gutterBottom
                color="secondary"
                fontWeight="bold"
                display="inline"
              >
                {' $ '}
                {unpaidDelinquencyAmount.toLocaleString()}
              </Typography>
            </div>
            <Divider />
            <div>
              <Typography
                variant="body2"
                align="center"
                gutterBottom
                color="text.secondary"
                display="inline"
              >
                Total
              </Typography>
              <Typography
                variant="body2"
                align="center"
                gutterBottom
                color="secondary"
                fontWeight="bold"
                display="inline"
              >
                {' $ '}
                {totalToDeposit.toLocaleString()}
              </Typography>
            </div>
            <br />
          </div>
          )}
          <Typography
            variant="body1"
            fontWeight="bold"
            align="left"
            gutterBottom
          >
            Datos bancarios
          </Typography>
          <Grid
            container
            sx={{
              padding: '2.5%',
              alignItems: 'center',
              borderRadius: '15px',
            }}
            justifyContent="center"
          >

            <Grid
              item
              xs={12}
            >

              <Typography
                variant="body1"
                align="left"
                gutterBottom
                color="secondary"
              >
                {bancoBice.name.value}
              </Typography>
              <Divider />
              {Object.keys(bancoBice).map((key) => (
                key !== 'name'
                && (
                  <div key={key} style={{ display: 'flex', placeContent: 'space-between' }}>
                    <Typography
                      variant="body2"
                      align="left"
                    >
                      {bancoBice[key].label}
                      :
                    </Typography>
                    <Typography
                      variant="body2"
                      align="right"
                      fontWeight="bold"
                    >
                      {bancoBice[key].value}
                    </Typography>
                  </div>
                )
              ))}
              <Divider />
            </Grid>
            <Grid item xs={2}>
              <BancameAlert
                button={(
                  <IconButton
                    onClick={copyTextToClipboard}
                    size="large"
                  >
                    <Tooltip
                      title="Copiar datos"
                    >
                      <FileCopyOutlinedIcon style={{ color: theme.palette.grey[600], fontSize: '40px' }} />
                    </Tooltip>
                  </IconButton>
)}
                successMessage="Has copiado los datos con éxito"
                errorMessage="Ha ocurrido un error copiando el texto"
                action={copyTextToClipboard}
              />

            </Grid>
          </Grid>
          {hasDelinquency
            && (
              <div className={classes.actionButtons}>
                <Button variant="contained" onClick={handleOpenWhatsApp}>
                  Listo, ya transferí
                </Button>
                <Button
                  variant="contained"
                  onClick={handleClose}
                >
                  Recuérdamelo más tarde

                </Button>
              </div>
            )}
        </DialogContent>
        {!hasDelinquency
        && (
        <DialogActions>
          <Button
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </DialogActions>
        )}
      </Dialog>

    </>
  );
}

export default TransferDetailsDialog;
