import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import type { UserAccountComponent } from '../../../../types/account';
import { getUserReport } from '../../../../queries/account';
import AccountNavbar from '../../../../components/AccountNavbar';
import UserReport from '../../../../feature/account/UserReport';
import jwtDecode from '../../../../utils/jwtDecode';

type UserReportParams = {
    reportId: string;
  };

export default function UserReportView(props:UserAccountComponent) {
  const { token } = props;
  const { reportId } = useParams<UserReportParams>();
  const { hasLoan, hasInvestment } = jwtDecode(token);
  const componentRef = useRef(null);

  const {
    userReport,
    userReportError,
    userReportLoading,
  } = getUserReport(token, window.location.href.includes('demo') ? 'demo' : reportId);

  return (
    <>
      <AccountNavbar
        showLoan={hasLoan}
        showInvestment={hasInvestment}
        showWhatsapp={!userReportLoading}
        tab="Guia.Me"
      />
      {!userReportLoading && !userReportError && userReport
    && (
    <UserReport
      URHistory={userReport.history}
      pdf={false}
      componentRef={componentRef}
      reportId={reportId}
    />
    )}
    </>
  );
}
