import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  creditOverview: {
    padding: '2.5%',
    backgroundColor: 'white',
    width: '95%',
    marginLeft: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      margin: 'auto',
    },
  },
  avatar: {
    height: '100px',
    [theme.breakpoints.down('md')]: {
      height: '60px',
    },

  },
}));

export default useStyles;
