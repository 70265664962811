import React from 'react';
import {
  CircularProgress,
  Fade,
  Grid,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import jwtDecode from '../../../utils/jwtDecode';
import avatar from '../../../assets/avatar_finger.svg';
import avatarNervous from '../../../assets/avatar_nervous.svg';
import useStyles from './styles';
import ViewTitle from '../../../components/ViewTitle';
import { postClaveUnicaHistory, postPreLoanRequestRefinance, postCheckIsClaveUnicaUpdated } from '../../../queries/account';
import DataLoading from '../../../components/DataLoading';
import ClaveUnicaForm from '../../../components/ClaveUnicaForm';
import RefinanceBaseView from './refinanceBaseView';
import analytics from '../../../utils/analytics';

type RefinanceOverviewProps ={
    token: string,
    type: 'refinanceRP' | 'cashout'
}

export default function RefinanceOverview(props: RefinanceOverviewProps) {
  const classes = useStyles();
  const history = useHistory();
  const { token, type } = props;
  const { idNumber } = jwtDecode(token);
  const [errorView, setErrorView] = React.useState(false);
  const [successView, setSuccessView] = React.useState(false);
  const [shouldAskForClaveUnica, setShouldAskForClaveUnica] = React.useState(false);
  const [enableLoading, handleEnableLoading] = React.useState(false);
  const [isCUChecking, setIsCUChecking] = React.useState(false);

  const backToAccount = () => {
    history.push('/account');
  };

  const createPreLoanRequestRefinance = async () => {
    try {
      handleEnableLoading(true);
      await postPreLoanRequestRefinance(token, type);
      handleEnableLoading(false);
      setSuccessView(true);
    } catch (e) {
      setErrorView(true);
      handleEnableLoading(false);
    }
  };

  const goToReduceRefinanceWhatsapp = () => {
    analytics.track('Click Boton', { text: 'Hablar con servicio al cliente', initiator: 'usuario' });
    window.open('https://wa.me/56920711980?text=¡Hola!%20Quiero%20refinanciar%20mi%20crédito%20y%20he%20actualizado%20mis%20datos%20con%20Banca.Me.', '_blank');
  };

  const claveUnicaSuccess = () => {
    setShouldAskForClaveUnica(false);
    handleEnableLoading(false);
    createPreLoanRequestRefinance();
  };

  const handleCheckCUupdated = async () => {
    setIsCUChecking(true);
    try {
      await postCheckIsClaveUnicaUpdated(token);
      createPreLoanRequestRefinance();
    } catch (e) {
      setShouldAskForClaveUnica(true);
    }
    setIsCUChecking(false);
  };

  React.useEffect(() => {
    handleCheckCUupdated();
  }, []);

  React.useEffect(() => {
    if (successView) {
      if (type === 'refinanceRP') {
        analytics.page('PU - TU CREDITO', 'SOLICITUD DE REFINANCE ENVIADA');
      }
      if (type === 'cashout') {
        analytics.page('PU - TU CREDITO', 'REFINANCIAR CON AUMENTO');
      }
    }
    if (shouldAskForClaveUnica) {
      if (type === 'refinanceRP') {
        analytics.page('PU - TU CREDITO', 'ACTUALIZAR CU REFINANCE');
      }
      if (type === 'cashout') {
        analytics.page('PU - TU CREDITO', 'ACTUALIZAR CU CASHOUT');
      }
    }
  }, [type, successView, shouldAskForClaveUnica]);

  return (
    <div style={{ width: '100%' }}>
      <Grid
        container
        justifyContent="center"
        rowGap={5}
      >
        <Grid
          item
          xs={12}
          lg={12}
        >
          <div
            className={classes.backButton}
            onClick={backToAccount}
            aria-hidden
          >
            <ArrowBackIcon style={{ fontSize: '18px', margin: 'auto 8px' }} />
            Volver
          </div>

        </Grid>
        <ViewTitle
          title={`Refinanciar mi crédito${type}` === 'cashout' ? ' con aumento' : ''}
          color="secondary"
          variant="h4"
        />
        <Fade
          in
          {...({ timeout: 2500 })}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            textAlign="center"
          >
            {enableLoading && (
            <div style={{ width: '500px' }}>
              <DataLoading
                firstText="Estamos creando tu evaluación de refinanciamiento"
                secondText="Por favor espera unos segundos"
              />
            </div>
            )}
            <div>
              <img src={errorView ? avatarNervous : avatar} alt="Avatar" className={classes.prepayAvatar} />
            </div>
            {isCUChecking
            && (
            <>
              <CircularProgress />
              <Typography fontWeight="bold" gutterBottom>Cargando...</Typography>
            </>
            )}
            <div className={classes.actionButtons} style={{ width: '100%', marginBottom: 20 }}>
              {successView && (
                <RefinanceBaseView
                  callback={goToReduceRefinanceWhatsapp}
                  firstMessage="¡Hemos recibido tu solicitud!"
                  secondMessage="Te estaremos contactando en breve. Con lo ingresado tenemos la
                    informacion necesaria para evaluar tu refinanciamiento. Si prefieres
                    hablar con nosotros, haz click
                    en el siguiente botón."
                  buttonText="Hablar con servicio al cliente"
                />
              )}
            </div>
            {(shouldAskForClaveUnica) && (
            <>
              <Typography
                variant="h5"
                fontWeight="bold"
                gutterBottom
                style={{ margin: '10px' }}
              >
                Para poder evaluar tu solicitud, debemos actualizar tus datos
              </Typography>
              <ClaveUnicaForm<void>
                token={token}
                partner="Banca.Me"
                endpoint={postClaveUnicaHistory}
                pageCall={{ category: 'PU - TU CREDITO', name: type === 'refinanceRP' ? 'DIALOGO CU REFINANCE' : 'DIALOGO CU CASHOUT' }}
                idNumber={idNumber}
                origin={type}
                callback={claveUnicaSuccess}
                firstMessage={(
                  <Typography gutterBottom>
                    ¡Al ingresar tu
                    {' '}
                    <b>ClaveÚnica</b>
                    {' '}
                    podemos darte una respuesta inmediata sobre tu aplicación
                    para que
                    {' '}
                    <b>
                      puedas refinanciar tu crédito en menos de 24 horas!
                    </b>
                    {' '}
                  </Typography>
                      )}
              />
            </>
            )}
            {(errorView) && (
            <RefinanceBaseView
              callback={createPreLoanRequestRefinance}
              firstMessage="Hemos tenido un error en tu consulta"
              secondMessage="Si quieres conversar con un ejecutivo, presiona el icono de Whatsapp mas abajo."
              buttonText="Reintentar"
            />
            )}
            <div className={classes.footerInfo} style={{ width: '90%' }}>
              <div
                className={classes.footerIcon}
                style={{ placeContent: 'center' }}
              >
                <InfoOutlinedIcon style={{ fontSize: '20px' }} />
              </div>
              <Typography
                display="inline"
                variant="body2"
                fontWeight="light"
                className={classes.footerText}
              >
                Se te contactará oportunamente con el resultado de tu solicitud
              </Typography>
            </div>
          </Grid>
        </Fade>
      </Grid>
    </div>
  );
}
