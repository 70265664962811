/* eslint-disable camelcase */
import React from 'react';
import { CardPayment, StatusScreen } from '@mercadopago/sdk-react';
import * as Sentry from '@sentry/react';
import { ICardPaymentFormData, ICardPaymentBrickPayer } from '@mercadopago/sdk-react/bricks/cardPayment/type';
import { IBrickError } from '@mercadopago/sdk-react/bricks/util/types/common';
import { Fade, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import BancameBackdrop from '../BancameBackdrop';
import BackButton from '../BackButton';
import type { MELICardPaymentBrickType } from './types';
import analytics from '../../utils/analytics';

type MeliComponentError = {
  [key: string]: string,
};

const meliComponentError: MeliComponentError = {
  already_initialized: 'Ya inicializado',
  amount_is_not_number: 'El monto no es un número',
  amount_is_not_number_in_update: 'El monto no es un número en la actualización',
  card_token_creation_failed: 'Error al crear el token de la tarjeta',
  container_not_found: 'Contenedor no encontrado',
  fields_setup_failed: 'Configuración de campos fallida',
  fields_setup_failed_after_3_tries: 'Configuración de campos fallida después de 3 intentos',
  financial_institution_not_found: 'Institución financiera no encontrada',
  get_address_data_failed: 'Error al obtener datos de la dirección',
  get_card_bin_payment_methods_failed: 'Error al obtener métodos de pago por BIN de la tarjeta',
  get_card_issuers_failed: 'Error al obtener emisores de la tarjeta',
  get_identification_types_failed: 'Error al obtener tipos de identificación',
  get_mexico_payment_points_failed: 'Error al obtener puntos de pago en México',
  get_config_assets_failed: 'Error al obtener configuración de recursos',
  get_payment_installments_failed: 'Error al obtener cuotas de pago',
  empty_installments: 'Cuotas vacías',
  get_payment_methods_failed: 'Error al obtener métodos de pago',
  get_preference_details_failed: 'Error al obtener detalles de preferencia',
  get_saved_cards_failed: 'Error al obtener tarjetas guardadas',
  incomplete_fields: 'Campos incompletos',
  incorrect_initialization: 'Inicialización incorrecta',
  invalid_preference_purpose: 'Propósito de preferencia inválido',
  invalid_sdk_instance: 'Instancia de SDK inválida',
  missing_amount_property: 'Propiedad de monto faltante',
  missing_site_property: 'Propiedad de sitio faltante',
  missing_container_id: 'ID de contenedor faltante',
  missing_locale_property: 'Propiedad de configuración regional faltante',
  missing_payment_information: 'Información de pago faltante',
  missing_payment_type: 'Tipo de pago faltante',
  missing_required_callbacks: 'Callbacks requeridos faltantes',
  missing_texts: 'Textos faltantes',
  no_preference_provided: 'No se proporcionó preferencia',
  no_chunk_path_provided: 'Ruta de chunk no proporcionada',
  secure_fields_card_token_creation_failed: 'Error al crear token de tarjeta en campos seguros',
  settings_empty: 'Configuración vacía',
  translation_key_not_found: 'Clave de traducción no encontrada',
  unauthorized_payment_method: 'Método de pago no autorizado',
  update_preference_details_failed: 'Error al actualizar detalles de preferencia',
  validations_parameter_null: 'Parámetro de validación nulo',
  get_chunk_failed: 'Error al obtener chunk',
  get_saved_cards_on_bricks_api_failed: 'Error al obtener tarjetas guardadas en la API de Bricks',
  window_redirect_was_blocked: 'La redirección de la ventana fue bloqueada',
  missing_required_review_props: 'Propiedades requeridas de revisión faltantes',
  no_payment_method_for_provided_bin: 'No hay método de pago para el BIN proporcionado',
  payment_method_not_in_allowed_types: 'El método de pago no está en los tipos permitidos',
  payment_method_not_in_allowed_methods: 'El método de pago no está en los métodos permitidos',
  no_installments_in_selected_range: 'No hay cuotas en el rango seleccionado',
  no_issuers_found_for_card: 'No se encontraron emisores para la tarjeta',
};

const getMeliError = (value: string) => {
  if (meliComponentError[value]) {
    return meliComponentError[value];
  }
  return undefined;
};

export default function MELICardPaymentBrick(props: MELICardPaymentBrickType) {
  const {
    token,
    title,
    initialization,
    paymentMethods,
    productId,
    redirectURL,
    returnURL,
    successRedirectLabels,
    postMELIPayment,
  } = props;
  const customization = {
    paymentMethods,
  };
  const history = useHistory();
  const theme = useTheme();
  const [backdropType, setBackdropType] = React.useState<'loading' | 'error'>('loading');
  const [openBackdrop, setOpenBackdrop] = React.useState(true);
  const [paymentId, setPaymentId] = React.useState<string | undefined>(undefined);
  const [paymentSuccess, setPaymentSuccess] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const onReady = () => {
    if (backdropType === 'loading') {
      setOpenBackdrop(false);
    }
  };
  const redirectOnSubmit = () => setTimeout(() => {
    window.location.href = redirectURL;
  }, 7000);
  const returnAction = () => {
    history.push(returnURL);
  };
  const onPaymentReady = () => {
    redirectOnSubmit();
    setShowMessage(true);
  };
  const onError = async (error: IBrickError) => {
    analytics.page('CHECKOUT MELI', 'ERROR CARGA COMPONENTE', { mensaje: error.message, causa: error.cause ? getMeliError(error.cause) : undefined });
    Sentry.setContext('MercadoPago Service', {
      error,
    });
    setBackdropType('error');
  };

  const onSubmit = async (formData: ICardPaymentFormData<ICardPaymentBrickPayer>) => {
    try {
      const res = await postMELIPayment(formData, productId, token);
      if (res.data.mercadoLibreResponse.status === 'approved') {
        setPaymentSuccess(true);
      }
      setPaymentId(res.data.mercadoLibreResponse.id);
      if (res.data.mercadoLibreResponse.status === 'approved') {
        analytics.page('CHECKOUT MELI', 'PAGO EXITOSO');
      } else {
        analytics.page('CHECKOUT MELI', 'PAGO FALLIDO', { mensaje: res.data.mercadoLibreResponse.status, paso: `Pago de ${title.toLowerCase()}` });
      }
    } catch (e) {
      analytics.page('CHECKOUT MELI', 'ERROR BACKEND');
      Sentry.setContext('MercadoPago Backend Endpoint', {
        error: e,
      });
      setBackdropType('error');
      setOpenBackdrop(true);
    }
  };

  React.useEffect(() => {
    if (!openBackdrop && backdropType === 'loading') {
      analytics.page('CHECKOUT MELI', 'COMPRA INFORME DE DEUDAS');
    }
  }, [openBackdrop, backdropType]);

  return (
    <div>
      <Typography
        align="center"
        component="div"
        color="white"
        fontWeight="bold"
        marginTop={3}
        marginBottom={3}
        width="100%"
        variant="h6"
      >
        {title}
        <div>{`$${initialization.amount.toLocaleString('es-CL')}`}</div>
      </Typography>
      <div style={{ display: paymentId ? 'none' : undefined }}>
        <CardPayment
          initialization={initialization}
          onSubmit={onSubmit}
          onReady={onReady}
          onError={onError}
          customization={customization}
        />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <BackButton
            label={<b style={{ color: theme.customPalette.tertiary.main }}>Volver</b>}
            lightMode
            onClickAction={returnAction}
          />
        </div>
      </div>
      {paymentId
          && (
          <>
            {paymentSuccess && (
            <Typography
              color="white"
              variant="h5"
              fontWeight="bold"
              marginTop={10}
            >
              {showMessage && '¡Tu pago fue aceptado!'}
            </Typography>
            )}
            <Fade
              in
              easing={{
                enter: 'cubic-bezier(1.0, 0.0, 1.0, 0.0)',
              }}
              {... ({ timeout: 4200 })}
            >
              <div style={{ marginTop: '20px' }}>
                <StatusScreen
                  initialization={{ paymentId }}
                  onReady={onPaymentReady}
                />
              </div>
            </Fade>
            {!paymentSuccess && showMessage
              && (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <BackButton
                    label={(
                      <b
                        style={{ color: theme.customPalette.tertiary.main }}
                      >
                        Volver a Productos
                      </b>
)}
                    lightMode
                    onClickAction={returnAction}
                    maxWidth={400}
                  />
                </div>
              )}
          </>
          )}
      <BancameBackdrop
        type={backdropType}
        openBackdrop={openBackdrop}
        loadingText={!paymentId ? 'Cargando...' : successRedirectLabels(redirectURL)}
        errorRedirectURL={returnURL}
      />
    </div>
  );
}

MELICardPaymentBrick.defaultProps = {
  paymentMethods: {
    minInstallments: 1,
    maxInstallments: 1,
  },
};
