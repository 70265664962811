/* eslint-disable camelcase */
import React from 'react';
import { Typography } from '@mui/material';
import type { FormSchemaV3 } from '../../../../../../../types/preloan/v3';
import { LegacyResult, Results } from '../../../../../../../types/preloan';

export const resultsLabels = (origin: {originLabel: string,
   partner: string,
   partnerProducts: string[]}): { [key: string]: string } => {
  const labelEnding = origin.partnerProducts.some((product) => product === 'mortgage') ? 'bancarias' : 'de Banca.Me';
  return {
    lagunes: 'No cumples con el criterio de lagunas previsionales',
    max_age: `No cumples con el criterio de edad máxima exigida por las políticas de riesgo ${labelEnding}`,
    min_age: `No cumples con el criterio de edad mínima exigida por las políticas de riesgo ${labelEnding}`,
    punished_debt: `Tienes deuda castigada por sobre lo permitido por las políticas de riesgo ${labelEnding}`,
    leverage_total_debt: `La relación entre la suma de tus deudas totales con respecto a tus ingresos supera el máximo permitido bajo las políticas de riesgo ${labelEnding}`,
    leverage_consumer_debt: `La relación entre tu deuda de consumo con respecto a tus ingresos supera el máximo permitido bajo las políticas de riesgo ${labelEnding}`,
    min_emp_length: `No cumples con el criterio de antiguedad laboral mínima exigida por las políticas de riesgo ${labelEnding}`,
    min_net_salary: `Tu sueldo líquido es menor al permitido por las políticas de riesgo ${labelEnding}`,
    min_brute_salary: `Tu sueldo bruto es menor al permitido por las políticas de riesgo ${labelEnding}`,
    max_financial_stress: `Presentas un nivel de carga financiera (i.e., relación entre la suma de las cuotas mensuales que pagas por concepto de créditos con respecto a tus ingresos) sobre lo permitido por las políticas de riesgo ${labelEnding}`,
    max_mortgage_financial_stress: `Presentas un nivel de carga financiera hipotecaria (i.e., relación entre la suma de tus dividendos hipotecarios con respecto a tus ingresos) sobre lo permitido por las políticas de riesgo ${labelEnding}`,
    protest_and_delinquency_1year: `Presentas protestos y/o morosidades a 1 año sobre lo permitido por las políticas de riesgo ${labelEnding}`,
    protest_and_delinquency_5years: `Presentas protestos y/o morosidades a 5 años sobre lo permitido por las políticas de riesgo ${labelEnding}`,
    external_score: 'Tienes un puntaje de crédito menor al permitido para acceder a este producto',
    risk_models: 'Tienes un puntaje de crédito menor al permitido para acceder a este producto',
    w2p: 'Tienes un puntaje de crédito menor al permitido para acceder a este producto',
    max_dpp_financial_stress: 'No cumples con el criterio de porcentaje de descuentos sobre tu sueldo bruto',
    alimony_debtor: 'Tu RUT aparece registrado como deudor en el Registro Nacional de Deudores de Pensiones de Alimentos',
    min_loan_policy_amount: 'Tu monto aprobado es menor al mínimo a otorgar establecido del producto solicitado',
    product_availability: 'No tienes cupo disponible para el producto solicitado',
    pre_required_associated_product_approved: 'No fuiste pre-aprobado en un producto que es requisito para la otorgamiento de éste',
  };
};
export const legacyResultsLabels: { [key: string]: string } = {
  age: 'No cumples con el criterio de edad mínima o máxima exigida por las políticas de riesgo',
  consumer_debt: 'Posees una deuda en consumo mayor a la permitida',
  deceased: 'Este RUT pertenece a una persona fallecida',
  dpp_financial_stress: 'No cumples con el criterio del descuento por planilla mensual. Esto significa que ya hay descuentos en créditos por sobre el 15% de tu salario bruto.',
  emp_length: 'No cumples con el criterio de antigüedad laboral',
  employer_delinquency: 'Tu empleador se encuentra moroso',
  lagunes: 'No cumples con el criterio de lagunas previsionales',
  licenses: 'Presentas licencias médicas en los últimos 2 meses',
  lpe: 'A',
  proceso_concursal: 'Se encontraron procesos concursales asociados a tu RUT',
  protest_and_delinquency_1year: 'No cumples con el criterio de protestos y morosidades a un año',
  punished_debt: 'Posees deuda castigada mayor a lo aceptado',
  type_of_contract: 'No cumples con el criterio de tipo de contrato',
  external_score: 'Según fuentes externas tienes un historial crediticio fuera de lo aceptado',
};

export const productTypeLabels = {
  microLoan: 'Microcrédito',
  consumer: 'Consumo',
  mortgage: 'Hipotecario',
  downPayment: 'Pie hipotecario',
};

type ResultsProps = {
    results: FormSchemaV3['results'];
    origin: {originLabel: FormSchemaV3['origin'],
    partner: FormSchemaV3['partner'],
    partnerProducts: string[]};
}

export default function ResultLabels(props:ResultsProps) {
  const { results, origin } = props;

  const resultParser = (
    HFResults: LegacyResult['hardFilterResults']|Results['hardFilterResults'],
    key: string,
    value: 'A'|'G'|'R'|boolean,
  ) => {
    if (['A', 'G', 'R'].includes(value.toString())) {
      return (HFResults[key] === 'R' || HFResults[key] === 'G')
      && (
        <li style={{ listStyleType: 'circle' }} key={key}>
          <Typography
            style={{ color: HFResults[key] === 'R' ? 'red' : 'gray' }}
            align="left"
            component="div"
            variant="caption"
          >
            {legacyResultsLabels[key]}
          </Typography>
        </li>
      );
    }
    return !HFResults[key] as boolean
    && (
    <li style={{ listStyleType: 'circle' }} key={key}>
      <Typography
        style={{ color: 'red' }}
        align="left"
        component="div"
        variant="caption"
      >
        {resultsLabels(origin)[key]}
      </Typography>
    </li>
    );
  };

  return (
    <ul>
      {results && results.length > 0
              && results.map((result, idx) => (
                <div key={`${result.paymentMethod}_${idx * 2}`}>
                  <Typography align="left">{productTypeLabels[result.productType]}</Typography>
                  {Object.entries(result.hardFilterResults)
                    .map((HF) => resultParser(result.hardFilterResults, HF[0], HF[1]))}
                  <br />
                </div>
              ))}
    </ul>
  );
}
