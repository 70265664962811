export const prepayTypeLabels: { [key: string]: string } = {
  fullPrepay: 'Prepago total',
  partialPrepay: 'Prepago parcial',
  cashOut: 'Refinanciamiento con aumento',
  refinanceRP: 'Refinanciamiento',
};

export const prepayStatusLabels: { [key: string]: string } = {
  userRequest: 'Solicitud de usuario',
  simulated: 'Solicitud mediante agente Banca.Me',
};
