import React from 'react';
import { verifyPhone } from '../../../utils/helpers';

export const formInputs:
{[key: string]: {title:string,
  placeholder: string|undefined,
  defaultValue: string,
  startAdornment: React.ReactElement|undefined,
  endAdornment: React.ReactElement|undefined
},
  } = {
    email: {
      title: '¿Cuál es tu E-mail?',
      placeholder: 'tu_email@empresa.com',
      defaultValue: '',
      startAdornment: undefined,
      endAdornment: undefined,
    },
    phone: {
      title: '¿Cuál es tu celular/WhatsApp?',
      placeholder: undefined,
      defaultValue: '+56 ',
      startAdornment: undefined,
      endAdornment: undefined,
    },
  };

export const handleInputSetter = (type: 'Phone'|string, setFieldValue: (
  fieldName: string, val:string)=> void, fieldName: string, value: string) => {
  switch (type) {
    case 'Phone':
      return verifyPhone(value, setFieldValue, fieldName);
    default:
      return setFieldValue(fieldName, value);
  }
};
