import React from 'react';
import {
  Grid,
  Typography,
  Paper,
  useTheme,
  Fade,
} from '@mui/material';
import avatarConfident from '../../../assets/avatar_finger.svg';
import useStyles from './styles';
import analytics from '../../../utils/analytics';

type VerifyEmailProps = {
    email: string,
    firstName: string
}

export default function VerifyEmail(props:VerifyEmailProps) {
  const { email, firstName } = props;
  const classes = useStyles();
  const theme = useTheme();

  React.useEffect(() => {
    analytics.page('PU - LOGIN', 'VERIFICACIÓN EMAIL');
  }, []);
  return (
    <Fade
      in
      {...({ timeout: 1000 })}
    >
      <Grid
        item
        xs={12}
        lg={5}
        md={6}
        sm={10}
      >
        <Paper className={classes.paper} elevation={1} style={{ borderRadius: '10px' }}>
          <div>
            <object data={avatarConfident} width="70px" aria-labelledby="Bancamin Confident" />
            <Typography variant="h6" align="center" color="secondary" fontWeight="bold" marginTop="2vh">
              {`${firstName},`}
            </Typography>
            <Typography variant="h6" align="center" color="primary" fontWeight="bold">
              Te hemos enviado un correo a
            </Typography>
            <Typography variant="h6" align="center" color="secondary" fontWeight="bold">
              {`${email}`}
            </Typography>
            <Typography variant="h6" align="center" marginTop="3vh" fontWeight="bold">
              Debes
              {' '}
              <span style={{ color: theme.palette.secondary.main }}>verificar </span>
              {' '}
              tu correo para continuar.
            </Typography>
            <Typography variant="body1" align="center" marginTop="3vh" fontWeight="bold">
              Si no aparece este correo en tu
              {' '}
              <span style={{ color: theme.palette.secondary.main }}>
                bandeja de entrada
              </span>
              , por favor revisa la carpeta de
              {' '}
              <span style={{ color: theme.palette.secondary.main }}>
                spam o promociones.
              </span>
            </Typography>
          </div>
        </Paper>
      </Grid>
    </Fade>
  );
}
