import { useTheme, Breakpoint } from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';

export function useIsWidthUp(breakpoint: number|Breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
}
export function useIsWidthDown(breakpoint: number|Breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint));
}
