import React, { useEffect, useState } from 'react';
import {
  Fade, Grid,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import useStyles from '../styles';
import type { Partner, PartnerConfiguration } from '../types';
import bancameLogoWhite from '../../../assets/bancame_logo_white.svg';

type LeadFormRightGridProps = {
    partner: Partner,
    xs?: number|false,
    lg?: number|false,
}

const defaultProps = {
  xs: false,
  lg: 7,
};

export default function LeadFormRightGrid(props: LeadFormRightGridProps) {
  const { partner, xs, lg } = props;
  const classes = useStyles();
  const [backgroundStyles, setBackgroundStyles] = useState<React.CSSProperties>({});
  const getClassNameRightGrid = (
    partnerConfiguration: PartnerConfiguration,
    partnerName: string,
  ) => {
    if (!partnerConfiguration.backgroundImage || partnerName === 'Banca.Me') {
      return classes.rightGrid;
    }

    return classes.rightGridPartner;
  };

  const loadImage = (src: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = reject;
  });

  const getCustomImageRightGrid = async () => {
    const imageUrl = partner.partnerConfiguration.backgroundImage;
    try {
      if (imageUrl) {
        const img = await loadImage(imageUrl);
        const styles: React.CSSProperties = {
          backgroundImage: `url('${imageUrl}')`,
          backgroundSize: img.width > img.height ? 'contain' : 'cover',
        };
        setBackgroundStyles(styles);
      }
    } catch (error) {
      Sentry.setContext('Partners background image load error', {
        error,
      });
    }
  };

  useEffect(() => {
    getCustomImageRightGrid();
  }, [partner.partnerConfiguration.backgroundImage]);

  return (
    <Fade
      in
      {...({ timeout: 2500 })}
    >
      <Grid
        item
        xs={xs}
        lg={lg}
        className={getClassNameRightGrid(
          partner.partnerConfiguration,
          partner.partnerName,
        )}
        style={backgroundStyles}
      >
        {!partner.partnerConfiguration.backgroundImage && (
        <div style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        >

          <img src={bancameLogoWhite} width="250px" alt="BancaMeLogo" aria-hidden />
        </div>
        )}
      </Grid>
    </Fade>
  );
}

LeadFormRightGrid.defaultProps = defaultProps;
