import React from 'react';
import { Typography } from '@mui/material';
import DocumentUploadButton from './Document';
import { AWSUrl, postAWSDocument } from './helpers';
import useStyles from './styles';

export type PLRDocument = {
  plrDocumentId: string,
  url: AWSUrl,
}

type UploadDocumentsProps = {
  liquidacion?: boolean,
  cotizaciones?: boolean,
  getDocumentsUrl: (pensionQuoteExtension?: string, payslipExtension?: string)
    => Promise<{data:{
      cotizacionUrl: PLRDocument | undefined,
      liquidacionUrl: PLRDocument | undefined
    }
    }>,
  handleDocumentsOk: (documentsOk: boolean) => void,
  triggerUpload: boolean,
  handleUploadOk: () => void,
}
const defaultProps = {
  liquidacion: true,
  cotizaciones: true,
};

const getPath = (filename?: string) => {
  if (filename === undefined) return undefined;
  const path = filename.split('.');
  return path[path.length - 1];
};

export default function UploadDocuments(props: UploadDocumentsProps) {
  const {
    liquidacion,
    cotizaciones,
    getDocumentsUrl,
    triggerUpload,
    handleUploadOk,
    handleDocumentsOk,
  } = props;
  const classes = useStyles();
  const [fileOne, setFileOne] = React.useState<File | undefined>(undefined);
  const [fileOneOk, setFileOneOk] = React.useState(false);
  const [fileTwo, setFileTwo] = React.useState<File | undefined>(undefined);
  const [fileTwoOk, setFileTwoOk] = React.useState(false);

  const [fileOneUploading, setFileOneLoading] = React.useState(false);
  const [fileTwoUploading, setFileTwoLoading] = React.useState(false);
  const [uploadSuccessOne, setUploadSuccessOne] = React.useState(false);
  const [uploadSuccessTwo, setUploadSuccessTwo] = React.useState(false);

  const [fileOneInputRefs,
    setFileOneInputRefs] = React.useState<React.RefObject<HTMLCanvasElement>[]>([]);
  const [fileTwoInputRefs,
    setFileTwoInputRefs] = React.useState<React.RefObject<HTMLCanvasElement>[]>([]);

  const [error, setError] = React.useState(false);

  const handleFileOk = (fileType: string) => {
    switch (fileType) {
      case 'liquidacion': {
        return setFileOneOk(true);
      }
      case 'cotizaciones': {
        return setFileTwoOk(true);
      }
      case 'resetLiquidacion':
        return setFileTwoOk(false);
      case 'resetCotizaciones':
        return setFileTwoOk(false);
      default:
        return (setFileOneOk(false), setFileTwoOk(false));
    }
  };
  const getURLandUpload = async () => {
    setError(false);
    setFileOneLoading(true);
    setFileTwoLoading(true);
    try {
      const payslipExt = fileOne && getPath(`${fileOne.name}`);
      const pensionQuoteExt = fileTwo && getPath(`${fileTwo.name}`);
      const { data } = await getDocumentsUrl(payslipExt, pensionQuoteExt);
      if (data.liquidacionUrl && fileOne) {
        const res = await postAWSDocument(fileOneInputRefs, fileOne, data.liquidacionUrl);
        if (res.status === 201) {
          setFileOneLoading(false);
          setUploadSuccessOne(true);
        }
      }
      if (data.cotizacionUrl && fileTwo) {
        const res = await postAWSDocument(fileTwoInputRefs, fileTwo, data.cotizacionUrl);
        if (res.status === 201) {
          setFileTwoLoading(false);
          setUploadSuccessTwo(true);
        }
      }
    } catch (e) {
      setError(true);
    }
  };
  React.useEffect(() => {
    if ((fileOneOk && liquidacion && !cotizaciones)
      || (fileOneOk && fileTwoOk && liquidacion && cotizaciones)
      || (fileTwoOk && !liquidacion && cotizaciones)) {
      return handleDocumentsOk(true);
    }
    return handleDocumentsOk(false);
  }, [fileOneOk, fileTwoOk]);

  React.useEffect(() => {
    if (triggerUpload) {
      getURLandUpload();
    }
  }, [triggerUpload]);
  React.useEffect(() => {
    if ((uploadSuccessOne && liquidacion && !cotizaciones)
      || (uploadSuccessOne && uploadSuccessTwo && liquidacion && cotizaciones)
      || (uploadSuccessTwo && !liquidacion && cotizaciones)) {
      handleUploadOk();
    }
  }, [uploadSuccessOne, uploadSuccessTwo]);
  return (
    <>
      <div className={(liquidacion && cotizaciones)
        ? classes.twoButtonsDisplay
        : classes.oneButtonDisplay}
      >
        {liquidacion
          && (
            <DocumentUploadButton
              label="Subir última liquidación"
              file={fileOne}
              type="liquidacion"
              setFile={setFileOne}
              fileOk={handleFileOk}
              documentUploading={fileOneUploading}
              uploadSuccess={uploadSuccessOne}
              setFileInputRefs={setFileOneInputRefs}
              maxPages={1}
            />
          )}
        {cotizaciones
          && (
            <DocumentUploadButton
              label="Subir últimas 12 cotizaciones"
              file={fileTwo}
              type="cotizaciones"
              setFile={setFileTwo}
              fileOk={handleFileOk}
              documentUploading={fileTwoUploading}
              uploadSuccess={uploadSuccessTwo}
              setFileInputRefs={setFileTwoInputRefs}
              maxPages={3}
            />
          )}
      </div>
      {error
        && (
          <div style={{ textAlign: 'center' }}>
            <Typography
              variant="caption"
              color="error"
              maxWidth={400}
              component="div"
              align="center"
              margin="auto"
              marginTop={2}
            >
              Ha ocurrido un error cargando tus archivos,
              por favor escríbenos para atender tu caso
            </Typography>
          </div>
        )}
    </>
  );
}

UploadDocuments.defaultProps = defaultProps;
