const fetcher = async (
  url: string,
  method: string,
  body: string | string[][] | Record<string, string> | {} | null,
  token: string| null,
  timeout: number = 300000,
) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const res = await fetch(url, {
    method,
    body: body ? new URLSearchParams(body) : undefined,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token ? `Bearer ${token}` : '',

    },
  }) as any;
  clearTimeout(id);
  const response = await res.json();
  if (res.ok) {
    return { ...response, status: res.status };
  }
  if (response.data.message) {
    throw response.data.message;
  }
  throw res.status;
};

export default fetcher;
