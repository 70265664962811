export default function b64DecodeUnicodeToObject(str: string|null) {
  if (!str) {
    return {};
  }
  let output = str.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += '==';
      break;
    case 3:
      output += '=';
      break;
    default:
      throw Error('Illegal base64url string!');
  }
  return JSON.parse(decodeURIComponent(atob(output).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join('')));
}
