import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const loanInstallmentsStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '2.5%',
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      padding: '1%',
    },

  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: '100%',
  },
  AccordionDetails: {
    display: 'grid',
    padding: '2% auto',
    paddingLeft: '100px',
    paddingRight: '100px',
  },
  TableContainer: {
    height: '390px',
    '&::-webkit-scrollbar': {
      width: 10,
      height: 10,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '8px',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
      marginTop: '70px',
      transition: '2s',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      backgroundColor: '#262D40',
      transition: '2s',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },

  },
  ReponsiveCell: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },

  },
  ResponsiveTitle: {
    alignSelf: 'center',
    fontSize: 'x-small',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  DialogTitle: {
    backgroundColor: theme.palette.primary.main,
  },

}));

export default loanInstallmentsStyles;
