/* eslint-disable camelcase */
import * as Yup from 'yup';
import { santiagoAndMobileAreaCodes, regionNumsAreaCodes, MailRegex } from '../../../utils/helpers';

const dataFormObject: {[key:string]: any} = {
  age: Yup.object()
    .shape({
      value: Yup.number().required('Por favor complete este campo')
        .min(1, '¿Naciste?')
        .max(99, '¿Más de 100 años?'),
    }),
  sex: Yup.object()
    .shape({ value: Yup.string().required('Por favor complete este campo') }),
  type_of_contract: Yup.object()
    .shape({ value: Yup.string().required('Por favor complete este campo') }),
  emp_length: Yup.object()
    .shape({ value: Yup.string().required('Por favor complete este campo') }),
  licenses: Yup.object()
    .shape({ value: Yup.string().required('Por favor complete este campo') }),
  brute_salary: Yup.object()
    .shape({
      value: Yup.number().required('Por favor complete este campo')
        .min(90_000, 'Debes ganar al menos 100 mil pesos')
        .max(10_000_000, 'Al parecer te sobra un dígito'),
    }),
  net_salary: Yup.object()
    .shape({
      value: Yup.number().required('Por favor complete este campo')
        .min(90_000, 'Debes ganar al menos 90 mil pesos')
        .max(10_000_000, 'Al parecer te sobra un dígito'),
    }),
  phone: Yup.object()
    .shape({
      value: Yup.string()
        .test(
          'num-required',
          'Ingrese un número de teléfono válido, 9 dígitos',
          (num: any) => {
            const cleanNum = num.replace(' ', '');
            if (cleanNum.length >= 3) {
              const schema = Yup.string().min(12).max(12);
              return schema.isValidSync(cleanNum);
            }
            return true;
          },
        )
        .test(
          'num-area-invalid',
          'Ingrese un código de área válido o celular en Chile',
          (num: any) => {
            const cleanNum = num.replace(' ', '');
            if (cleanNum.length === 12) {
              if (!santiagoAndMobileAreaCodes.includes(cleanNum[3])
            && !regionNumsAreaCodes.includes(cleanNum.slice(3, 5))) {
                return false;
              }
            }
            return true;
          },
        ),
    }),
  email: Yup.object()
    .shape({
      value: Yup.string()
        .required('Ingrese un e-mail de contacto de su empresa')
        .matches(
          MailRegex,
          'Por favor ingrese un E-mail válido',
        ),
    }),
};

const validationSchemaF = (keys: string[]) => {
  Object.keys(dataFormObject)
    .filter((key) => !keys.includes(key))
    .forEach((key) => delete dataFormObject[key]);

  const validationSchema = Yup.object().shape({ dataForm: Yup.object().shape(dataFormObject) });
  return validationSchema;
};

export default validationSchemaF;
