import { Typography } from '@mui/material';
import React from 'react';
import avatarConfident from '../../../../../../../../assets/avatar_finger.svg';
import { getExperianComponent, termsAndConditions } from './helpers';
import { ExperianFormProps } from './types';

export default function ExperianForm(
  props: ExperianFormProps,
) {
  const {
    values, handleChange, queriesArray, setFieldValue,
    handleCurrentData, handleStep, handleEnableLoading,
  } = props;

  return (
    <div>
      <div>
        <object data={avatarConfident} aria-labelledby="Avatar" height="80px" />
      </div>
      <Typography
        fontWeight="bold"
      >
        ¡Necesitamos validar tus ingresos a través de tus cotizaciones!

      </Typography>
      <br />
      <div style={{ textAlign: 'center' }}>
        {getExperianComponent({
          values,
          handleChange,
          queriesArray,
          setFieldValue,
          handleCurrentData,
          handleStep,
          handleEnableLoading,
        })}
      </div>
      <br />
      <Typography
        fontStyle="italic"
        variant="body2"
        color="GrayText"
        style={{ borderRadius: '10px', padding: '10px', background: 'whitesmoke' }}
      >
        {termsAndConditions}

      </Typography>
    </div>
  );
}
