import { Theme, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    paddingLeft: 50,
    marginTop: 15,
    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
      paddingLeft: 0,
    },

  },
  gridItem: {
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  avatar1: {
    width: 90,
    [theme.breakpoints.down('md')]: {
      width: 70,
    },
  },
  avatar2: {
    width: 75,
    [theme.breakpoints.down('md')]: {
      width: 60,
    },
  },
}));

export const sx = () => {
  const theme = useTheme();
  return ({
    cardLinkContainer: {
      textAlign: 'center',
      placeContent: 'center',
      borderRadius: 5,
      maxWidth: 500,
      maxHeight: 100,
      display: 'flex',
      alignItems: 'center',
      paddingBlock: 23,
      [theme.breakpoints.down('md')]: {
        paddingBlock: 18,
        maxWidth: 'none',

      },
    },
    list: {
      bgcolor: 'background.paper',
      borderRadius: 3,
      maxWidth: 500,
      height: 400,
      overflowY: 'auto',
      [theme.breakpoints.down('md')]: {
        height: 340,
        maxWidth: 'none',
      },

    },
  });
};

export default useStyles;
