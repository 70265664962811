import React from 'react';
import {
  Fade,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import avatarWhistle from '../../../assets/avatar_whistle.svg';
import useStyles from './styles';
import { productTypeLabels } from '../../../components/CreditOverview';

type PrepayRequestSimulationProps = {
    totalPrepaySimulation: {
      unpaidCapital: number,
      paidAmount: number,
      delinquencyDebt: number,
      interestAmount: number,
      prepayComission: number,
      totalToDeposit: number
  },
  partner: string,
  productType: string,
  paymentMethod: string
}

export default function PrepayRequestSimulation(props: PrepayRequestSimulationProps) {
  const {
    totalPrepaySimulation, partner, productType, paymentMethod,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Fade
      in
      {...({ timeout: 2500 })}
    >
      <Grid
        container
        justifyContent="flex-end"
        className={classes.infoContainer}
      >
        <Grid
          item
          xs={12}
          lg={2}
        >
          <div style={{
            display: 'flex', columnGap: 10, textAlign: 'center', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <img
              src={avatarWhistle}
              alt="Avatar"
              className={classes.prepayAvatar}
            />
            <div>
              <Typography variant="h6" fontWeight="bold">{productTypeLabels[productType]}</Typography>
              <Typography gutterBottom>
                [
                {paymentMethod.toUpperCase()}
                ]
              </Typography>
              <Typography>{partner}</Typography>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={2}
          style={{
            placeSelf: 'flex-end',
            padding: '30px',
            border: '5px solid whitesmoke',
            borderRadius: '10px',
          }}
        >
          <Typography
            variant="h4"
            style={{ color: theme.customPalette.tertiary.main }}
            fontWeight="bold"
            gutterBottom
          >
            $
            {totalPrepaySimulation.totalToDeposit.toLocaleString('es-CL')}
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            color="text.secondary"
          >
            Monto a cancelar para realizar prepago

          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={3}
          style={{
            placeSelf: 'flex-end',
            padding: '30px',
            border: '5px solid whitesmoke',
            borderRadius: '10px',
            marginInline: 10,
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            gutterBottom
          >
            Detalles

          </Typography>
          <Typography
            variant="body2"
          >
            Capital a cancelar: $
            <b>{totalPrepaySimulation.unpaidCapital.toLocaleString('es-CL')}</b>
          </Typography>
          <Typography
            variant="body2"
          >
            Comisión prepago: $
            <b>{totalPrepaySimulation.prepayComission.toLocaleString('es-CL')}</b>
          </Typography>
          <Typography
            variant="body2"
          >
            Intereses devengados: $
            <b>{totalPrepaySimulation.interestAmount.toLocaleString('es-CL')}</b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          className={classes.prepaySavingsContainer}
          alignSelf="center"
        >
          <div style={{ padding: '5%' }}>
            <Typography
              variant="h3"
              fontWeight="bold"
              color="secondary"
              align="center"
            >
              $
              {(totalPrepaySimulation.unpaidCapital - totalPrepaySimulation.totalToDeposit).toLocaleString('es-CL')}

            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              align="center"
            >
              Te estás ahorrando

            </Typography>
          </div>
        </Grid>
      </Grid>
    </Fade>
  );
}
