import React from 'react';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import AccessibleIcon from '@mui/icons-material/Accessible';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Avatar, Tooltip, useTheme } from '@mui/material';

export const insuranceLogoSwitch = (params:{value: string, invertColors?: boolean, iconSize: 'small' | 'medium'}) => {
  const { value, invertColors, iconSize } = params;
  const theme = useTheme();
  switch (value) {
    case 'unemployment':
      return (
        <WorkOffIcon
          key={value}
          style={{
            fontSize: iconSize === 'medium' ? 17 : 13,
            color: invertColors ? 'whitesmoke' : theme.palette.primary.main,
          }}
        />
      );
    case 'incapacity':
      return (
        <AccessibleIcon
          key={value}
          style={{
            fontSize: iconSize === 'medium' ? 20 : 16,
            color: invertColors ? 'whitesmoke' : theme.palette.primary.main,
          }}
        />
      );
    case 'death':
      return (
        <FavoriteIcon
          key={value}
          style={{
            fontSize: iconSize === 'medium' ? 18 : 14,
            color: invertColors ? 'whitesmoke' : theme.palette.primary.main,
          }}
        />
      );
    case 'death-downpayment':
      return (
        <FavoriteIcon
          key={value}
          style={{
            fontSize: iconSize === 'medium' ? 18 : 14,
            color: invertColors ? 'whitesmoke' : theme.palette.primary.main,
          }}
        />
      );
    default:
      return (
        <VerifiedUserIcon
          key={value}
          style={{ fontSize: iconSize === 'medium' ? 18 : 14, color: invertColors ? 'whitesmoke' : theme.palette.primary.main }}
        />
      );
  }
};

export const getInsuranceLogo = (params: {value: string,
    label: string, invertColors?: boolean, iconSize: 'small' | 'medium'}) => {
  const {
    value, label, invertColors, iconSize,
  } = params;
  const theme = useTheme();
  const avatarSizeSwitch = (size: 'small' | 'medium') => {
    switch (size) {
      case 'small': return 24;
      default: return 30;
    }
  };
  return (
    <Tooltip key={value} title={label}>
      <Avatar style={{
        width: avatarSizeSwitch(iconSize),
        height: avatarSizeSwitch(iconSize),
        backgroundColor: invertColors ? theme.palette.primary.main : 'transparent',
      }}

      >
        {insuranceLogoSwitch({ value, invertColors, iconSize })}
      </Avatar>
    </Tooltip>
  );
};
