import React from 'react';
import { Formik, Form } from 'formik';
import { PLRFormProps } from '../../../../../../types/preloan';
import { FormSchemaV3 } from '../../../../../../types/preloan/v3';
import ExperianForm from './components/ExperianForm';
import { postExperianChallenge, postExperianChallengeResponses } from '../../../../../../queries/preloan/v3';

export default function NeedsICPForm<T extends FormSchemaV3>(props: PLRFormProps<T>) {
  const {
    formRef,
    formSchema,
    handleEnableLoading,
    handleNext,
    handleCurrentData,
    handleStep,
  } = props;

  React.useEffect(() => handleEnableLoading(true), []);

  return (
    <Formik
      innerRef={formRef}
      initialValues={formSchema}
      onSubmit={() => handleNext()}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form>
          <ExperianForm
            values={values}
            handleChange={handleChange}
            queriesArray={[postExperianChallenge, postExperianChallengeResponses]}
            setFieldValue={setFieldValue}
            handleCurrentData={handleCurrentData}
            handleStep={handleStep}
            handleEnableLoading={handleEnableLoading}
          />
        </Form>
      )}

    </Formik>
  );
}
