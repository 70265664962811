import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({

  textInput: {
    margin: '10px auto',
    width: '90%',
  },
}));

export default useStyles;
