import React from 'react';
import { CircularProgress, Typography } from '@mui/material';

export const analyticsErrorPageName: {[key: string]: string} = {
  ERR_PAYSLIP_DOES_NOT_BELONG_TO_CLIENT_ID_NUMBER: 'RUT PERSONA NO COINCIDE',
  ERR_PENSION_SETTLEMENT_DOES_NOT_BELONG_TO_CLIENT_ID_NUMBER: 'RUT PERSONA NO COINCIDE',
  ERR_PAYSLIP_DOES_NOT_BELONG_TO_CLIENT_EMPLOYER: 'RUT EMPRESA NO COINCIDE',
  ERR_PENSION_SETTLEMENT_DOES_NOT_BELONG_TO_CLIENT_EMPLOYER: 'RUT EMPRESA NO COINCIDE',
  ERR_PAYSLIP_DATE_IS_NOT_CURRENT: 'LIQUIDACION ANTIGUA',
  ERR_PAYSLIP_COULD_NOT_BE_PARSED: 'LECTURA LIQUIDACION',
  ERR_PENSION_STATEMENT_COULD_NOT_BE_PARSED: 'LECTURA COTIZACIONES',
  LAMBDA_TEXT_TO_STRUCTURED_ERROR: 'ERROR LAMBDA',
};
export const analyticsErrorPageProps = (attempts: number): {[key: string]: {document?: string,
  attempts?: number}} => ({
  ERR_PAYSLIP_DOES_NOT_BELONG_TO_CLIENT_ID_NUMBER: { document: 'liquidación' },
  ERR_PENSION_SETTLEMENT_DOES_NOT_BELONG_TO_CLIENT_ID_NUMBER: { document: 'cotizaciones' },
  ERR_PAYSLIP_DOES_NOT_BELONG_TO_CLIENT_EMPLOYER: { document: 'liquidación' },
  ERR_PENSION_SETTLEMENT_DOES_NOT_BELONG_TO_CLIENT_EMPLOYER: { document: 'cotizaciones' },
  ERR_PAYSLIP_DATE_IS_NOT_CURRENT: { attempts },
  ERR_PAYSLIP_COULD_NOT_BE_PARSED: { attempts },
  ERR_PENSION_STATEMENT_COULD_NOT_BE_PARSED: { attempts },
  LAMBDA_TEXT_TO_STRUCTURED_ERROR: { attempts },
});

export const docsParsingErrorMessageSwitch = (docError: string) => {
  switch (docError) {
    case 'ERR_PAYSLIP_COULD_NOT_BE_PARSED':
      return 'tu LIQUIDACIÓN DE SUELDO';
    case 'ERR_PENSION_STATEMENT_COULD_NOT_BE_PARSED':
      return 'tu CERTIFICADO DE COTIZACIONES';
    default:
      return 'tus DOCUMENTOS';
  }
};
export default function errorMessageSwitch(params:{status: number|string, numTries:number}) {
  const { status, numTries } = params;
  switch (status) {
    case 'ERR_PAYSLIP_DOES_NOT_BELONG_TO_CLIENT_ID_NUMBER':
      return {
        comp:
  <>
    El RUT que aparece en tu liquidación de
    sueldo parece no coincidir con el que identificamos. Por favor sube otro archivo.
  </>,
        str: 'El RUT que aparece en tu liquidación de sueldo parece no coincidir con el que identificamos. Por favor sube otro archivo.',
      };
    case 'ERR_PENSION_SETTLEMENT_DOES_NOT_BELONG_TO_CLIENT_ID_NUMBER':
      return {
        comp:
  <>
    El RUT que aparece en tu certificado de
    cotizaciones parece no coincidir con el que identificamos. Por favor sube otro archivo.
  </>,
        str: 'El RUT que aparece en tu certificado de cotizaciones parece no coincidir con el que identificamos. Por favor sube otro archivo.',
      };
    case 'ERR_PAYSLIP_DOES_NOT_BELONG_TO_CLIENT_EMPLOYER':
      return {
        comp:
  <>
    El RUT de la empresa que aparece en
    tu liquidación de sueldo parece no coincidir con la que identificamos.
    Por favor sube otro archivo.
  </>,
        str: 'El RUT de la empresa que aparece en tu liquidación de sueldo parece no coincidir con la que identificamos. Por favor sube otro archivo.',
      };
    case 'ERR_PENSION_SETTLEMENT_DOES_NOT_BELONG_TO_CLIENT_EMPLOYER':
      return {
        comp:
  <>
    El RUT de la empresa que aparece en tu certificado
    de cotizaciones parece no coincidir con el que entregaste.
    Por favor sube otro archivo.
  </>,
        str: 'El RUT de la empresa que aparece en tu certificado de cotizaciones parece no coincidir con el que entregaste. Por favor sube otro archivo.',
      };
    case 'ERR_PAYSLIP_DATE_IS_NOT_CURRENT':
      return {
        comp:
  <>
    La liquidación de sueldo que subiste parece no ser reciente. Por favor sube otro archivo.
  </>,
        str: 'La liquidación de sueldo que subiste parece no ser reciente. Por favor sube otro archivo.',
      };
    default:
      return {
        comp:
  <>
    {status === 206
      ? (
        <div style={{ textAlign: 'center' }}>
          <div>
            Se te redirigirá al próximo paso del flujo
            debido a que se ha completado el
            máximo de intentos permitidos para subir documentos.
          </div>
          <CircularProgress size={15} />
        </div>
      )
      : `Ha ocurrido un error leyendo ${docsParsingErrorMessageSwitch(status as string)}.
    Por favor verifica que has subido una imagen o un archivo PDF de buena calidad. 
    Si el problema persiste,
    tu evaluación pasará por revisión manual.`}
    <Typography
      variant="h6"
      align="center"
      gutterBottom
      marginTop="15px"
      component="div"
      color={numTries >= 3 ? 'error' : undefined}
    >
      Número de intentos
    </Typography>
    <div>
      <Typography variant="h4" align="center" display="inline" component="div">
        {numTries}
        /
      </Typography>
      <Typography
        variant="h4"
        align="center"
        display="inline"
        component="div"
      >
        3

      </Typography>
    </div>
  </>,
        str: `Ha ocurrido un error leyendo ${docsParsingErrorMessageSwitch(status as string)}. Por favor verifica que has subido una imagen o un archivo PDF de buena calidad. 
        Si el probelma persiste, tu evaluación pasará por revisión manual. Número de intentos:  ${numTries}/3`,
      };
  }
}
