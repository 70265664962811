import React from 'react';
import AlertDialog from '../../../components/AlertDialog';
import TalanaTermsAndConditionsContent from './content';

export default function TalanaTermsAndConditions() {
  const [triggerDialog, setTriggerDialog] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(true);
  const handleEnableButton = () => setDisableButton(false);
  setTimeout(
    () => setTriggerDialog(true),
    500,
  );
  return (
    <AlertDialog
      isTriggered={triggerDialog}
      dialogContent={(
        <TalanaTermsAndConditionsContent
          handleEnableButton={handleEnableButton}
        />
)}
      hideCloseButton
      submitButtonDisabled={disableButton}
      submitButtonLabel="Aceptar T&C"
      closeAfterSubmit
    />
  );
}
