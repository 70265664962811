import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    margin: '16px',
    marginLeft: '16px',
    width: '90%',
    columnGap: 30,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      margin: '10px auto',
      rowGap: 10,
    },
  },
  infoCard: {
    padding: '5%',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 2fr',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  actionButtons: {
    width: '90%',
    textAlign: 'center',
    marginTop: 15,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  footerInfo: {
    display: 'flex',
    margin: 'auto',
    marginTop: 10,
    alignItems: 'left',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10,
    },
  },
  footerInfoTermsAndConditions: {
    display: 'flex',
    margin: 'auto',
    marginTop: 10,
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: 15,
      marginBottom: 80,
      marginLeft: 15,
    },
  },
  footerIcon: {
    display: 'flex',
    alignItems: 'center',
    placeContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      placeContent: 'center',
    },
  },
  footerText: {
    textAlign: 'left',
    paddingLeft: 5,
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  referralOverview: {
    paddingInline: '3%',
    backgroundColor: 'white',
    width: '90%',
    marginLeft: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: '5%',
      gridTemplateColumns: '1fr',
      margin: 'auto',
    },
  },
}));

export default useStyles;
