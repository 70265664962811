import React from 'react';
import {
  Button,
  Divider,
  List,
  ListItem,
  Paper,
  useTheme,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import useStyles from './styles';
import saveAsExcel from '../../utils/saveAsExcel';

type DataTableProps = {
    tableTitle?: string,
    tableHeaders: string[],
    tableBody: { id: string,
      content: (string | number | React.ReactElement)[],
      url?: string }[],
    excelTitle?: string,
    excelHeaders?: string[],
    excelData?: {}[],
    tableNoDataLabel?: string,
    fixedHeight?: number,
    hideTableTitleAndAction?: boolean,
    customTitleAndAction?: React.ReactElement |null,
    totalsArray?: (string|number|React.ReactElement)[]
}
const defaultProps = {
  tableTitle: '',
  excelTitle: 'Planilla sin título',
  excelHeaders: [],
  excelData: [],
  tableNoDataLabel: '(No hay datos para mostrar)',
  fixedHeight: 300,
  hideTableTitleAndAction: false,
  customTitleAndAction: null,
  totalsArray: [],
};

export default function DataTable(props: DataTableProps) {
  const {
    tableTitle,
    tableHeaders,
    tableBody,
    excelTitle = defaultProps.excelTitle,
    excelHeaders,
    excelData = defaultProps.excelData,
    tableNoDataLabel,
    fixedHeight,
    hideTableTitleAndAction,
    customTitleAndAction,
    totalsArray = defaultProps.totalsArray,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const header = excelHeaders;

  const handleSaveAsExcel = () => saveAsExcel(excelData, header, excelTitle);

  return (
    <Paper style={{ borderRadius: 20, padding: 5, marginTop: '9px' }}>
      {customTitleAndAction}
      {!hideTableTitleAndAction
      && (
      <div style={{
        display: 'grid',
        gridTemplateColumns: '2fr 3fr 1fr',
        alignItems: 'center',
        paddingInline: 10,
      }}
      >
        <h2>{tableTitle}</h2>
        <div />
        <Button
          disabled={excelData.length === 0}
          style={{ margin: 'auto 5px', borderColor: 'green', fontSize: 'small' }}
          variant="outlined"
          onClick={handleSaveAsExcel}
          startIcon={<DownloadIcon style={{ color: excelData.length === 0 ? 'gray' : 'green', fontSize: 'medium' }} />}
        >
          .XLSX
        </Button>
      </div>
      )}
      <List
        className={classes.table}
        style={{
          paddingLeft: '6px',
          paddingRight: '6px',
        }}
      >
        <div className={classes.headerContainer}>
          <ListItem
            sx={{
              [theme.breakpoints.down('md')]: {
                paddingInline: 0,
              },
            }}
          >
            <div
              className={classes.header}
              style={{ gridTemplateColumns: `repeat(${tableHeaders.length}, 1fr)` }}
            >
              {tableHeaders.map((tableHeader, idx) => (
                <div
                  key={`${tableHeader}_${idx + 1}`}
                  className={classes.headerItem}
                >
                  {tableHeader}
                </div>
              ))}
            </div>
          </ListItem>
        </div>
        <div style={{ height: fixedHeight }} className={classes.tableBody}>
          {tableBody.length > 0
            ? tableBody.map((tableBodyItem) => (
              <div
                key={`tableBody_item_${tableBodyItem.id}`}
                style={{ cursor: 'pointer' }}
                onDoubleClick={tableBodyItem.url
                  ? (() => window.open(tableBodyItem.url)) : undefined}
              >
                <ListItem
                  className={classes.tableBodyItem}
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      paddingInline: 0,
                    },
                  }}
                >
                  <div style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${tableBodyItem.content.length}, 1fr)`,
                    width: '100%',
                  }}
                  >
                    {tableBodyItem.content.map((contentItem, idx) => (
                      <div
                        key={`${contentItem}_${idx + 1}`}
                        className={classes.tableBodyItemContent}
                      >
                        {contentItem}
                      </div>
                    ))}
                  </div>
                </ListItem>
                <Divider />
              </div>
            ))
            : (
              <div style={{ fontStyle: 'italic', opacity: 0.8, padding: 10 }}>
                {tableNoDataLabel}
              </div>
            )}
        </div>
        {totalsArray.length > 0
        && (
        <div>
          <ListItem
            className={classes.tableBodyItem}
            sx={{
              [theme.breakpoints.down('md')]: {
                paddingInline: 0,
              },
            }}
          >
            <div style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${totalsArray.length}, 1fr)`,
              width: '100%',
            }}
            >
              {totalsArray.map((contentItem, idx) => (
                <b
                  key={`${contentItem}_${idx + 1}`}
                  className={classes.tableBodyItemContent}
                >
                  {contentItem}
                </b>
              ))}
            </div>
          </ListItem>
          <Divider />
        </div>
        )}
      </List>
    </Paper>
  );
}

DataTable.defaultProps = defaultProps;
