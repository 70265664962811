import React from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import AccountRoutes from './account';
import PreloanRoutes from './preloan';
import VerifyRoutes from './verify';
import NotFound from './404';
import ReferralTermsAndConditions from '../pages/referral-terms-and-conditions';
import PaymentRoutes from './payment';
import TermsAndConditions from '../pages/terms-and-conditions';
import ProductsRoutes from './products';
import SignatureRoutes from './signature';

const notFound = () => <NotFound />;

function AppRoutes() {
  return (
    <Route
      render={
        ({ location }) => (
          <Switch location={location}>
            <Route path="/preloan" component={PreloanRoutes} />
            <Route path="/account" component={AccountRoutes} />
            <Route path="/verify" component={VerifyRoutes} />
            <Route path="/referral-terms-and-conditions" component={ReferralTermsAndConditions} />
            <Route path="/payment" component={PaymentRoutes} />
            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Route path="/products" component={ProductsRoutes} />
            <Route path="/signature" component={SignatureRoutes} />
            <Route path="/">
              <Redirect to="/preloan" />
            </Route>
            <Route component={notFound} />
          </Switch>
        )
      }
    />
  );
}

export default AppRoutes;
