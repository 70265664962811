import { Currency } from '../../../types/account';

export const installmentStateLabels: { [key: string]:string } = {
  ACTIVE: 'ACTIVA',
  SOFT_DEBT: 'MOROSA BLANDA',
  INTERMEDIATE_DEBT: 'MOROSA INTERMEDIA',
  HARD_DEBT: 'MOROSA DURA',
  PAID: 'PAGADA',
  PUNISHED: 'CASTIGADA',
  RECOVERED: 'PAGADA',
};
export const intallmentStateDefinitions: { [key: string]:string } = {
  PAID: 'La cuota ya fue oportunamente cancelada.',
  ACTIVE: 'El pago de la cuota continúa pendiente.',
  SOFT_DEBT: 'Signifca estar atrasado/a en cualquier pago entre 1 y 30 días.',
  INTERMEDIATE_DEBT: 'Signifca estar atrasado/a en cualquier pago entre 30 y 90 días. Se ingresa deuda a DICOM',
  HARD_DEBT: 'Signifca estar atrasado/a en cualquier pago sobre 90 días. Se evalua cobranza judicial.',
  PUNISHED: 'Signifca estar atrasado/a en cualquier pago sobre 180 días. Se inicia una cobranza judicial.',
  RECOVERED: 'Signifca que una cuota que estaba CASTIGADA fue pagada.',
};
export const installmentStateColor = (state: string) => {
  switch (state) {
    case 'PAID':
    case 'RECOVERED':
      return 'green';
    case 'SOFT_DEBT':
      return 'orange';
    case 'INTERMEDIATE_DEBT':
      return 'red';
    case 'HARD_DEBT':
    case 'PUNISHED':
      return 'purple';
    default:
      return undefined;
  }
};
export function createData(
  period: number,
  expirationDate: string,
  principal: number,
  interest: number,
  amount: number,
  state: string,
  currency: Currency,
) {
  return {
    period, expirationDate, principal, interest, amount, state, currency,
  };
}

export const installmentStateExplanations = [
  'ACTIVA: El pago de la cuota continúa pendiente.',
  'PAGADA: La cuota ya fue debidamente cancelada.',
  'MOROSA BLANDA: Signifca estar atrasado/a en cualquier pago entre 1 y 30 días.',
  'MOROSA INTERMEDIA: Signifca estar atrasado/a en cualquier pago entre 30 y 90 días. Se ingresa deuda a DICOM.',
  'MOROSA DURA: Signifca estar atrasado/a en cualquier pago por más de 90 días. Se evalúa cobranza judicial.',
  'CASTIGADA: Signifca estar atrasado/a en cualquier pago por más de 180 días. Se inicia una cobranza judicial.',
  'RECUPERADA: Signifca que una cuota que estaba CASTIGADA fue pagada.',
];
