import React from 'react';
import {
  Grid,
} from '@mui/material';
import accountStyles from '../account/styles';
import Verify from '../../feature/verify';

type VerifyViewProps = {
  queryToken: string
}

export default function VerifyView(props:VerifyViewProps) {
  const { queryToken } = props;
  const classes = accountStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Verify queryToken={queryToken} />
    </Grid>
  );
}
