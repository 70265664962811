import React from 'react';
import {
  Grid, Paper, Typography, Button, useTheme,
  ListItem,
  ListItemText,
} from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import type { ProductsData } from '../../../types/products';
import BuyProductDialog from '../BuyProductDialog';
import BackButton from '../../../components/BackButton';
import analytics from '../../../utils/analytics';

const productTypeLabels: {[key:string]:{label: string,
  icon: null|React.ReactElement, description: string}} = {
    'user-report': {
      label: 'Obtenlo por solo',
      icon: <SummarizeIcon style={{ fontSize: 30 }} />,
      description: '¡El precio más conveniente del mercado!',
    },
  };

const getProductTypeLabels = (type: string) => {
  if (Object.keys(productTypeLabels).includes(type)) {
    return productTypeLabels[type];
  }
  return { label: type, icon: null, description: null };
};

type ProductsOverviewProps = {
    token: string;
    productsData: ProductsData['data'];
}

export default function ProductsOverview(props:ProductsOverviewProps) {
  const { token, productsData } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [product] = React.useState(productsData[0]);

  const backToAccount = () => {
    history.push('/account/products');
  };
  const goToUserReportDemo = () => {
    history.push('/account/products/user-report/demo');
  };

  React.useEffect(() => {
    analytics.page('PRODUCTOS', 'HP PRODUCTOS');
  }, []);

  return (
    <div className={classes.contentContainer}>
      {token
      && (
      <BackButton label="Volver a Guia.Me" onClickAction={backToAccount} />
      )}
      <Grid
        container
        columnGap={10}
        className={classes.gridContainer}
      >
        <Grid
          item
          xs={false}
          md={6}
          sx={{ margin: 4, marginTop: { xs: 0, sm: 4 } }}
        >
          <Typography variant="h4" sx={{ display: { xs: 'none', sm: 'block' } }}>
            Banca
            <b style={{ color: '#F3005A' }}>.</b>
            Me
            {' '}
            presenta
          </Typography>
          <br />
          <Typography variant="button" fontWeight={600} style={{ background: '#F3005A', color: 'white' }}>
            Nuevo
          </Typography>
          <Typography variant="h2" fontWeight={600} sx={{ marginBottom: { xs: 3, sm: 7 } }}>
            Informe de deudas
          </Typography>
          <Typography component="div" fontWeight={600} sx={{ fontSize: { xs: '1.2rem', sm: '2rem' } }}>
            Toma control de tus finanzas: descubre y elimina tus deudas por
            {' '}
            <span style={{ color: '#F3005A' }}>
              {' '}
              {`$${product.amount.toLocaleString('es-CL')}`}
            </span>
          </Typography>
          <ListItem component="div" disablePadding sx={{ display: 'list-item', marginLeft: '16px' }}>
            <ListItemText primaryTypographyProps={{ fontSize: '1.15rem' }} primary="Detalle consolidado de tus deudas actuales y morosas incluyendo bancos, cooperativas, cajas de compensación, y casas comerciales" />
          </ListItem>
          <ListItem component="div" disablePadding sx={{ display: 'list-item', marginLeft: '16px' }}>
            <ListItemText primaryTypographyProps={{ fontSize: '1.15rem' }} primary="Conoce cómo te ven los bancos con tu puntaje credicitio" />
          </ListItem>
          <ListItem component="div" disablePadding sx={{ display: 'list-item', marginLeft: '16px' }}>
            <ListItemText primaryTypographyProps={{ fontSize: '1.15rem' }} primary="Obtén hasta un 80% de descuento sobre deudas morosas" />
          </ListItem>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          component={Paper}
          elevation={5}
          className={classes.gridItem}
          style={{ borderRadius: 20 }}
        >
          <div style={{ maxWidth: 350, margin: '20px' }}>
            {getProductTypeLabels(product.type).icon}
            <Typography
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              {getProductTypeLabels(product.type).label}
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: theme.customPalette.tertiary.main }}
              fontWeight="bold"
            >
              {`$ ${product.amount.toLocaleString('es-CL')}`}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="text.secondary"
            >
              {getProductTypeLabels(product.type).description}
            </Typography>
            <div className={classes.buttonsContainer}>
              <Button
                variant="outlined"
                style={{ width: 150 }}
                onClick={goToUserReportDemo}
              >
                Ver Ejemplo

              </Button>
              <BuyProductDialog
                token={token}
                productType={product.type}
                productId={product.id}
                amount={product.amount}
                redirectURL="/account/products"
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <div style={{ height: 70 }} />
    </div>
  );
}
