import React from 'react';
import { ReactSession } from 'react-client-session';
import {
  Grid,
} from '@mui/material';
import accountStyles from '../styles';
import VerifyEmail from '../../../feature/account/VerifyEmail';

export default function verifyEmailView() {
  const classes = accountStyles();
  const { email, firstName } = JSON.parse(ReactSession.get('User'));

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="center"
      alignItems="center"
    >
      <VerifyEmail
        email={email}
        firstName={firstName}
      />
    </Grid>
  );
}
