import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 6,
      height: 6,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '8px',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      backgroundColor: theme.palette.primary.main,
    },
    paddingLeft: 6,
    paddingRight: 6,
    [theme.breakpoints.down('md')]: {
      margin: 0,
      marginTop: 5,
    },
  },
  headerContainer: {
    backgroundColor: '#EDF1F5',
    [theme.breakpoints.down('sm')]: {
      float: 'left',
      minWidth: '100%',
    },
  },
  header: {
    display: 'grid',
    width: '100%',
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  headerItem: {
    width: '130px',
    alignSelf: 'center',
    paddingBlock: 10,
    backgroundColor: '#EDF1F5',
    [theme.breakpoints.down('md')]: {
      paddingInline: 5,
    },
  },
  tableBody: {
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      minWidth: 'fit-content',
    },
  },
  tableBodyItem: {
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  },
  tableBodyItemContent: {
    textOverflow: 'clip',
    width: '120px',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    paddingBlock: 5,
    '&::-webkit-scrollbar': {
      height: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginInline: 5,
    },
  },
}));

export default useStyles;
