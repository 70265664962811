import React from 'react';
import { Typography } from '@mui/material';
import { verifyPhone } from '../../../utils/helpers';

export const formInputs:
{[key: string]: {title:string,
  placeholder: string|undefined,
  inputs: undefined|{label: string, value: string}[]
  startAdornment: React.ReactElement|undefined,
  endAdornment: React.ReactElement|undefined
},
  } = {
    age: {
      title: '¿Cuál es tu edad?',
      placeholder: undefined,
      inputs: undefined,
      startAdornment: undefined,
      endAdornment:
  <Typography variant="body2">
    años
  </Typography>,
    },
    sex: {
      title: '¿Cuál es tu género?',
      placeholder: undefined,
      inputs: [
        { label: 'Masculino', value: 'M' },
        { label: 'Femenino', value: 'F' },
      ],
      startAdornment: undefined,
      endAdornment: undefined,
    },
    type_of_contract: {
      title: '¿Qué tipo de contrato tienes?',
      placeholder: undefined,
      inputs: [
        { label: 'Definido', value: 'DEFINIDO' },
        { label: 'Indefinido', value: 'INDEFINIDO' },
        { label: 'No tengo contrato ', value: 'DESEMPLEADO' },
      ],
      startAdornment: undefined,
      endAdornment: undefined,
    },
    brute_salary: {
      title: '¿Cuál es tu sueldo bruto?',
      placeholder: 'e.j: 550.000',
      inputs: undefined,
      startAdornment:
  <Typography variant="body2">
    $
    {' '}
  </Typography>,
      endAdornment: undefined,
    },
    net_salary: {
      title: '¿Cuál es tu sueldo líquido?',
      placeholder: 'e.j:454.000',
      inputs: undefined,
      startAdornment:
  <Typography variant="body2">
    $
    {' '}
  </Typography>,
      endAdornment: undefined,
    },
    emp_length: {
      title: '¿Cuánto meses llevas en tu último empleo?',
      placeholder: undefined,
      inputs: undefined,
      startAdornment: undefined,
      endAdornment:
  <Typography variant="body2">
    meses
  </Typography>,
    },
    licenses: {
      title: '¿Cuántos días de licencia has tomado en los últimos 2 meses?',
      placeholder: undefined,
      inputs: undefined,
      startAdornment: undefined,
      endAdornment:
  <Typography variant="body2">
    meses
  </Typography>,
    },
  };

export const handleInputSetter = (type: 'Phone'|string, setFieldValue: (
  fieldName: string, val:string)=> void, fieldName: string, value: string) => {
  switch (type) {
    case 'Phone':
      return verifyPhone(value, setFieldValue, fieldName);
    default:
      return setFieldValue(fieldName, value);
  }
};
