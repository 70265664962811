import React from 'react';
import {
  Card,
  Grid,
} from '@mui/material';
import jwtDecode from '../../../utils/jwtDecode';
import AccountNavbar from '../../../components/AccountNavbar';
import type { UserAccountComponent } from '../../../types/account';
import FintocWidget from '../../../components/FintocWidget';
import ViewTitle from '../../../components/ViewTitle';
import avatarFinger from '../../../assets/avatar_finger.svg';

export default function PacView(props: UserAccountComponent) {
  const { token } = props;
  const { hasLoan, hasInvestment } = jwtDecode(token);
  const { idNumber } = jwtDecode(token);
  return (
    <>
      <AccountNavbar
        showLoan={hasLoan}
        showInvestment={hasInvestment}
        showWhatsapp
        tab="Pagos"
      />
      <ViewTitle
        title="Pagos"
        color="secondary"
        variant="h4"
        subtitle="En esta sección podrás validar que tu Pago (PAC) esta activo, o en su defecto asociar el correspondiente."
      />
      <Grid
        container
        rowGap={10}
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          md={5}
          margin={15}
        >
          <Card
            style={{
              borderRadius: '20px',
            }}
          >
            <div style={{
              padding: 20, justifyContent: 'center', display: 'flex',
            }}
            >
              <object style={{ width: 75, alignSelf: 'center' }} data={avatarFinger} aria-labelledby="Bancamin" />
            </div>
            <div>
              <FintocWidget
                idNumber={idNumber}
                token={token}
              />
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
