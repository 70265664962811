import React from 'react';
import {
  Button,
  Card,
  Typography,
} from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import avatarSmilingWhite from '../../../assets/avatar_smiling_white.svg';
import useStyles from './styles';
import analytics from '../../../utils/analytics';

type RenegotiateDebtsProps = {
  idNumber: string,
  name: string,
  negotiableDebts: {debts: string[]},
}

export default function RenegotiateDebts(props: RenegotiateDebtsProps) {
  const {
    idNumber,
    name,
    negotiableDebts,
  } = props;
  const classes = useStyles();
  const action = negotiableDebts.debts.length > 0 ? 'Renegociar' : 'Repactar';
  const goToRenegotiateDebts = () => {
    if (!window.location.href.includes('demo')) {
      analytics.track('Click Boton', { text: `${action} deudas`, initiator: 'usuario' });
      window.open(`https://relif.cl/bancame-sign-mandate?rut=${idNumber}&firstName=${name}`, '_blank');
    }
  };
  return (
    <div className={classes.renegotiateDebts}>
      <Typography gutterBottom variant="h6">
        Recomendaciones
        {' '}
        <AutoAwesomeIcon fontSize="small" />
      </Typography>
      <Typography
        gutterBottom
        variant="caption"
        color="text.secondary"
        component="div"
      >
        Lo que te propone Banca.Me

      </Typography>
      <Card
        elevation={10}
        className={classes.renegotiateDebstCard}
        style={{
          borderRadius: 20, textAlign: 'center', padding: 20, marginTop: '10px',
        }}
      >
        <Typography
          gutterBottom
          maxWidth={250}
          margin="auto"
          fontWeight="bold"
          color="white"
          variant="h6"
          height="10%"
        >
          Descubre si calificas a un descuento sobre tu deuda

        </Typography>
        <br />
        <div style={{
          display: 'flex', alignItems: 'center', placeContent: 'center', height: '90%',
        }}
        >
          <div>
            <object
              data={avatarSmilingWhite}
              className={classes.avatar}
              aria-labelledby="Bancamin"
            />
          </div>
          <Button
            variant="contained"
            color="secondary"
            style={{ maxWidth: 180, fontWeight: 'bold', marginLeft: 20 }}
            onClick={goToRenegotiateDebts}
          >
            {action}
            {' '}
            deudas
          </Button>
        </div>

      </Card>
    </div>
  );
}
