import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    margin: '16px',
    marginLeft: '16px',
    width: '95%',
    columnGap: 30,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      margin: '10px auto',
      rowGap: 10,
    },
  },
  infoCard: {
    padding: '5%',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 2fr',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
