import React from 'react';
import {
  Button,
  Card,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import ViewTitle from '../../../components/ViewTitle';
import useStyles, { sx } from './styles';
import type { ProductsListProps } from './types';
import avatarExcited from '../../../assets/avatar_excited.svg';
import avatarFinger from '../../../assets/avatar_finger.svg';
import { checkDaysThreshold } from '../../../utils/helpers';
import analytics from '../../../utils/analytics';

export default function ActionLinks(props:ProductsListProps) {
  const { userReportsData } = props;
  const classes = useStyles();
  const history = useHistory();
  const goToProductsShop = () => {
    history.push('/products');
  };
  const goToBlog = () => {
    analytics.track('Click Boton', { text: 'Blog bienestar financiero', initiator: 'usuario' });
    window.open('https://www.banca.me/blog');
  };

  return (
    <>
      <ViewTitle
        title="Enlaces"
        color="alternativePrimary"
        variant="body1"
        disablePadding
      />
      <Card
        sx={sx().cardLinkContainer}
      >
        <div style={{ paddingRight: 5 }}>
          <object className={classes.avatar1} data={avatarExcited} aria-labelledby="Bancamin" />
        </div>
        <div style={{ margin: '5px' }}>
          <Typography
            variant="body2"
            gutterBottom
            maxWidth={250}
          >
            ¡Accede a un
            {' '}
            <b>resumen y análisis de tu información financiera</b>
            !
          </Typography>
          <Button
            variant="contained"
            onClick={goToProductsShop}
            style={{ maxWidth: '240px' }}
            disabled={userReportsData.reports.length > 0 && checkDaysThreshold(
              new Date(userReportsData.reports[0].createdAt),
              31,
            )}
          >
            Comprar informe de deudas
          </Button>
          {userReportsData.reports.length > 0 && checkDaysThreshold(
            new Date(userReportsData.reports[0].createdAt),
            31,
          )
            ? (
              <Typography
                variant="caption"
                color="text.secondary"
                maxWidth={250}
                margin="5px auto"
                component="div"
              >
                Debe pasar al menos un mes para
                que puedas comprar el siguiente informe de deudas
              </Typography>
            )
            : (
              <Typography
                color="text.secondary"
                variant="caption"
                maxWidth={250}
                marginTop={1}
                component="div"
              >
                <>
                  Recuerda que este informe requiere
                  {' '}
                  <b>de tu ClaveÚnica</b>
                </>
              </Typography>
            )}
        </div>
      </Card>
      <Card
        sx={sx().cardLinkContainer}
        style={{
          marginBlock: 30,
        }}
      >
        <div style={{ paddingRight: 10, paddingLeft: 5 }}>
          <object className={classes.avatar2} data={avatarFinger} aria-labelledby="Bancamin" />
        </div>
        <div>
          <Typography maxWidth={290} variant="body2" gutterBottom>
            ¡Entérate los mejores tips de
            {' '}
            <b>bienestar finaciero</b>
            {' '}
            en nuestro Blog!
          </Typography>
          <Button variant="contained" style={{ maxWidth: '240px' }} onClick={goToBlog}>Blog bienestar financiero</Button>
        </div>
      </Card>
    </>
  );
}
