import React from 'react';
import { CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import JsPDF from 'jspdf';
import axios from 'axios';

export type AWSUrl = {
  url: string,
  fields: {
    bucket: string,
    key: string,
    Policy: string,
    'X-Amz-Algorithm': string,
    'X-Amz-Credential': string,
    'X-Amz-Date': string,
    'X-Amz-Signature': string,
  }
}

export const documentsBody = (fileUrl: AWSUrl, file: File) => {
  const fileURL = fileUrl;
  const formDataFileUrl = new FormData();
  formDataFileUrl.append('Content-Type', file.type);
  formDataFileUrl.append('acl', 'private');
  formDataFileUrl.append('key', fileURL.fields.key);
  formDataFileUrl.append('bucket', fileURL.fields.bucket);
  formDataFileUrl.append('X-Amz-Algorithm', fileURL.fields['X-Amz-Algorithm']);
  formDataFileUrl.append('X-Amz-Credential', fileURL.fields['X-Amz-Credential']);
  formDataFileUrl.append('X-Amz-Date', fileURL.fields['X-Amz-Date']);
  formDataFileUrl.append('Policy', fileURL.fields.Policy);
  formDataFileUrl.append('X-Amz-Signature', fileURL.fields['X-Amz-Signature']);
  formDataFileUrl.append('success_action_status', '201');
  formDataFileUrl.append('file', file);
  return formDataFileUrl;
};

export const createFileURL = (
  file: File | undefined,
  stateSetter: React.Dispatch<React.SetStateAction<string>>,
) => {
  if (file !== undefined) {
    stateSetter(URL.createObjectURL(file));
  }
};
export const getUploadICon = (
  isLoading: boolean,
  File: File | undefined,
  uploadedColor: string,
) => {
  if (isLoading) {
    return <CircularProgress size={15} />;
  }
  return (
    <CloudUploadIcon
      style={{ color: File ? uploadedColor : undefined }}
    />
  );
};

export const FILE_SIZE = 5000 * 1024;
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
];

export const changeFileURL = (refs: React.RefObject<HTMLCanvasElement>[], file:File) => {
  const firstCanvas = refs[0].current as HTMLCanvasElement;
  const pdf = new JsPDF({
    format: firstCanvas ? [firstCanvas.width, firstCanvas.height] : undefined,
  });
  refs.forEach(async (ref, idx) => {
    const canvas = (ref.current as HTMLCanvasElement);
    if (idx > 0) {
      pdf.addPage();
    }
    const img = canvas.toDataURL('image/png', 1);
    pdf.addImage(img, 'PNG', 0, 0, firstCanvas.width, firstCanvas.height, undefined, 'SLOW');
  });

  const blobPDF = pdf.output('blob');
  const blobFile = new File([blobPDF], `[UNLOCKED]-${file?.name as string}`);
  return blobFile;
};

export const postAWSDocument = async (
  fileInputRefs:React.RefObject<HTMLCanvasElement>[],
  file:File,
  awsUrl: {url: AWSUrl},
) => {
  const newFile = fileInputRefs.length > 0
    ? changeFileURL(fileInputRefs, file) : file;
  const { url } = awsUrl.url;
  const liqBody = documentsBody(awsUrl.url, newFile);
  const res = await axios.post(
    url,
    liqBody,
    {
      headers: {
        'Content-Type': `${file.type}`,
      },
      data: liqBody,
    },
  );
  return res;
};
