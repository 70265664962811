import React from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import claveUnicaStyles from './styles';
import AgentContactButton from '../AgentContactButton';
import analytics from '../../utils/analytics';

export type ErrorTypeType = ''
  | 'passwordError'
  | 'dataExtractionError'
  | 'blockedPassword'
  | 'afcBlockedPassword'
  | 'connectionError'
  | 'maintenanceError'
  | 'notSubscribedError'

export default function ErrorDialogContent(params: {
  errorType: ErrorTypeType,
  retry?: ()=> void,
  numTries?: number,
  partner: string
}) {
  const {
    errorType, retry, numTries, partner,
  } = params;
  const classes = claveUnicaStyles();
  const theme = useTheme();
  const goToFogotPassword = () => {
    analytics.track('Click Link', {
      text: '¿Olvidaste tu ClaveÚnica?',
      initiator: 'usuario',
    });
    window.open('https://claveunica.gob.cl/recuperar');
  };
  const goToAFC = () => {
    analytics.track('Click Link', {
      text: 'Administradora de Fondos de Cesantía (AFC)',
      initiator: 'usuario',
    });
    window.open('https://webafiliados.afc.cl/WUI.AAP.OVIRTUAL/Default.aspx');
  };
  switch (errorType) {
    case 'blockedPassword':
      return (
        <div>
          <Typography gutterBottom variant="body2">
            <b>Tu ClaveÚnica se encuentra bloqueada</b>
            ,
            por favor presiona el siguiente enlace para recuperarla:
            {' '}
            <b
              onClick={goToFogotPassword}
              className={classes.passwordForgotten}
              aria-hidden
            >
              Recuperar ClaveÚnica

            </b>
            {' '}
            y vuelve con nosotros. También tienes la
            posibilidad de subir tus archivos para analizar
            tu situación financiera,
            pero el proceso es más lento.
          </Typography>
        </div>
      );
    case 'passwordError':
      return (
        <>
          {numTries as number > 2
            ? (
              <Typography gutterBottom variant="body2">
                <b>Llevas muchos intentos de ingresar tu ClaveÚnica sin éxito. </b>
                Por favor presiona el enlace
                {' '}
                <b>¿Olvidaste tu ClaveÚnica?</b>
                {' '}
                si es que la quieres recuperar y
                vuelve con nosotros.
              </Typography>
            )
            : (
              <Typography gutterBottom variant="body2">
                <b
                  style={{
                    color: theme.customPalette.tertiary.main,
                  }}
                >
                  Has ingresado de manera incorrecta tu contraseña de ClaveÚnica
                </b>
                , por lo que no pudimos consultar tus datos.
                Te recordamos que si ingresas incorrectamente tu contraseña reiteradas veces,
                {' '}
                <b>es posible que se te bloquee.</b>
              </Typography>
            )}
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h4" align="center" display="inline">
              {numTries}
              /
            </Typography>
            <Typography
              variant="h4"
              align="center"
              display="inline"
              color="error"
            >
              2

            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              fontWeight="bold"
              align="center"
              color="primary"
            >
              Intentos

            </Typography>
          </div>
          <div
            style={{ textAlign: 'center', marginTop: 10 }}
            onClick={goToFogotPassword}
            className={classes.passwordForgotten}
            aria-hidden
          >
            ¿Olvidaste tu ClaveÚnica?
          </div>
        </>
      );
    case 'dataExtractionError':
      return (
        <div>
          <Typography gutterBottom variant="body2" textAlign="justify" component="div">
            <div>
              Lo sentimos,
              {' '}
              <b style={{ color: theme.customPalette.tertiary.main }}>
                pero no pudimos consultar toda tu información.
              </b>
              {' '}
              {numTries as number > 2 ? '' : 'Por favor inténtalo de nuevo ingresando tu ClaveÚnica.'}

              {' '}

            </div>
            <br />
            <div>
              {numTries as number > 2
                ? (
                  <span>
                    Por favor contáctate con nuestros ejecutivos mediante WhatsApp para
                    {' '}
                    <b>realizar una evaluación personalizada para tu caso</b>
                  </span>
)
                : 'Si el problema persiste, por favor contáctate con nuestros ejecutivos mediante WhatsApp.'}
            </div>
          </Typography>
          <div style={{ textAlign: 'center' }}>
            <AgentContactButton
              hide={partner !== 'Banca.Me'}
            />
          </div>
        </div>
      );
    case 'maintenanceError':
      return (
        <div>
          <Typography gutterBottom variant="body2" component="div">
            <div>
              <b>Los servicios consultados se encuentran en mantención.</b>
              {' '}
              Por favor vuelve más tarde para que podamos
              {' '}
              <b style={{ color: theme.customPalette.tertiary.main }}>
                evaluarte oportunamente.
              </b>
            </div>
            <br />
            <div>
              ¡Disculpa las molestias!
            </div>
          </Typography>
        </div>
      );
    case 'afcBlockedPassword':
      return (
        <div>
          <Typography gutterBottom variant="body2" component="div">
            <b>
              Hay un problema con tu contraseña en la AFC (Administradora de fondos de cesantia),
              debido a que esta se encuentra bloqueada o aún no la has creado.
            </b>
            {' '}
            Puedes revisar tu situación en:
            {' '}
            <b
              onClick={goToAFC}
              className={classes.passwordForgotten}
              aria-hidden
            >
              Administradora de Fondos de Cesantía (AFC)

            </b>
            {' '}
            ingresando tu ClaveÚnica.
            <p>
              ¡Por favor vuelve con nosotros cuando hayas
              {' '}
              <b>recuperado / creado</b>
              {' '}
              tu contraseña!
            </p>
          </Typography>
        </div>
      );
    case 'notSubscribedError':
      return (
        <div>
          <Typography gutterBottom variant="body2" component="div">
            <div>
              <b>No apareces inscrito en la Administradora de Fondos de Cesantía (AFC).</b>
              {' '}
              Puedes revisar tu situación en:
              {' '}
              <b
                onClick={goToAFC}
                className={classes.passwordForgotten}
                aria-hidden
              >
                Administradora de Fondos de Cesantía (AFC).

              </b>
            </div>
            {' '}
            <br />
            <div>
              <b> Atención:  </b>
              {' '}
              Si eres funcionario de alguna entidad pública (Carabineros,
              Municipalidades, Ejército, Hospitales, entre otros),
              por el momento no podremos atenderte debido a que no poseemos
              convenio con aquellas instituciones.
            </div>
            {' '}
            <br />
            <div>
              Por otra parte, si estás recién comenzado a trabajar con contrato,
              la AFC normalmente presenta demora en actualizarse.
            </div>
            <br />
            <div>Si piensas que esto es un error, por favor contáctate con nosotros.</div>
            <div style={{ textAlign: 'center' }}>
              <AgentContactButton
                hide={partner !== 'Banca.Me'}
              />
            </div>
          </Typography>
        </div>
      );
    case 'connectionError':
      return (
        <>
          <Typography gutterBottom variant="body2">
            <b
              style={{
                color: theme.customPalette.tertiary.main,
              }}
            >
              Hubo un error en la conexión de nuestra aplicación
              con tu ClaveÚnica
            </b>
            , por lo que no pudimos consultar tus datos.
            Por favor inténtalo de nuevo para que podamos
            {' '}
            <b>evaluar tu situación oportunamente.</b>
            {' '}
            En el caso de reiterados fallos, tendremos que pedirte que vuelvas en otro
            momento o ingreses tus datos manualmente.
          </Typography>
          <br />
          <div style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              onClick={retry}
            >
              Reintentar

            </Button>
          </div>
        </>
      );
    default:
      return <div />;
  }
}
