import { Currency } from '../../types/account';

export type installmentMarkType = {
    installment: number;
    value: number;
    monthlyCAE: number,
    interestRate: number
  }

export type installmentMarksObjectType = {
    [key: number]: installmentMarkType[]
}

export type AmountMarksType = {
  marks: installmentMarksObjectType,
  maxAmount: number,
  insuranceRates: {[key: number]: number},
}

export type InsuranceType = {
  id: string,
  label:string,
  premiumMultiplier:number,
  type: 'death'|'incapacity'|'unemployment'|'death-downpayment'|string,
  description: string,
  minPeriods: number,
  maxPeriods: number
}
export type LoanParams = {
  canGetLoan: boolean,
  alpha: number,
  beta: number,
  payProb: {
      [key: string]: number,
  },
  originCost: number
  maxInstallmentAmount: number,
  riskEngineMaxAmount: number,
  riskEngineMaxPeriod: number,
  basePaymentMethod: 'dpp'|'pac'|'pat',
  productType: 'consumer'|'microLoan'|'mortgage'|'downPayment',
  preApproveId: string,
  amountStepSize: number,
  insurances: InsuranceType[],
  minAmount: number,
  onlyStepSize: boolean,
  paymentDate: number,
  currency: Currency
}

export type SimulatorComponentType = {
    loanParams: LoanParams,
    isLoan?: boolean,
    requestLoan?: (params: {
      amount: number,
      interestRate: number,
      periods: number,
      installment: number,
      totalCost: number,
      monthlyCAE: number,
      CAE:number,
      originCost: number,
      taxes: number,
      insuranceCost: number,
      isInitialState: boolean
    }) => void,
    isPLR?: boolean,
    fixAmount?: number
    fixPeriod?: number,
    reset?: boolean,
    disabledSliders?: boolean,
    handleLoadingSimulation: (isLoading: boolean) => void,
    handleRejection: () => void,
    offerMaxAmount: boolean,
    origin: string,
    amountMarks: AmountMarksType|undefined,
  }

export const defaultProps = {
  loanParams: {
    canGetLoan: true,
    alpha: -0.11764706,
    beta: 0.25235294,
    gamma: 0.01333333,
    delta: 7333.333,
    payProb: {
      3: 0.72,
      6: 0.70,
      9: 0.68,
      12: 0.65,
      15: 0.60,
      18: 0.55,
      24: 0.50,
      36: 0.45,
    },
    originCost: 10000,
    riskEngineMaxAmount: 1500000,
    maxInstallmentAmount: 500000,
    riskEngineMaxPeriod: 36,
    basePaymentMethod: 'pac',
    productType: 'consumer',
    preApproveId: '',
    amountStepSize: 25000,
    minAmount: 25000,
    onlyStepSize: false,
    currency: {
      abbreviation: 'CLP',
      precision: 0,
    },
    insurances: [],
    paymentDate: 10,
  } as LoanParams,
  isLoan: false,
  requestLoan: () => { },
  isPLR: true,
  fixAmount: null,
  fixPeriod: null,
  reset: false,
  disabledSliders: false,

};

export type simulationStateType = {
    amount: number,
    interestRate: number,
    period: number,
    installment: number,
    sliderMinPeriod: number,
    sliderMaxPeriod: number,
    installmentMarks: installmentMarkType[],
    periods: number,
    totalCost: number,
    monthlyCAE: number,
    CAE: number,

}
