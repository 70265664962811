import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  termsAndConditions: {
    fontSize: '0.8em',
    margin: '30px 0',
    fontStyle: 'italic',
    opacity: 0.8,
  },
});

export default useStyles;
