import React from 'react';
import {
  Grid, Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import AccountNavbar from '../../components/AccountNavbar';
import jwtDecode from '../../utils/jwtDecode';
import { getLoan } from '../../queries/account';
import type { LoanOverViewType, UserAccountComponent } from '../../types/account';
import AccountDataLoading from '../../components/DataLoading';
import LoanOverview from '../../feature/account/LoanOverview';
import ViewTitle from '../../components/ViewTitle';
import analytics from '../../utils/analytics';

export default function AccountIndex(props: UserAccountComponent) {
  const history = useHistory();
  const { token } = props;
  const {
    firstName, hasLoan, hasInvestment,
  } = jwtDecode(token);
  const { loanData, loanDataLoading, loanDataError } = getLoan(token, true);

  const [firstMessage, setFirstMessage] = React.useState('Pareces no tener un crédito activo con nosotros');
  const [secondMessage, setSecondMessage] = React.useState('En el caso de que no poseas créditos con nosotros, te redirigiremos para que realices una solicitud.');

  const errorOrNoActiveLoan = (loanDataError || (loanData && Object.keys(loanData).length === 0));
  const hasActiveLoan = (loanData && Object.keys(loanData).length > 0);

  React.useEffect(() => {
    if (loanDataLoading === false && !hasLoan && hasInvestment === false) {
      setFirstMessage('Te damos la bienvenida');
      setSecondMessage('Te redirigemos a la página de productos Banca.Me');
      setTimeout(() => {
        history.push('/account/products');
      }, 5000);
    } else if (loanDataLoading === false && hasLoan && errorOrNoActiveLoan) {
      setFirstMessage('Gracias por haber tomado un crédito con nosotros');
      setSecondMessage('¡Te redirigiremos a referidos para que continues mejorando tu situacion financiera con nosotros!');
      setTimeout(() => {
        history.push('/account/referrals');
      }, 5000);
    } else if (loanDataLoading === false && errorOrNoActiveLoan && hasInvestment) {
      setFirstMessage('Gracias por haber invertido');
      setSecondMessage('¡Te redirigiremos a tu inversión para que continues mejorando tu situacion financiera con nosotros!');
      setTimeout(() => {
        history.push('/account/investment');
      }, 5000);
    } else if (loanDataLoading === false) {
      analytics.page('PU - TU CREDITO', 'RESUMEN');
    }
  }, [loanDataLoading]);

  return (
    <>
      <AccountNavbar
        showWhatsapp={!loanDataLoading && !(loanData && Object.keys(loanData).length === 0)}
        showButtons={!(loanData && Object.keys(loanData).length === 0)}
        showInvestment={hasInvestment}
        showLoan={hasLoan}
        tab={hasLoan ? 'Tu crédito' : 'Guia.Me'}
      />
      <Grid
        container
      >
        {(loanDataLoading || errorOrNoActiveLoan)
          && (
            <AccountDataLoading
              firstText={loanDataLoading ? 'Estamos consultando tus datos...' : firstMessage}
              secondText={secondMessage}
            />
          )}
        {hasActiveLoan && loanData && (
        <>
          <ViewTitle
            title={`Te damos la bienvenida ${firstName}`}
            color="secondary"
            variant="h4"
          />
          <ViewTitle
            lg={11}
            title="Mis productos financieros"
            subtitle="Aquí podras ver el detalle de los créditos que se gestionan en nuestra plataforma"
            color="alternativePrimary"
            variant="h6"
          />
          <Typography
            fontWeight="bold"
            component="div"
            style={{
              display: 'flex',
              placeContent: 'space-between',
              width: '100%',
              marginInline: 20,
              marginBlock: 10,
              textDecoration: 'underline',
            }}
          >
            <div>
              Tipo de producto
            </div>
            <div>
              Partner y método de pago
            </div>
          </Typography>
          {loanData.map((loan: LoanOverViewType) => (
            <div key={loan.loan.id}>
              <LoanOverview
                token={token}
                loanData={loan}
                defaultExpanded={loanData.length === 1}
              />
            </div>

          ))}
          <div style={{ margin: 20 }}>
            <Typography variant="body2" gutterBottom component="div">
              <b>[DPP]:</b>
              {' '}
              Descuento Por Planilla
            </Typography>
            <Typography variant="body2" component="div">
              <b>[PAC]:</b>
              {' '}
              Pago Automático de Cuentas

            </Typography>
          </div>
        </>

        )}

      </Grid>
    </>
  );
}
