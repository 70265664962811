import React from 'react';
import {
  Route, Redirect, Switch,
} from 'react-router-dom';
import AccountIndex from '../../pages/account';
import PartialPrepay from '../../pages/account/partial-prepay';
import TotalPrepay from '../../pages/account/total-prepay';
import FullPrepayOverview from '../../pages/account/total-prepay/overview';
import LoginForm from '../../pages/account/login';
import RegisterForm from '../../pages/account/register';
import RecoverPasswordForm from '../../pages/account/recover-password';
import SetPasswordForm from '../../pages/account/set-password';
import VerifyEmail from '../../pages/account/verify-email';
import Referrals from '../../pages/account/referrals';
import ProtectedRoute from '../../components/ProtectedRoute';
import ProductsView from '../../pages/account/products';
import UserReportView from '../../pages/account/products/user-report';
import RefinanceView from '../../pages/account/refinance';
import InvestmentView from '../../pages/account/investment';
import UserReportPdfView from '../../pages/account/products/user-report/pdf';
import PacView from '../../pages/account/pac';

function AccountRoutes() {
  return (
    <Route
      render={
        ({ location }) => (
          <Switch location={location}>
            <Route exact path="/account/login" component={LoginForm} />
            <Route exact path="/account/register" component={RegisterForm} />
            <Route exact path="/account/recover-password" component={RecoverPasswordForm} />
            <Route exact path="/account/set-password" component={SetPasswordForm} />
            <Route exact path="/account/verify-email" component={VerifyEmail} />
            <ProtectedRoute exact path="/account" component={AccountIndex} />
            <ProtectedRoute exact={false} path="/account/partial-prepay/:loanId" component={PartialPrepay} />
            <ProtectedRoute exact path="/account/total-prepay/:loanId" component={TotalPrepay} />
            <ProtectedRoute exact path="/account/total-prepay/overview/:loanId" component={FullPrepayOverview} />
            <ProtectedRoute exact path="/account/referrals" component={Referrals} />
            <ProtectedRoute exact path="/account/pac" component={PacView} />
            <ProtectedRoute exact path="/account/products" component={ProductsView} />
            <Route exact path="/account/products/user-report/demo" component={UserReportView} />
            <ProtectedRoute exact={false} path="/account/products/user-report/:reportId/pdf" component={UserReportPdfView} />
            <ProtectedRoute exact={false} path="/account/products/user-report/:reportId" component={UserReportView} />
            <ProtectedRoute exact path="/account/investment" component={InvestmentView} />
            <ProtectedRoute exact={false} path="/account/refinance/:loanId/cashout" component={RefinanceView} />
            <ProtectedRoute exact={false} path="/account/refinance/:loanId/rate-period/" component={RefinanceView} />
            <Route path="/">
              <Redirect to="/account" />
            </Route>
          </Switch>
        )
      }
    />
  );
}

export default AccountRoutes;
