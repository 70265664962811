import React from 'react';
import { Formik, Form } from 'formik';
import { PLRFormProps } from '../../../../../../types/preloan';
import GetOrCreatePLR from './components/GetOrCreatePLR';
import { FormSchemaV3, PLRStepV3 } from '../../../../../../types/preloan/v3';
import { handlePLRstateSwitch } from './components/helpers';

export default function DataFetchingForm<T extends FormSchemaV3>(props: PLRFormProps<T>) {
  const {
    formRef,
    formSchema,
    handleEnableLoading,
    handleNext,
    handleCurrentData,
    handleStep,
  } = props;
  const [plrState, setPLRState] = React.useState<PLRStepV3|'error'|false>(false);
  const [dataLoading, setDataLoading] = React.useState(true);
  const handleSetDataLoading = (state:boolean) => setDataLoading(state);
  const handleSetPLRState = (state: PLRStepV3|'error'|false) => setPLRState(state);

  React.useEffect(() => handlePLRstateSwitch({
    plrState,
    formSchema,
    handleSetDataLoading,
    handleEnableLoading,
    handleStep,
  }), [plrState]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={formSchema}
      onSubmit={() => handleNext()}
    >
      {() => (
        <Form>
          <GetOrCreatePLR
            formSchema={formSchema}
            handleSetPLRState={handleSetPLRState}
            plrState={plrState}
            handleCurrentData={handleCurrentData}
            dataLoading={dataLoading}
            handleSetDataLoading={handleSetDataLoading}
          />
        </Form>
      )}

    </Formik>
  );
}
