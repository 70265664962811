import React from 'react';
import { Button } from '@mui/material';
import { useIntercom } from 'react-use-intercom';
import bancameLogo from '../../assets/bancame_logo_sans.svg';
import analytics from '../../utils/analytics';

type AgentContactButtonProps = {
  size?: 'small' | 'normal',
  trackCallCTA?: string,
  hide?: boolean,
  reportError?: boolean,
  eventId?: string
}

const defaultProps = {
  size: 'normal',
  trackCallCTA: 'form bancame',
  hide: false,
  reportError: false,
  eventId: '',
};

export default function AgentContactButton(props: AgentContactButtonProps) {
  const {
    size, trackCallCTA, hide, reportError, eventId,
  } = props;
  const { show: showIntercom, showNewMessage } = useIntercom();
  const handleOpenIntercom = () => {
    analytics.track(reportError ? 'Reportar error' : 'Contactar Agente', {
      cta: trackCallCTA,
      initiator: 'usuario',
    });
    if (reportError) {
      showNewMessage(`¡Hola! Ha ocurrido un error inesperado: El ID del error es: ${eventId}`);
    } else {
      showIntercom();
    }
  };
  return (
    hide
      ? <div />
      : (
        <Button
          onClick={handleOpenIntercom}
          variant="outlined"
          startIcon={(
            <object
              data={bancameLogo}
              aria-labelledby="Banca.Me logo"
              height={size === 'small' ? 23 : '30px'}
              style={{ marginBottom: '-5px' }}
            />
      )}
          style={{ fontSize: size === 'small' ? 13 : undefined }}
        >
          {reportError ? 'Reportar error' : 'Contactar a un agente'}

        </Button>
      )
  );
}

AgentContactButton.defaultProps = defaultProps;
