import React from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  IconButton, Grid, Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { ReactSession } from 'react-client-session';
import bancameLogo from '../../assets/bancame_logo.svg';
import useStyles from './styles';
import AlertDialog from '../AlertDialog';
import { useIsWidthDown } from '../../utils/widthHooks';
import jwtDecode from '../../utils/jwtDecode';
import {
  totalTabs,
  onlyInvestTabs,
  onlyLoanTabs,
  onlyProductsTab,
  StyledTab,
  StyledTabs,
  mobileIcons,
  logOut,
} from './helpers';

type AccountNavbarProps = {
  tab: 'Tu crédito'|'Guia.Me'|'Referidos'|'Inversiones'|'Pagos',
  showWhatsapp?: boolean,
  showButtons?: boolean,
  showInvestment: boolean,
  showLoan: boolean,
}

const defaultProps = {
  showWhatsapp: true,
  showButtons: true,
};

export default function AccountNavbar(props:AccountNavbarProps) {
  const {
    showWhatsapp, showButtons, showInvestment, tab, showLoan,
  } = props;

  const {
    userId, idNumber, email, firstName, lastName,
  } = jwtDecode(ReactSession.get('token'));

  const { boot: bootIntercom, shutdown: shutdownIntercom } = useIntercom();

  function getShowableTabs() {
    if (showInvestment && !showLoan) {
      return onlyInvestTabs;
    }
    if (!showInvestment && showLoan) {
      return onlyLoanTabs;
    }
    if (!showInvestment && !showLoan) {
      return onlyProductsTab;
    }
    return totalTabs;
  }

  const classes = useStyles();
  const history = useHistory();
  const isMdDown = useIsWidthDown('md');
  const showableTabs = getShowableTabs();
  const mobileTabs = showableTabs.filter((t) => t !== 'Pagos');
  const [value, setValue] = React.useState<number>(showableTabs.indexOf(tab));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue !== showableTabs.length - 1) {
      setValue(newValue);
    }
    if (value !== newValue) {
      if (showableTabs[newValue] === 'Guia.Me') {
        return history.push('/account/products');
      }
      if (showableTabs[newValue] === 'Inversiones') {
        return history.push('/account/investment');
      }
      if (showableTabs[newValue] === 'Referidos') {
        return history.push('/account/referrals');
      }
      if (showableTabs[newValue] === 'Tu crédito') {
        return history.push('/account');
      }
      if (showableTabs[newValue] === 'Pagos') {
        return history.push('/account/pac');
      }
    }
    return undefined;
  };

  const payMentButtonActive = () => {
    if (window.location.href.includes('/account/pac')) {
      return 0;
    }
    return false;
  };

  React.useEffect(() => {
    if (showWhatsapp) {
      bootIntercom({
        name: `${firstName} ${lastName}`,
        email,
        userId,
        verticalPadding: 55,
        customAttributes: { idNumber },
      });
    } else {
      shutdownIntercom();
    }
  }, [showWhatsapp]);

  return (
    <>
      <Grid
        className={classes.navBar}
        xs={12}
        item
      >

        <img src={bancameLogo} className={classes.logo} alt="BancameLogo" />
        {(showButtons)
        && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledTabs
              value={isMdDown ? payMentButtonActive() : value}
              onChange={isMdDown ? () => history.push('/account/pac') : handleChange}
              aria-label="styled tabs example"
              sx={{ display: { xs: 'block' } }}
            >
              {!isMdDown && showLoan && (
                <StyledTab label="Tu crédito" />
              )}
              {!isMdDown && showLoan && (
                <StyledTab label="Referidos" />
              )}
              {!isMdDown && showInvestment && (
                <StyledTab label="Inversiones" />
              )}
              {!isMdDown
              && <StyledTab label="Guia.Me" />}
              {showableTabs.includes('Pagos') && (
              <StyledTab label="Pagos" />
              )}
            </StyledTabs>
            {!isMdDown
            && (
            <AlertDialog
              dialogTitle="¿Cerrar sesión?"
              dialogContent="Si presiona continuar, se redirigirá a la vista de inicio de sesión"
              action={() => logOut()}
              button={(
                <IconButton style={{ marginTop: '-5px' }}>
                  <LogoutIcon />
                </IconButton>
                    )}
              pageCall={{ category: 'PU - LOGOUT', name: 'CONFIRMAR LOGOUT' }}
            />
            )}
          </div>
        )}
      </Grid>
      {isMdDown && showButtons
        && (
        <BottomNavigation
          showLabels
          value={value}
          onChange={handleChange}
          className={classes.mobileNavigation}
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${mobileTabs.length}, 1fr)`,
            placeItems: 'center',
          }}
        >
          {mobileTabs.slice(0, mobileTabs.length - 1).map((mobileTab) => (
            <BottomNavigationAction
              key={mobileTab}
              className={classes.mobileBottomNavBarAction}
              label={<Typography variant="caption">{mobileTab}</Typography>}
              icon={mobileIcons(mobileTabs[value])[mobileTab]}
            />
          ))}

          <AlertDialog
            dialogTitle="¿Cerrar sesión?"
            dialogContent="Si presiona continuar, se redirigirá a la vista de inicio de sesión"
            action={() => logOut()}
            button={(
              <BottomNavigationAction
                className={classes.mobileBottomNavBarAction}
                style={{ paddingTop: '8px' }}
                icon={(
                  <>
                    <LogoutIcon style={{ color: showableTabs[value] === 'Cerrar sesión' ? '#F3005A' : undefined, paddingBottom: '10px', fontSize: '22px' }} />
                    <Typography noWrap variant="caption">Cerrar sesión</Typography>
                  </>
                )}
              />
              )}
            pageCall={{ category: 'PU - LOGOUT', name: 'CONFIRMAR LOGOUT' }}
          />
        </BottomNavigation>
        )}
    </>
  );
}

AccountNavbar.defaultProps = defaultProps;
