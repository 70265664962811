import React from 'react';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import avatarExcited from '../../assets/avatar_excited.svg';
import avatarStressed from '../../assets/avatar_stressed.svg';

type BancameBackdropProps = {
  openBackdrop: boolean;
  loadingText?: string|React.ReactElement,
  type?: 'loading' | 'error',
  errorRedirectURL?: string
}
const defaultProps = {
  loadingText: 'Un momento...',
  type: 'loading',
  errorRedirectURL: '/account',
};
export default function BancameBackdrop(props: BancameBackdropProps) {
  const {
    openBackdrop,
    loadingText,
    type,
    errorRedirectURL = defaultProps.errorRedirectURL,
  } = props;
  const handleErrorReditect = () => {
    window.location.href = errorRedirectURL;
  };
  return (
    <Backdrop
      sx={{
        backgroundColor: 'rgba(245, 245, 245, 0.9)',
        textAlign: 'center',
      }}
      open={openBackdrop}
    >
      <div>
        <div>
          <object
            data={type === 'loading' ? avatarExcited : avatarStressed}
            width="70px"
            aria-labelledby="AvatarExcited"
          />
        </div>
        <div>
          {' '}
          <b style={{ marginLeft: 10 }}>
            {type === 'loading' ? loadingText : (
              <>
                <div>¡Ha ocurrido un error!</div>
                {' '}
                <div>Por favor inténtalo de nuevo o contáctanos si este error persiste</div>
                <br />
                <Button
                  variant="contained"
                  onClick={handleErrorReditect}
                >
                  Reintentar

                </Button>
              </>
            )}
          </b>

        </div>
        <br />
        {type === 'loading'
          && (
            <CircularProgress
              color="inherit"
            />
          )}

      </div>

    </Backdrop>
  );
}

BancameBackdrop.defaultProps = defaultProps;
