import React from 'react';
import getProducts from '../../queries/products';
import AccountDataLoading from '../../components/DataLoading';
import AccountNavbar from '../../components/AccountNavbar';
import ProductsOverview from '../../feature/products/ProductsOverview';
import type { UserAccountComponent } from '../../types/account';
import jwtDecode from '../../utils/jwtDecode';

export default function ProductsView(props: UserAccountComponent) {
  const { token } = props;
  const { hasLoan, hasInvestment } = jwtDecode(token);
  const { productsData, productsDataLoading, productsDataError } = getProducts();

  return (
    <>
      <AccountNavbar
        showLoan={hasLoan}
        showInvestment={hasInvestment}
        showButtons={!!token}
        showWhatsapp={!!token}
        tab="Guia.Me"
      />
      {(productsDataLoading || productsDataError)
      && (
      <AccountDataLoading
        firstText="Cargando..."
        error={productsDataError}
      />
      )}
      {!productsDataError && !productsDataLoading && productsData
      && (
      <ProductsOverview
        token={token}
        productsData={productsData}
      />
      )}
    </>
  );
}
