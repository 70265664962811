import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';
import ProductsView from '../../pages/products';
import ProtectedRoute from '../../components/ProtectedRoute';

export default function ProductsRoutes() {
  return (
    <Route
      render={
        ({ location }) => (
          <Switch location={location}>
            <ProtectedRoute exact path="/products" component={ProductsView} logOut={false} />
          </Switch>
        )
}
    />
  );
}
