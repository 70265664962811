import * as Yup from 'yup';
import { checkRut, formatRut } from 'react-rut-formatter';

const MailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))|(undefined)$/;
const regionNums = ['58', '57', '55', '52', '51', '53', '32', '34', '33', '35', '72', '75', '71', '73', '41', '42', '43', '45', '63', '64', '65', '67', '61'];
const santiagoAndMobile = ['9', '2'];

export const rutValidationSchema = Yup.object().shape({
  rut: Yup.string()
    .required('Por favor ingresa tu RUT')
    .test('Validar-rut', 'RUT Inválido', (rut:any) => checkRut(rut))
    .test('Largo-rut', 'RUT demasiado corto', (rut:any) => formatRut(rut).slice(0, formatRut(rut).length - 2).length > 6),
});

export const phoneValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .test(
      'num-required',
      'Por favor ingrese un número de teléfono',
      (num: any) => num.length > 3,
    )
    .min(12, 'Por favor ingresa un número válido')
    .max(12, 'Por favor ingresa un número válido'),
});

export const companyDataValidationSchema = Yup.object().shape({
  companyName: Yup.string().required('Por favor ingrese el nombre de su empresa')
    .min(3, 'Este nombre es muy corto'),
  companyContactName: Yup.string()
    .required('Ingrese el nombre del encargado de RRHH de su empresa')
    .min(4, 'Este nombre es muy corto')
    .test(
      'surname-required',
      'Ingrese nombre y apellido',
      (nom: any) => {
        if (nom) {
          if (nom.trim().includes(' ')) {
            return true;
          }
        }
        return false;
      },
    ),
  companyContactPhone: Yup.string()
    .test(
      'num-required',
      'Ingrese un número de teléfono válido, 9 dígitos',
      (num: any) => {
        const cleanNum = num.replace(' ', '');
        if (cleanNum.length >= 3) {
          const schema = Yup.string().min(12).max(12);
          return schema.isValidSync(cleanNum);
        }
        return true;
      },
    )
    .test(
      'num-area-invalid',
      'Ingrese un código de área válido o celular en Chile',
      (num: any) => {
        const cleanNum = num.replace(' ', '');
        if (cleanNum.length === 12) {
          if (!santiagoAndMobile.includes(cleanNum[3])
            && !regionNums.includes(cleanNum.slice(3, 5))) {
            return false;
          }
        }
        return true;
      },
    ),
  companyContactMail: Yup.string()
    .required('Ingrese un e-mail de contacto de su empresa')
    .matches(
      MailRegex,
      'Por favor ingrese un E-mail válido',
    ),
});
const FILE_SIZE = 5000 * 1024;
const SUPPORTED_FORMATS = [
  'application/pdf',
];
export const lastStepValidationSchema = Yup.object().shape({
  liqFile: Yup.mixed().nullable()
    .test(
      'fileSize',
      'El archivo es muy grande (Máximo 5MB)',
      (value) => value && value.size <= FILE_SIZE,
    )
    .test(
      'fileFormat',
      'Formatos permitidos: PDF',
      (value) => value && SUPPORTED_FORMATS.includes(value.type),
    ),
});
