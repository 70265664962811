/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import jwtDecode from '../../utils/jwtDecode';

const getElementFromStorage = (element:string) => {
  ReactSession.setStoreType('sessionStorage');
  return ReactSession.get(element);
};

const isAuthenticated = (
  token: string,
  acceptedMemberRoles:string[],
  acceptedTokenIntentions: string[],
) => {
  if (token !== null && token !== undefined) {
    try {
      const { memberRole, tokenIntention, role } = jwtDecode(token);
      if (acceptedTokenIntentions.includes(tokenIntention)
      && (acceptedMemberRoles.includes(memberRole) || acceptedMemberRoles.includes(role))) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
  return false;
};

type PrivateRouteProps = {
  component: React.ElementType<{ token: string }>;
  exact: RouteProps['exact']
  path: RouteProps['path'];
  acceptedMemberRoles?: string[];
  acceptedTokenIntentions?: string[];
  notAuthenticatedRedirectUrl?: string;
  logOut?: boolean
}

const notAuthenticatedComponent = (
  logOut: boolean,
  RedirectComponent: React.ReactElement,
  CurrentComponent: React.ReactElement,
) => (logOut
  ? (
    RedirectComponent
  )
  : CurrentComponent
);

export default function ProtectedRoute(props: PrivateRouteProps) {
  const {
    component: Component,
    acceptedMemberRoles = ProtectedRoute.defaultProps.acceptedMemberRoles,
    acceptedTokenIntentions = ProtectedRoute.defaultProps.acceptedTokenIntentions,
    notAuthenticatedRedirectUrl,
    logOut = true,
    ...rest
  } = props;
  const token = getElementFromStorage('token');
  if (!Component) return null;
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        isAuthenticated(token, acceptedMemberRoles, acceptedTokenIntentions) ? (
          <Component token={token} {...routeProps} />
        ) : (
          notAuthenticatedComponent(
            logOut,
            <Redirect
              to={{
                pathname: notAuthenticatedRedirectUrl,
              }}
            />,
            <Component token="" {...routeProps} />,
          )

        )
      )}
    />
  );
}

ProtectedRoute.defaultProps = {
  acceptedMemberRoles: ['lead', 'memberClient', 'member', 'client'],
  acceptedTokenIntentions: ['clientLogin', 'memberLogin', 'userReport'],
  notAuthenticatedRedirectUrl: '/account/login',
  logOut: true,
};
