import * as Yup from 'yup';

const personalInfoValidationSchema = Yup.object().shape({
  personalInfo: Yup.object().shape({
    firstName: Yup.string().required('Indique su(s) Nombre(s)'),
    lastName: Yup.string().required('Indique su(s) Apellido(s)'),
    birthDate: Yup.date().nullable().required('Indique su Fecha de nacimiento'),
    sex: Yup.string().required('Indique su género'),
  }),
});

export default personalInfoValidationSchema;
