import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const loanDetailStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '2.5%',
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      padding: '1%',
    },

  },
  AccordionDetails: {
    display: 'grid',
    padding: '2% auto',
  },
  AccordionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 5%',
  },
  AccordionItemRightContent: {
    width: '20%',
  },
}));
export default loanDetailStyles;
