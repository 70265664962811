import React from 'react';
import {
  Grid,
} from '@mui/material';
import PartialPrepay from '../../../feature/account/PartialPrepayOverview';
import useStyles from '../styles';
import AccountNavbar from '../../../components/AccountNavbar';
import { UserAccountComponent } from '../../../types/account';
import jwtDecode from '../../../utils/jwtDecode';

export default function PartialPrepayView(props: UserAccountComponent) {
  const classes = useStyles();
  const { token } = props;
  const { hasLoan, hasInvestment } = jwtDecode(token);
  return (
    <>
      <AccountNavbar
        tab="Tu crédito"
        showLoan={hasLoan}
        showInvestment={hasInvestment}
      />
      <Grid
        container
        style={{ height: '100%' }}
        className={classes.baseBackground}
      >
        <PartialPrepay />
      </Grid>
    </>
  );
}
