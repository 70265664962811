/* eslint-disable camelcase */
import React from 'react';
import { Divider, Typography } from '@mui/material';

type NoFilterResultsProps = {
    adminComment: string,
}

export default function NoFilterResults(props:NoFilterResultsProps) {
  const { adminComment } = props;
  return ((
    <>
      <Typography variant="body2" color="secondary" fontWeight="bold" style={{ paddingBottom: 15, backgroundColor: 'whitesmoke' }}>
        {adminComment ?? 'Políticas internas de otorgamiento Banca.Me'}
      </Typography>
      <Divider />
    </>
  )
  );
}
