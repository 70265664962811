import React from 'react';
import { Grid } from '@mui/material';
import { ReactSession } from 'react-client-session';
import paymentStyles from './styles';

import MELICardPaymentBrick from '../../components/MELICardPayment';
import type { UserAccountComponent } from '../../types/account';
import postMELIPayment from '../../queries/payment';

const productTypeLabels: {[key:string]:string} = {
  'user-report': 'Informe de deudas',
};

const getProductTypeLabels = (value: string) => {
  if (Object.keys(productTypeLabels).includes(value)) {
    return productTypeLabels[value];
  }
  return value;
};

export default function PaymentIndex(props: UserAccountComponent) {
  const { token } = props;
  const classes = paymentStyles();
  const paymentData = ReactSession.get('Payment');
  if (!paymentData) {
    window.location.href = '/account';
  }
  const {
    productType, amount, productId, redirectURL, returnURL,
  } = JSON.parse(paymentData);

  const successRedirectLabels = (url: string) => {
    switch (url) {
      case '/account/products':
        return 'Te redirigiremos en breve a tus productos..';
      default:
        return 'Te redirigiremos en breve a tu cuenta...';
    }
  };

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="center"
    >
      <MELICardPaymentBrick
        title={getProductTypeLabels(productType)}
        token={token}
        initialization={{ amount }}
        productId={productId}
        redirectURL={redirectURL}
        returnURL={returnURL}
        successRedirectLabels={successRedirectLabels}
        postMELIPayment={postMELIPayment}
      />
    </Grid>
  );
}
