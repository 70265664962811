import React from 'react';
import {
  Route, Redirect, Switch,
} from 'react-router-dom';
import ProtectedRoute from '../../components/ProtectedRoute';
import PaymentIndex from '../../pages/payment';

function PaymentRoutes() {
  return (
    <Route
      render={
          ({ location }) => (
            <Switch location={location}>
              <ProtectedRoute exact path="/payment" component={PaymentIndex} />
              <Route path="/">
                <Redirect to="/account/login" />
              </Route>
            </Switch>
          )
        }
    />
  );
}

export default PaymentRoutes;
