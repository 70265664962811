import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  DialogTitle: {
    backgroundColor: theme.customPalette.opacity.primary,
    color: 'white',
  },
  Dialog: {
    '& .MuiDialog-container .MuiDialog-paper': {
      borderRadius: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-container .MuiDialog-paper': {
        marginLeft: '1%',
        marginRight: '1%',
      },
    },
  },
  DialogContent: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

export default useStyles;
