import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';
import SignaturePage from '../../pages/signature';

function SignatureRoutes() {
  return (
    <Route
      render={
        ({ location }) => (
          <Switch location={location}>
            <Route exact path="/signature" component={SignaturePage} />
          </Switch>
        )
}
    />
  );
}

export default SignatureRoutes;
