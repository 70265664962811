import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  Dialog: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-container .MuiDialog-paper': {
        margin: 0,
      },
    },
  },
  form: {
    textAlign: 'left',
    display: 'grid',
    marginBlock: '2%',
    rowGap: 10,
    columnGap: 20,
    minWidth: 400,
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      textAlignLast: 'center',
      minWidth: 0,
    },
  },
  formControl: {
    width: 400,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 325,
    },
  },
  zubaleDialogButton: {
    backgroundColor: '#004fff',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
