import React from 'react';
import { Typography } from '@mui/material';
import CustomPieChart from '../../../components/Charts/CustomPieChart';
import useStyles from './styles';

interface TotalPrepayOverviewInterface {
  data:{id: string, name: string, value: number}[],
}

export default function TotalPrepayOverview(props: TotalPrepayOverviewInterface) {
  const { data } = props;
  const classes = useStyles();
  return (
    <div className={classes.totalPrepayContainer}>
      <Typography
        variant="h5"
        fontWeight="bold"
      >
        Detalles de montos pagados por el crédito

      </Typography>
      <CustomPieChart data={data} isMoney />

    </div>
  );
}
