import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: theme.spacing(3, 15),
    padding: '30px',
    textAlign: 'center',
    minHeight: '58vh',
    maxHeight: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.customPalette.white.main,
    [theme.breakpoints.down('md')]: {
      padding: '5% 25px',
      margin: theme.spacing(3, 1),
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: '0.9em',
    '&:hover': {
      color: theme.customPalette.tertiary.main,
    },
  },
}));

export default useStyles;
