import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackgroundPic from '../../assets/fondoweb_bancamines.webp';

const paymentStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '101.5%',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${BackgroundPic})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '102vh',
    },
  },
}));

export default paymentStyles;
