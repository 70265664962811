import React from 'react';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { FormikTouched, FormikErrors } from 'formik';
import avatarConfident from '../../../../../../../assets/avatar_excited.svg';
import { verifyText, verifyPhone } from '../../../../../../../utils/helpers';
import AlertDialog from '../../../../../../../components/AlertDialog';
import { FormSchemaV3 } from '../../../../../../../types/preloan/v3';
import useStyles from './styles';

type CompanyContactFormProps ={
    archivePLR: (isRejectedBySimulator?:boolean)=>void,
    formSchema: FormSchemaV3,
    checked: boolean,
    handleSetChecked: ()=>void,
    handleBlur:(e: React.FocusEvent<any, Element>)=>void,
    handleChange:(e: React.ChangeEvent<any>)=>void,
    setFieldValue:(field: string, value: any, shouldValidate?: boolean | undefined) => void,
    touched: FormikTouched<FormSchemaV3>,
    errors: FormikErrors<FormSchemaV3>,
}

export default function CompanyContactForm(props:CompanyContactFormProps) {
  const {
    formSchema,
    checked,
    archivePLR,
    handleSetChecked,
    handleBlur,
    handleChange,
    setFieldValue,
    touched,
    errors,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <div>
        <object
          data={avatarConfident}
          aria-labelledby="Avatar"
          height="50px"
        />
      </div>
      <Typography variant="body1" align="center" gutterBottom>
        Necesitamos los datos del
        {' '}
        <b>encargado de Recursos Humanos</b>
        {' '}
        de tu Empresa (o representante)
      </Typography>
      <Divider />
      <Typography
        align="left"
        color="text.secondary"
        variant="body2"
        style={{ marginTop: 5 }}
      >
        Nombre de la empresa
      </Typography>
      <TextField
        style={{ marginBottom: 15 }}
        placeholder="Ej: Empresa S.A."
        variant="outlined"
        autoComplete="off"
        id="companyName"
        name="companyName"
        value={formSchema.companyName}
        onChange={(event) => verifyText(event.target.value, setFieldValue, 'companyName')}
        onBlur={handleBlur}
        error={touched.companyName && Boolean(errors.companyName)}
        helperText={touched.companyName ? errors.companyName : ''}
        FormHelperTextProps={{ className: classes.helperText }}
      />
      <br />
      <Typography
        align="left"
        color="text.secondary"
        variant="body2"
      >
        Nombre encargado RRHH de la empresa
      </Typography>
      <TextField
        style={{ marginBottom: 15 }}
        placeholder="Nombre y Apellido"
        variant="outlined"
        id="companyContactName"
        autoComplete="off"
        name="companyContactName"
        value={formSchema.companyContactName}
        onChange={(event) => verifyText(event.target.value, setFieldValue, 'companyContactName')}
        onBlur={handleBlur}
        error={touched.companyContactName && Boolean(errors.companyContactName)}
        helperText={touched.companyContactName ? errors.companyContactName : ''}
        FormHelperTextProps={{ className: classes.helperText }}
      />
      <br />
      <Typography
        align="left"
        color="text.secondary"
        variant="body2"
      >
        Teléfono de contacto de la empresa
      </Typography>
      <TextField
        style={{ marginBottom: 15 }}
        autoComplete="off"
        id="companyContactPhone"
        name="companyContactPhone"
        value={formSchema.companyContactPhone}
        onChange={(event) => verifyPhone(event.target.value, setFieldValue, 'companyContactPhone')}
        onBlur={handleBlur}
        error={touched.companyContactPhone && Boolean(errors.companyContactPhone)}
        helperText={touched.companyContactPhone ? errors.companyContactPhone : ''}
        FormHelperTextProps={{ className: classes.helperText }}
      />
      {' '}
      <Typography
        align="left"
        color="text.secondary"
        variant="body2"
      >
        E-mail de contacto de la empresa (sólo e-mails corporativos)
      </Typography>
      <TextField
        style={{ marginBottom: 15 }}
        placeholder="Ej: juan.perez@empresa.cl"
        autoComplete="off"
        id="companyContactMail"
        name="companyContactMail"
        value={formSchema.companyContactMail}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.companyContactMail && Boolean(errors.companyContactMail)}
        helperText={touched.companyContactMail ? errors.companyContactMail : ''}
        FormHelperTextProps={{ className: classes.helperText }}
      />
      <FormControlLabel
        control={(
          <Checkbox
            size="small"
            checked={checked}
            onClick={handleSetChecked}
          />
          )}
        label={(
          <Typography variant="caption">
            Acepto que Banca.Me contacte a mi empleador
          </Typography>
          )}
      />
      <AlertDialog
        dialogTitle={(
          <Typography
            fontWeight="bold"
            variant="h6"
            color="secondary"
            component="div"
          >
            No quiero que contacten a mi empresa
          </Typography>
          )}
        submitButtonLabel="Aceptar"
        dialogContent={(
          <>
            <Typography gutterBottom>
              Has calificado para un crédito que
              {' '}
              <b>
                se descuenta de tu liquidación
                de sueldo
              </b>
              , por lo que necesitamos contactar a tu empleador para
              establecer un convenio.
            </Typography>
            <Typography>
              Si no permites que contactemos a la empresa en que trabajas,
              {' '}
              <b>
                tu evaluación crediticia se verá finalizada en el momento que presiones
                “Aceptar”.

              </b>

            </Typography>
          </>
          )}
        button={(
          <Typography
            color="secondary"
            variant="caption"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            component="div"
          >
            No quiero que contacten a mi empleador
          </Typography>
          )}
        action={archivePLR}
        closeButtonLabel={(
          <b style={{ color: theme.customPalette.tertiary.main }}>
            VOLVER
          </b>
          )}
      />
    </>
  );
}
