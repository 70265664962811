import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  oneButtonDisplay: {
    textAlign: 'center',
    marginTop: 5,
  },
  twoButtonsDisplay: {
    textAlign: 'center',
    marginTop: 5,
    display: 'grid',
    columnGap: 20,
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  fileInput: {
    display: 'none',
  },
}));

export default useStyles;
