import React from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';

import AccountNavbar from '../../../components/AccountNavbar';
import accountStyles from '../styles';
import InvestmentOverview from '../../../feature/account/InvestmentOverview';
import { getInvestment } from '../../../queries/account';
import type { UserAccountComponent } from '../../../types/account';
import DataLoading from '../../../components/DataLoading';
import jwtDecode from '../../../utils/jwtDecode';

export default function InvestmentView(props: UserAccountComponent) {
  const classes = accountStyles();
  const { token } = props;
  const { hasLoan, hasInvestment } = jwtDecode(token);

  const { quoteBalance, quoteDataLoading, quoteDataError } = getInvestment(token);

  return (
    <>
      <AccountNavbar
        showWhatsapp={!quoteDataLoading}
        tab="Inversiones"
        showLoan={hasLoan}
        showInvestment={hasInvestment}
      />
      <Grid
        container
        style={{ height: '100%' }}
      >
        {quoteDataError
        && <Typography>¡Ha ocurrido un error! Por favor contáctanos</Typography>}
        {!quoteDataError && quoteDataLoading
          && (
            <DataLoading
              firstText="Estamos consultando tu inversión.."
            />
          )}
        {!quoteDataError && !quoteDataLoading && quoteBalance
          && (
          <div className={classes.contentContainer}>
            <InvestmentOverview
              investment={quoteBalance.investment.balance}
            />
          </div>
          )}
      </Grid>
    </>
  );
}
