import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
import { saveAs } from 'file-saver';
import excelTemplate from './excel/template_bancame.xlsx';

function getSheetData(data:any[], header:any) {
  const fields = Object.keys(data[0]);
  const sheetData = data.map((row) => fields.map((fieldName) => (row[fieldName] ? row[fieldName] : '')));
  sheetData.unshift(header);
  return sheetData;
}
function numberToColumn(n:number): string {
  const res = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[n % 26];
  return n >= 26 ? numberToColumn(Math.floor(n / 26) - 1) + res : res;
}

export default async function saveAsExcel(data:any, header:any, title:string, columnSize = 12) {
  fetch(excelTemplate)
    .then((response) => response.blob())
    .then((blob) => {
      XlsxPopulate.fromDataAsync(blob).then(async (workbook:any) => {
        const today = new Date().toLocaleDateString();
        const sheet1 = workbook.sheet(0);
        const sheetData = getSheetData(data, header) as any;
        const totalColumns = sheetData[0].length;
        sheet1.cell('B1').value(title).style({ bold: true, fontColor: '01182E' });
        sheet1.cell('B2').value(today).style({ italic: true, fontColor: '01182E' });
        sheet1.cell('B5').value(sheetData).style('border', true);
        const endColumn = numberToColumn(totalColumns);
        sheet1.row(5).style('bold', true);
        sheet1.range(`B5:${endColumn}5`).style({ fontColor: 'FFFFFF', fill: '01182E' });
        if (columnSize) {
          sheet1.column('B').width(columnSize);
          sheet1.column('C').width(columnSize);
          sheet1.column('D').width(columnSize);
          sheet1.column('E').width(columnSize);
          sheet1.column('F').width(columnSize);
          sheet1.column('G').width(columnSize);
          sheet1.column('H').width(columnSize);
          sheet1.column('I').width(columnSize);
          sheet1.column('J').width(columnSize);
          sheet1.column('K').width(columnSize);
          sheet1.column('L').width(columnSize);
          sheet1.column('M').width(columnSize);
        }
        return workbook.outputAsync().then((res: any) => {
          saveAs(res, `${today}_${title}.xlsx`);
        });
      });
    });
}
export function getFileBase64(file: File) {
  return new Promise((resolve) => {
    let baseURL = '';
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result as string;
      resolve(baseURL.split(',')[1]);
    };
  });
}
