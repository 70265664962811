import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  helperText: {
    fontSize: 'small',
    margin: 0,
    marginBottom: theme.spacing(1),
    padding: '0 2%',
    height: 0,
  },
}));

export default useStyles;
