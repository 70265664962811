import React from 'react';
import { PLRFormProps } from '../../../../../../types/preloan';
import { FormSchemaV3 } from '../../../../../../types/preloan/v3';
import DppOrPatForm from './components/DppOrPatForm';

export default function LoanSimulationForm<T extends FormSchemaV3>(props: PLRFormProps<T>) {
  const {
    formRef,
    formSchema,
    handleEnableLoading,
    handleStep,
    handleCurrentData,
  } = props;

  const handleSubmit = () => handleStep(7);
  return (
    <DppOrPatForm
      formRef={formRef}
      formSchema={formSchema}
      handleEnableLoading={handleEnableLoading}
      handleStep={handleStep}
      handleNext={handleSubmit}
      handleCurrentData={handleCurrentData}
    />
  );
}
