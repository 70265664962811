import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  navBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4),
    boxShadow: `2px 2px 4px ${theme.palette.text.secondary}`,
    borderRadius: '15px 0px 15px 15px',
    [theme.breakpoints.down('md')]: {
      height: '7%',
      padding: theme.spacing(2, 4),
    },
  },
  logo: {
    width: '45px',
    [theme.breakpoints.down('md')]: {
      width: '30px',
    },
  },
  mobileNavigation: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileBottomNavBarAction: {
    paddingTop: '6px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
}));

export default useStyles;
