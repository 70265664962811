import React from 'react';
import { ReactSession } from 'react-client-session';
import SignatureFormIndex from '../../feature/signature';

export default function SignaturePage() {
  const signatureToken = ReactSession.get('token');
  const preLoanRequest = ReactSession.get('preLoanRequest');
  return (
    <SignatureFormIndex
      token={signatureToken}
      preLoanRequest={preLoanRequest}
    />
  );
}
