import React from 'react';
import {
  Grid,
} from '@mui/material';

import AccountNavbar from '../../../components/AccountNavbar';
import accountStyles from '../styles';
import { getUserReports } from '../../../queries/account';
import type { UserAccountComponent } from '../../../types/account';
import AccountDataLoading from '../../../components/DataLoading';
import ProductsList from '../../../feature/account/ProductsList';
import jwtDecode from '../../../utils/jwtDecode';

export default function ProductsView(props: UserAccountComponent) {
  const classes = accountStyles();
  const { token } = props;
  const { hasLoan, hasInvestment } = jwtDecode(token);

  const { userReportsData, userReportsDataLoading, userReportsDataError } = getUserReports(token);

  return (
    <>
      <AccountNavbar
        showInvestment={hasInvestment}
        showLoan={hasLoan}
        showWhatsapp={!userReportsDataLoading}
        tab="Guia.Me"
      />
      <Grid
        container
        style={{ height: '100%' }}
      >
        {(userReportsDataError || userReportsDataLoading)
          && (
            <AccountDataLoading
              firstText="Estamos consultando tus productos..."
              error={!userReportsDataLoading && userReportsDataError}
            />
          )}
        {!userReportsDataError && !userReportsDataLoading && userReportsData
          && (
            <div className={classes.contentContainer} style={{ width: '100%' }}>
              <ProductsList
                userReportsData={userReportsData}
                token={token}
              />
            </div>
          )}
      </Grid>
    </>
  );
}
