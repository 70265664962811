import * as Yup from 'yup';

const zubaleUserFormValidationSchema = Yup.object().shape({
  userInput: Yup.object().shape({
    workStatus: Yup.string().required('Por favor complete este campo'),
    educationLevel: Yup.string().required('Por favor complete este campo'),
    gigworkerMotivation: Yup.string().required('Por favor complete este campo'),
    houseIncome: Yup.string().required('Por favor complete este campo'),
    economicDependents: Yup.string().required('Por favor complete este campo'),
    civilStatus: Yup.string().required('Por favor complete este campo'),
  }),
});

export default zubaleUserFormValidationSchema;
