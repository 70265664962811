import React from 'react';
import { Grid } from '@mui/material';
import accountStyles from '../styles';
import RecoverPasswordForm from '../../../components/RecorverPasswordForm';

export default function RecoverPasswordView() {
  const classes = accountStyles();

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <RecoverPasswordForm />
    </Grid>
  );
}
