import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  referralOverview: {
    backgroundColor: 'white',
    width: '95%',
    marginLeft: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: '5%',
      gridTemplateColumns: '1fr',
      margin: 'auto',
    },
  },
}));
export default useStyles;
