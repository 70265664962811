import React from 'react';
import { useIntercom } from 'react-use-intercom';
import { PreLoanRequestV3 } from '../../types/preloan/v3';
import LeadFormIndex from '../../components/LeadFormIndex';
import SignatureForm from './SignatureForm';
import { Partner, Origin } from '../../components/LeadFormIndex/types';

type SignatureFormIndexProps = {
  token: string,
  preLoanRequest: PreLoanRequestV3|undefined,
}

export default function SignatureFormIndex(props: SignatureFormIndexProps) {
  const {
    token,
    preLoanRequest,
  } = props;
  const {
    boot: bootIntercom,
    shutdown: shutdownIntercom,
  } = useIntercom();

  React.useEffect(() => {
    if (preLoanRequest && preLoanRequest.partner === 'Banca.me') {
      bootIntercom();
    } else {
      shutdownIntercom();
    }
  }, [preLoanRequest]);

  const originInstance : Origin = { originLabel: origin };
  const partnerInstance : Partner = { partnerName: preLoanRequest?.partner || 'Banca.Me', partnerProducts: ['consumer, microLoan'], partnerConfiguration: {} };

  return (
    <LeadFormIndex
      partner={partnerInstance}
      error={false}
      loading={!token || !preLoanRequest}
      fullScreen
    >
      {preLoanRequest
        ? (
          <SignatureForm
            origin={originInstance}
            partner={partnerInstance}
          />
        )
        : <div />}
    </LeadFormIndex>
  );
}
