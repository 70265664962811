import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  actionButtons: {
    width: '90%',
    textAlign: 'center',
    marginTop: 15,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  footerInfo: {
    display: 'flex',
    marginTop: 10,
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
      marginBottom: 80,
      marginLeft: 10,
    },
  },
  footerIcon: {
    display: 'flex',
    alignItems: 'center',
    placeContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      placeContent: 'center',
    },
  },
  footerText: {
    textAlign: 'left',
    paddingLeft: 5,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  prepayAvatar: {
    width: '90px',
    [theme.breakpoints.down('md')]: {
      width: '70px',
    },
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '0.5s',
    '&:hover': {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
    marginTop: '10px',
  },

}));

export default useStyles;
