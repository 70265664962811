import React from 'react';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Grid, Typography, useTheme,
} from '@mui/material';
import { prettifyRut } from 'react-rut-formatter';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ViewTitle from '../../../components/ViewTitle';
import useStyles from './styles';
import FinancialIndexes from './FinancialIndexes';
import FinancialScore from './FinancialScore';
import DebtsDetailTable from './DebtsDetailTable';
import RenegotiateDebts from './RegenegotiateDebts';
import BackButton from '../../../components/BackButton';
import type { UserReportHistory } from '../../../types/account';
import bancameLogo from '../../../assets/bancame_logo.svg';
import analytics from '../../../utils/analytics';

type UserReportProps = {
    URHistory: UserReportHistory,
    pdf: boolean,
    componentRef: any,
    reportId: string,
}

export default function UserReport(props:UserReportProps) {
  const {
    URHistory, pdf, componentRef, reportId,
  } = props;
  const {
    QueryDate, score, userData, summary, detail, negotiableDebts,
  } = URHistory;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const backToProducts = () => {
    if (window.location.href.includes('demo')) {
      return history.push('/products');
    }
    return history.push('/account/products');
  };

  const helpUsImprove = () => {
    analytics.track('Click Boton', { text: 'Ayudanos a mejorar', initiator: 'usuario' });
    window.open('https://docs.google.com/forms/d/16nYf4IK6hEga78ax4WSj55cKo6sdn2MeEy74nHH0bzs');
  };

  const downloadPDF = () => {
    analytics.track('Click Boton', { text: 'Descargar PDF', initiator: 'usuario' });
    history.push(`/account/products/user-report/${reportId}/pdf`);
  };

  React.useEffect(() => {
    if (window.location.href.includes('demo')) {
      analytics.page('PRODUCTOS', 'EJEMPLO INFORME DEUDAS');
    } else {
      analytics.page('PRODUCTOS', 'INFORME DEUDAS');
    }
  }, []);

  return (
    <>
      {!pdf && <BackButton onClickAction={backToProducts} />}
      <Grid ref={componentRef} style={{ ...(pdf && { margin: '2em', maxHeight: '100%' }) }}>
        {pdf && <img src={bancameLogo} className={classes.logo} alt="BancameLogo" />}
        <ViewTitle
          title={(
            <>
              Informe de deudas
              {' '}
              {window.location.href.includes('demo') && <b style={{ color: theme.customPalette.tertiary.main }}>-- EJEMPLO --</b>}
            </>
)}
          color="alternativePrimary"
          variant="h6"
          customHeight="auto"
        />
        <Grid
          container
          columnGap={6}
          rowGap={4}
          justifyContent="center"
          style={{
            columnGap: 0,
            rowGap: 0,
          }}
        >

          <Grid
            item
            xs={12}
            lg={7}
          >
            <div>
              <Typography style={{ display: 'flex', alignItems: 'center' }} fontWeight="bold">
                <PersonOutlineIcon fontSize="small" />
                {userData.name}
              </Typography>
              <Typography fontSize={13} style={{ display: 'inline-flex', alignItems: 'center', marginLeft: 3 }}>
                <BadgeIcon style={{ fontSize: 15 }} />
                {prettifyRut(userData.idNumber)}

              </Typography>
              <Typography
                fontSize={13}
                style={{ display: 'inline-flex', alignItems: 'center', marginLeft: 15 }}
              >
                <CalendarTodayIcon style={{ fontSize: 15 }} />
                {new Date(QueryDate).toLocaleDateString('es-CL')}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            justifyItems="right"
            style={{ padding: '10px' }}
            classes={classes.downloadButton}
          >
            {!pdf && !window.location.href.includes('demo') && (
            <div style={{ textAlign: 'right' }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={helpUsImprove}
                style={{ marginRight: '0.5em', marginBottom: '0.5em', width: '16em' }}
              >
                Ayúdanos a mejorar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={downloadPDF}
                style={{ marginBottom: '0.5em', width: '16em' }}
              >
                Descargar PDF
              </Button>
            </div>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          columnGap={6}
          rowGap={4}
          justifyContent="center"
          style={{
            columnGap: 0,
            rowGap: 0,
          }}
        >
          <Grid
            item
            xs={12}
            lg={7}
            style={{ padding: '10px' }}
          >
            <FinancialIndexes
              totalDebt={summary.totalCredit}
              totalDelinquency={summary.totalDebt}
              financialBurden={summary.monthlyDebtToIncome}
              monthlyDebts={summary.monthlyDebts}
              pdf={pdf}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            style={{ margin: '10px' }}
          >
            <FinancialScore score={score.score} pdf={pdf} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={7}
            style={{ padding: '10px' }}
          >
            <DebtsDetailTable
              debtsDetail={detail}
            />
          </Grid>
          {!pdf && (
          <Grid
            item
            xs={12}
            lg={4}
            style={{ margin: '10px' }}
          >
            <RenegotiateDebts
              idNumber={userData.idNumber}
              name={userData.name}
              negotiableDebts={negotiableDebts}
            />
          </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
