import React from 'react';
import { Button, Typography } from '@mui/material';
import { ReactSession } from 'react-client-session';
import { useHistory } from 'react-router-dom';
import ClaveUnicaForm from '../../../components/ClaveUnicaForm';
import { postClaveUnicaHistory, getUserReportsOnce, postCheckIsClaveUnicaUpdated } from '../../../queries/account';
import jwtDecode from '../../../utils/jwtDecode';
import { checkDaysThreshold } from '../../../utils/helpers';
import BancameBackdrop from '../../../components/BancameBackdrop';
import analytics from '../../../utils/analytics';

type BuyProductoDialogProps = {
  token: string,
  productType: 'user-report'|string,
  productId: string,
  amount: number,
  redirectURL: string,
}

export default function BuyProductDialog(props: BuyProductoDialogProps) {
  const {
    token, productType, productId, amount, redirectURL,
  } = props;
  const [givenIdNumber, setGivenIdNumber] = React.useState('');
  const [isClaveUnicaUpdated, setIsClaveUnicaUpdated] = React.useState(false);
  const [isCUChecking, setIsCUChecking] = React.useState(false);
  const [checkError, setCheckError] = React.useState(false);
  const cuButtonRef = React.useRef<HTMLButtonElement>(null);
  const PLRData = localStorage.getItem('PLR');
  const userData = ReactSession.get('User');
  const history = useHistory();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const handleCheckCUupdated = async () => {
    setIsCUChecking(true);
    try {
      await postCheckIsClaveUnicaUpdated(token, true);
      setIsClaveUnicaUpdated(true);
    } catch (e) {
      if (e !== 422) {
        setCheckError(true);
      }
      setIsClaveUnicaUpdated(false);
    }
    setIsCUChecking(false);
  };

  React.useEffect(() => {
    if (!token) {
      if (PLRData) {
        const { idNumber } = JSON.parse(PLRData);
        setGivenIdNumber(idNumber);
      }
      if (userData) {
        const { idNumber } = JSON.parse(userData);
        setGivenIdNumber(idNumber);
      }
    } else {
      const { idNumber } = jwtDecode(token);
      setGivenIdNumber(idNumber);
      handleCheckCUupdated();
    }
  }, [PLRData, userData, token]);
  const setPaymentAmount = async () => {
    const {
      userReportsData,
    } = await getUserReportsOnce(token);
    if (userReportsData.reports.length === 0
      || !checkDaysThreshold(new Date(userReportsData.reports[0].createdAt), 31)) {
      ReactSession.setStoreType('sessionStorage');
      ReactSession.set('Payment', JSON.stringify({
        productType, amount, productId, redirectURL, returnURL: '/products',
      }));
      return history.push('/payment');
    }
    setOpenBackdrop(true);
    analytics.page('PRODUCTOS', 'REDIRECCION INFORME DEUDAS VIGENTE');
    setTimeout(() => history.push('/account/products'), 5000);
    return undefined;
  };
  const openCUDialog = () => {
    if (cuButtonRef.current) {
      return cuButtonRef.current.click();
    }
    return undefined;
  };
  const claveUnicaSuccess = () => {
    setIsClaveUnicaUpdated(true);
    return setPaymentAmount();
  };
  React.useEffect(() => {
    if (token && window.location.hash.includes('handleLogin')) {
      if (isClaveUnicaUpdated) {
        setPaymentAmount();
      } else {
        history.push('/products');
        openCUDialog();
      }
    }
  }, [token, isClaveUnicaUpdated]);
  return (
    !token
      ? (
        <Button
          variant="contained"
          style={{ width: 150 }}
          href="/account/register#UR"
        >
          Comprar Informe
        </Button>
      )
      : (
        <div>
          {isClaveUnicaUpdated
            ? (
              <Button
                onClick={setPaymentAmount}
                variant="contained"
                style={{ width: 150 }}
              >
                Comprar
              </Button>
            )
            : (
              <ClaveUnicaForm
                token={token}
                partner="Banca.me"
                endpoint={(params) => postClaveUnicaHistory(params, true)}
                pageCall={{ category: 'PRODUCTO', name: 'DIALOGO CU INFORME DE DEUDAS' }}
                subtitle="Por favor ingresa tu ClaveÚnica para que podamos verificar tus datos y generar tu informe de deudas."
                idNumber={givenIdNumber}
                origin="web"
                callback={claveUnicaSuccess}
                showDisclaimer={false}
                button={(
                  <Button
                    disabled={isCUChecking || checkError}
                    ref={cuButtonRef}
                    variant="contained"
                    style={{ width: 150 }}
                  >
                    Comprar
                  </Button>
)}
              />
            )}
          <BancameBackdrop
            openBackdrop={openBackdrop}
            loadingText={(
              <>
                <div>¡Ya tienes el producto de este mes!</div>
                <div>Te redirigiremos en breve a Guia.Me</div>
              </>
)}
          />
          {checkError
                && <Typography color="red" variant="caption">Ha ocurrido un error, por favor contáctanos</Typography>}
        </div>
      )
  );
}
