import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fade,
  Hidden,
  Typography,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TransitionProps } from '@mui/material/transitions';
import loanInstallmentsStyles from './styles';
import type { LoanInstallment, LoanInstallmentsProps } from '../../../types/account';
import {
  installmentStateLabels,
  intallmentStateDefinitions,
  installmentStateColor,
  createData,
  installmentStateExplanations,
} from './labels';
import { parseAmountWithCurrency } from '../../../utils/helpers';
import analytics from '../../../utils/analytics';

const Transition = React.forwardRef((
  props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
  ref: React.Ref<unknown>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

export default function LoanInstallments(props: LoanInstallmentsProps) {
  const { loanInstallments } = props;
  const classes = loanInstallmentsStyles();
  // eslint-disable-next-line max-len
  const rows = loanInstallments.map((inst) => createData(inst.period, inst.expirationDate, inst.principal, inst.interest, inst.amount, inst.state, inst.currency));
  const [dialogContent, setDialogContent] = React.useState<LoanInstallment|null>(null);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = (installment: LoanInstallment) => {
    setDialogContent(installment);
    setOpen(true);
  };

  React.useEffect(() => {
    analytics.page('PU - TU CREDITO', 'CUOTAS PRODUCTO');
  }, []);

  return (
    <Fade in {... ({ timeout: 1500 })}>
      <div>
        <Accordion expanded style={{ margin: '1%', borderRadius: '15px', padding: '50px' }}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.heading}
            style={{
              borderRadius: '10px 10px 0px 0px',
              cursor: 'default',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              textAlign: 'left',
            }}
            >
              <Typography component="div">
                Detalle cuotas crédito
                <Typography variant="caption" component="div">
                  Fecha:
                  {' '}
                  {new Date().toLocaleDateString()}
                </Typography>

              </Typography>
              <Typography variant="body2" className={classes.ResponsiveTitle}>Click para ver detalle</Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.AccordionDetails}>
            <TableContainer component={Paper} className={classes.TableContainer}>
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Número de cuota</TableCell>
                    <TableCell className={classes.ReponsiveCell} align="left" style={{ fontWeight: 'bold' }}>Fecha de vencimiento</TableCell>
                    <TableCell className={classes.ReponsiveCell} align="left" style={{ fontWeight: 'bold' }}>
                      <div style={{ display: 'flex' }}>
                        <span>
                          Amortización
                          {(loanInstallments[0].currency.abbreviation === 'CLF' && (' (UF)'))}
                        </span>
                        <Tooltip
                          title="Monto que se destina para pagar la deuda adquirida con Banca.Me"
                          arrow
                          placement="right"
                        >
                          <InfoOutlinedIcon style={{ fontSize: '1rem', height: 13 }} />
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell className={classes.ReponsiveCell} align="left" style={{ fontWeight: 'bold' }}>
                      <div style={{ display: 'flex' }}>
                        <span>
                          Interés
                          {(loanInstallments[0].currency.abbreviation === 'CLF' && (' (UF)'))}
                        </span>
                        {' '}
                        <Tooltip
                          title="Monto que se destina para pagar el costo del préstamo con Banca.Me"
                          arrow
                          placement="right"
                        >
                          <InfoOutlinedIcon style={{ fontSize: '1rem', height: 13 }} />

                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: 'bold' }}>
                      Monto total cuota
                      {(loanInstallments[0].currency.abbreviation === 'CLF' && (' (UF)'))}
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: 'bold' }}>
                      <div style={{ display: 'flex' }}>
                        <span>Estado</span>
                        {' '}
                        <Tooltip
                          title={(
                            <>
                              <Typography variant="caption" gutterBottom style={{ color: 'white' }}>Las cuotas de tu crédito pueden tener seis estados diferentes:</Typography>
                              {installmentStateExplanations.map((ise) => <div key={ise} style={{ margin: '5px auto' }}>{ise}</div>)}
                            </>
                          )}
                          arrow
                          placement="right"
                        >
                          <InfoOutlinedIcon style={{ fontSize: '1rem', height: 13 }} />
                        </Tooltip>
                      </div>

                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow onClick={() => handleOpen(row)} hover key={row.period}>
                      <TableCell>
                        {row.period}
                      </TableCell>
                      <TableCell className={classes.ReponsiveCell} align="left">
                        {new Date(row.expirationDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell className={classes.ReponsiveCell} align="left">
                        {parseAmountWithCurrency(row.currency, row.principal)}
                      </TableCell>
                      <TableCell className={classes.ReponsiveCell} align="left">
                        {parseAmountWithCurrency(row.currency, row.interest)}
                      </TableCell>
                      <TableCell align="left">
                        {parseAmountWithCurrency(row.currency, row.amount)}
                      </TableCell>
                      <TableCell align="left" style={{ color: installmentStateColor(row.state) }}>
                        {installmentStateLabels[row.state]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <Hidden smUp>
          {dialogContent
          && (
            <Dialog
              open={open}
              TransitionComponent={Transition}
              style={{ textAlign: 'center' }}
            >
              <DialogTitle className={classes.DialogTitle}>
                <span style={{ color: 'white' }}>
                  Detalle cuota N°
                  {' '}
                  {dialogContent.period}
                </span>
              </DialogTitle>
              <div style={{ backgroundColor: 'whitesmoke', paddingTop: '5px' }}>
                <Typography variant="body2" align="center">
                  <span style={{ fontWeight: 600 }}>Estado</span>
                  <Tooltip
                    title={intallmentStateDefinitions[dialogContent.state]}
                    arrow
                  >
                    <InfoOutlinedIcon style={{ fontSize: '1rem', height: 13 }} />
                  </Tooltip>

                </Typography>
                <Typography variant="h5" align="center" style={{ color: installmentStateColor(dialogContent.state), padding: '6px 8px' }}>
                  {installmentStateLabels[dialogContent.state]}

                </Typography>

              </div>
              <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <div>
                    <Typography variant="body2" align="center">
                      <>
                        Cuota
                        {' '}
                        <span style={{ fontWeight: 600 }}>número</span>
                      </>
                    </Typography>
                    <Typography variant="h5" align="center" style={{ padding: '6px 8px' }} color="secondary">
                      {dialogContent.period}
                    </Typography>

                  </div>
                  <div>
                    <Typography variant="body2" align="center">
                      <span style={{ fontWeight: 600 }}>Vencimiento</span>

                    </Typography>
                    <Typography variant="h5" align="center" style={{ padding: '6px 8px' }} color="secondary">
                      {new Date(dialogContent.expirationDate).toLocaleDateString()}
                    </Typography>

                  </div>
                  <div>
                    <Typography variant="body2" align="center">
                      <span style={{ fontWeight: 600 }}>Amortización</span>
                      <Tooltip
                        title="Monto que se destina para pagar la deuda adquirida con Banca.Me"
                        arrow
                      >
                        <InfoOutlinedIcon style={{ fontSize: '1rem', height: 13 }} />
                      </Tooltip>

                    </Typography>
                    <Typography variant="h5" align="center" style={{ padding: '6px 8px' }} color="secondary">
                      $
                      {dialogContent.principal.toLocaleString()}
                    </Typography>

                  </div>
                  <div>
                    <Typography variant="body2" align="center">

                      <span style={{ fontWeight: 600 }}>Interés</span>
                      <Tooltip
                        title="Monto que se destina para pagar el costo del préstamo con Banca.Me"
                        arrow
                      >
                        <InfoOutlinedIcon style={{ fontSize: '1rem', height: 13 }} />

                      </Tooltip>
                    </Typography>
                    <Typography variant="h5" align="center" style={{ padding: '6px 8px' }} color="secondary">
                      $
                      {dialogContent.interest.toLocaleString()}
                    </Typography>

                  </div>
                </div>
                <hr />
                <div>
                  <Typography variant="body1" align="center">
                    <>
                      Monto
                      {' '}
                      <span style={{ fontWeight: 600 }}>Cuota</span>
                    </>
                  </Typography>
                  <Typography variant="h1" align="center" style={{ padding: '6px 8px' }} color="primary">
                    $
                    {dialogContent.amount.toLocaleString()}
                  </Typography>

                </div>
                <div />
              </DialogContent>
              <DialogActions style={{ backgroundColor: 'whitesmoke' }}>
                <Button onClick={handleClose}>Cerrar</Button>
              </DialogActions>
            </Dialog>
          )}
        </Hidden>
      </div>
    </Fade>
  );
}
