import React, { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import type { UserAccountComponent } from '../../../../types/account';
import { getUserReport } from '../../../../queries/account';
import UserReport from '../../../../feature/account/UserReport';

type UserReportParams = {
    reportId: string;
  };

export default function UserReportPdfView(props:UserAccountComponent) {
  const { token } = props;
  const { reportId } = useParams<UserReportParams>();
  const history = useHistory();
  const componentRef = useRef(null);
  const handdlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Informe Deudas Banca.Me',
    onAfterPrint: () => {
      history.push(`/account/products/user-report/${reportId}`);
    },
  });
  const {
    userReport,
  } = getUserReport(token, window.location.href.includes('demo') ? 'demo' : reportId);

  React.useEffect(() => {
    handdlePrint();
  }, [userReport]);
  return userReport
  && (
  <UserReport
    URHistory={userReport.history}
    pdf
    componentRef={componentRef}
    reportId={reportId}
  />
  );
}
