import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackgroundPic from '../../assets/fondoweb_bancamines.webp';

const useStyles = makeStyles((theme: Theme) => ({
  Dialog: {
    '& .MuiDialog-container .MuiDialog-paper': {
      borderRadius: 20,
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-container .MuiDialog-paper': {
        margin: 0,
      },
    },
  },
  DialogContentAlt: {
    height: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${BackgroundPic})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

export default useStyles;
