import { Button } from '@mui/material';
import React from 'react';
import bancameLogo from '../../assets/bancame_logo_sans.svg';

type BancameButtonProps = {
    onClick?: () => void,
    label: string,
    displayFlex?: boolean
}

export default function BancameButton(props:BancameButtonProps) {
  const { onClick, label, displayFlex } = props;
  return (
    <Button
      variant="outlined"
      style={displayFlex ? { display: 'flex', justifyContent: 'space-between' } : {}}
      onClick={onClick}
    >
      <object
        data={bancameLogo}
        aria-labelledby="Banca.Me logo"
        height="30px"
        style={{ marginRight: 10, marginBottom: '-5px' }}
      />
      <div>
        {label}
      </div>
      <div />
    </Button>
  );
}

BancameButton.defaultProps = {
  onClick: () => {},
  displayFlex: true,
};
