/* eslint-disable camelcase */
import { LoanParams } from '../components/Simulator/types';
import { Currency } from '../types/account';

export const roundAccurately = (number:number, decimalPlaces:number) => (
  parseFloat(`${Math.round(parseFloat(`${number}e${decimalPlaces}`))}e-${decimalPlaces}`)
);

export const CAECalulator = (monthlyCAE:number) => roundAccurately(monthlyCAE * 12, 8);

export const interesRateCalculator = (
  payProb: number,
  alpha: number,
  beta: number,
) => roundAccurately(
  (payProb * alpha) + beta,
  8,
);

export const getOriginCost = (
  loanAmount: number,
  productType: 'consumer'|'microLoan'|'mortgage'|'downPayment',
  currency: Currency,
) => {
  if (productType === 'downPayment') {
    return currency.abbreviation === 'CLF' ? 1 : 0;
  }
  if (loanAmount >= +(process.env.REACT_APP_MIN_ORIGIN_COST as string) * 100) {
    return (loanAmount * 0.01);
  }
  return +(process.env.REACT_APP_MIN_ORIGIN_COST as string);
};

function iterateTaxCalculation(bruteAmountWithoutTax: number, taxRate: number) {
  let newBruteAmount = bruteAmountWithoutTax;
  let tax = bruteAmountWithoutTax * taxRate;
  let difference = Infinity;
  let iterations = 0;
  const maxIterations = 100;
  while (difference > 0.0001 && iterations < maxIterations) {
    iterations += 1;
    const previousTax = tax;
    tax = (newBruteAmount * taxRate);
    newBruteAmount = bruteAmountWithoutTax + tax;
    tax = (newBruteAmount * taxRate);
    difference = Math.abs(previousTax - tax);
  }

  return tax;
}

export const taxesCalculator = (params:{
  loanAmount: number,
  originCost: number,
  periods: number,
  productType: 'consumer'|'microLoan'|'mortgage'|'downPayment',
  interestRate: number,
  insuranceCost: number,
  currency: Currency
}) => {
  const {
    loanAmount, originCost, periods, insuranceCost, currency,
  } = params;

  const tax0006 = iterateTaxCalculation(loanAmount + originCost + insuranceCost, 0.00066 * periods);
  const tax008 = iterateTaxCalculation(loanAmount
    + originCost
    + insuranceCost, 0.008);
  const calculatedTaxes = Math.min(tax0006, tax008);
  return Math.ceil(calculatedTaxes * 10 ** currency.precision) / 10 ** currency.precision;
};
const createTransferDate = (today = new Date()) => {
  const holidays = [
    '2024/01/01',
    '2024/03/29',
    '2024/03/30',
    '2024/05/01',
    '2024/05/21',
    '2024/06/20',
    '2024/06/16',
    '2024/08/15',
    '2024/09/18',
    '2024/09/19',
    '2024/09/20',
    '2024/10/31',
    '2024/11/01',
    '2024/12/25',
  ].map((date) => new Date(date));
  const nextFiveWeekDays = [1, 2, 3, 4, 5, 6]
    .map((n) => new Date(new Date().setDate(today.getDate() + n)))
    .filter((date) => [1, 2, 3, 4, 5].includes(date.getDay()))
    .filter(
      (date) => !holidays.map((h) => h.toDateString()).includes(date.toDateString()),
    );
  return nextFiveWeekDays[0];
};

function getDateForPaymentDay(params: {
  transferDate: Date;
  paymentDay: number;
  checkDate: number;
}) {
  const { transferDate, paymentDay, checkDate } = params;
  const expirationDate = new Date(transferDate);
  expirationDate.setDate(paymentDay);
  if (transferDate.getDate() < checkDate + 1) {
    expirationDate.setMonth(expirationDate.getMonth() + 1);
  } else {
    expirationDate.setMonth(expirationDate.getMonth() + 2);
  }
  expirationDate.setHours(0, 0, 0, 0);
  return expirationDate;
}

function createExpirationDate(params: {
  transferDate: Date;
  paymentMethod: 'dpp' | 'pac' | 'pat';
  companyAffiliated?: boolean;
  companyPaymentDay?: number;
}): Date {
  const {
    transferDate, paymentMethod, companyAffiliated, companyPaymentDay,
  } = params;
  const defaultExpirationDay = 1;
  const paymentDay = companyAffiliated && paymentMethod === 'dpp'
    ? companyPaymentDay || 10
    : defaultExpirationDay;

  return getDateForPaymentDay({
    transferDate,
    paymentDay,
    checkDate:
      paymentMethod === 'dpp' && companyPaymentDay ? companyPaymentDay : 15,
  });
}

function findPayment(
  amount: number,
  monthly_rate: number,
  nper_months: number,
  payment_limit: number,
  payment: number,
  paymentMethod: 'dpp'|'pac'|'pat',
) {
  let A_n_1 = amount;
  let A_n = A_n_1;
  const transferDate = createTransferDate();
  transferDate.setHours(0, 0, 0, 0);
  const expirationDate = createExpirationDate({
    transferDate,
    paymentMethod,
    companyPaymentDay: payment_limit,
    companyAffiliated: paymentMethod === 'dpp',
  });

  const timeDiff = expirationDate.getTime() - transferDate.getTime();
  const daysDiff = Math.round(timeDiff / (1000 * 3600 * 24));

  const due_dates = [expirationDate];
  const days_between_due_dates = [daysDiff];
  for (let month = 0; month < nper_months; month += 1) {
    const newDate = new Date(due_dates[month].getTime());
    newDate.setMonth(due_dates[month].getMonth() + 1);
    due_dates.push(newDate);
    if (month > 0) {
      const timeDiffe = due_dates[month].getTime() - due_dates[month - 1].getTime();
      const daysDiffe = Math.round(timeDiffe / (1000 * 3600 * 24));
      days_between_due_dates.push(daysDiffe);
    }
    const Amort = Math.ceil(payment - (A_n_1 * monthly_rate
      * ((days_between_due_dates[month]) / 30)));
    A_n -= Math.min(A_n_1, Amort);
    A_n_1 = A_n;
  }
  return A_n;
}

export function installmentEstimation(
  totalCost: number,
  loanAmount: number,
  interestRate: number,
  periods: number,
  paymentDate: number,
  paymentMethod: 'dpp'|'pat'|'pac',
  currency: Currency,
) {
  if (interestRate === 0) {
    return totalCost / periods;
  }
  const monthlyRate = interestRate / 12;

  const q = ((1 + monthlyRate) ** periods);

  const guess = Math.ceil(
    ((loanAmount * monthlyRate * q) / (q - 1) + Number.EPSILON)
        * 10 ** currency.precision,
  )
    / 10 ** currency.precision;
  const maxGuess = guess * 2;

  const installments = [];
  const foFx = (
    payment: number,
  ) => findPayment(totalCost, monthlyRate, periods, paymentDate, payment, paymentMethod);
  let p = guess;
  const CLPIteration = paymentMethod === 'dpp' ? 10 : 5;
  const pIteration = currency.abbreviation === 'CLF' ? 10 ** -currency.precision : CLPIteration;
  for (p; p < maxGuess; p += pIteration) {
    const inst = foFx(p);
    installments.push([inst, p]);
    if (inst === 0) {
      return p;
    }
  }
  const tupleWithMinFirstValue = installments
    .reduce((previous, current) => (Math.abs(current[0])
      < Math.abs(previous[0]) ? current : previous), [Infinity]);
  return tupleWithMinFirstValue[1] as number;
}

export function optimizeCAE(
  totalCost: number,
  periods: number,
  installmentAmount: number,
  currency: Currency,
) {
  const installmentsList = [...Array(periods).fill(installmentAmount)];
  function equation(x: number): number {
    const leftSide = totalCost / (1 + x) ** 1;
    const rightSide = installmentsList
      .reduce((sum, installment, i) => sum + (installment / (1 + x) ** (i + 2)), 0);
    return leftSide - rightSide;
  }
  const foFx = (
    monthly_CAE: number,
  ) => equation(monthly_CAE);
  const cae = [];
  let p = currency.abbreviation === 'CLF' ? 0.0001 : 0.01;
  for (p; p < 0.1; p += 0.00001) {
    const ca = foFx(p);
    cae.push([ca, p]);
    if (ca === 0) {
      return p;
    }
  }
  const tupleWithMinFirstValue = cae
    .reduce((previous, current) => (Math.abs(current[0])
      < Math.abs(previous[0]) ? current : previous), [Infinity]);
  return tupleWithMinFirstValue[1] as number;
}

export const insuranceCalculator = (params: {amount: number,
  periods: number,
  insuranceRate: number,
  precision: number,
  type?: string
}) => {
  const {
    amount, periods, insuranceRate, type, precision,
  } = params;
  if (insuranceRate === undefined) {
    return 0;
  }
  if (type && ['death', 'unemployment', 'incapacity'].includes(type)) {
    return (
      Math.round(
        (insuranceRate * periods * amount + Number.EPSILON)
          * 10 ** precision,
      )
      / 10 ** precision
    );
  }
  return (
    Math.round(
      (insuranceRate * amount + Number.EPSILON)
          * 10 ** precision,
    )
      / 10 ** precision
  );
};

export const compoundInsuranceRates = (insuranceRates: {[key:number]: number}, periods:number) => {
  if (insuranceRates[periods]) {
    return insuranceRates[periods] + insuranceRates[0] * periods;
  }
  return insuranceRates[0] * periods;
};

export const getInstallmentMarks = (params: {
  amount: number,
  loanParams: LoanParams,
  productType: 'consumer'|'microLoan'|'mortgage'|'downPayment',
  insuranceRates: {[key:number]: number},
  paymentDate: number,
  paymentMethod: 'dpp' |'pac'|'pat',
  currency: Currency}) => {
  const {
    amount, loanParams, productType, insuranceRates, paymentDate, paymentMethod, currency,
  } = params;
  const originCost = getOriginCost(amount, productType, currency);
  const bruteAmount = (periods: number, interestRate: number): number => amount
  + originCost
  + insuranceCalculator({
    amount,
    periods,
    insuranceRate: compoundInsuranceRates(insuranceRates, periods),
    precision: currency.precision,
  })
  + taxesCalculator({
    loanAmount: amount,
    originCost,
    periods,
    productType,
    interestRate,
    insuranceCost: insuranceCalculator({
      amount,
      periods,
      insuranceRate: compoundInsuranceRates(insuranceRates, periods),
      precision: currency.precision,
    }),
    currency: loanParams.currency,
  });
  const marksArray = Object.keys(loanParams.payProb).map((markPeriod) => {
    const interestRate = loanParams.payProb[+markPeriod as number] ? interesRateCalculator(
      loanParams.payProb[+markPeriod as number],
      loanParams.alpha,
      loanParams.beta,
    ) : 0;

    const totalCost = bruteAmount(+markPeriod as number, interestRate);
    const installment = installmentEstimation(
      +totalCost,
      amount,
      interestRate,
      +markPeriod as number,
      paymentDate,
      paymentMethod,
      currency,
    );
    const monthlyCAE = optimizeCAE(amount, +markPeriod, installment, currency);
    return ({
      installment,
      value: +markPeriod,
      monthlyCAE,
      interestRate,
    });
  });
  const marks = marksArray.filter((mark) => (
    mark.installment <= loanParams.maxInstallmentAmount
  ) && mark.value <= loanParams.riskEngineMaxPeriod);
  return marks;
};

export function getCombinationsFromArray(valuesArray: string[]) {
  const combi = [];
  let temp = [];
  const slent = 2 ** valuesArray.length;

  for (let i = 0; i < slent; i += 1) {
    temp = [];
    for (let j = 0; j < valuesArray.length; j += 1) {
      if ((i & 2 ** j)) { // eslint-disable-line no-bitwise
        temp.push(valuesArray[j]);
      }
    }
    if (temp.length > 0) {
      combi.push(temp);
    }
  }

  combi.sort((a, b) => b.length - a.length);
  return combi;
}
