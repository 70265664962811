import React from 'react';
import {
  Route, Switch, Redirect,
} from 'react-router-dom';
import type { Location } from 'history';
import Verify from '../../pages/verify';
import { useQuery } from '../../utils/helpers';

export default function VerifyRoutes() {
  const query = useQuery();
  const token = query.get('token');
  const checkQueryToken = (queryToken: string|null, location: Location<unknown>) => {
    if (queryToken) {
      const veriyComponent = () => <Verify queryToken={queryToken} />;
      return (
        <Switch location={location}>
          <Route exact path="/verify" component={veriyComponent} />
        </Switch>
      );
    }
    return (
      <Redirect
        to={{
          pathname: '/preloan',
        }}
      />
    );
  };
  return (
    <Route
      render={
        ({ location }) => (checkQueryToken(token, location))
      }
    />
  );
}
