const bancoBice: {[key:string]: {label:string, value:string}} = {
  name: { label: 'Nombre', value: 'Bancame Trust Spa' },
  rut: { label: 'Rut', value: '77.673.115-3' },
  account_type: { label: 'Tipo de cuenta', value: 'Cuenta Corriente' },
  account_number: { label: 'N° de cuenta', value: '01-38366-3' },
  bank: { label: 'Banco', value: 'Banco BICE' },
  email: { label: 'E-Mail', value: 'info@banca.me' },
};

export default bancoBice;
