import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { IconButton } from '@mui/material';
import WhatsappOutlinedIcon from '@mui/icons-material/WhatsappOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import analytics from '../../utils/analytics';

type ShareButtonsProps = {
    shareText: string,
    url: string,
    whatsapp?: boolean,
    facebook?:boolean,
    twitter?:boolean,
    email?:boolean,
    telegram?:boolean,
    linkedin?:boolean
}

export default function ShareButtons(props:ShareButtonsProps) {
  const {
    shareText,
    url,
    whatsapp,
    facebook,
    twitter,
    email,
    telegram,
    linkedin,
  } = props;

  const handleClick = (rrss:string) => {
    analytics.track('Click Boton', { text: `Compartir mediante ${rrss}`, initiator: 'usuario' });
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {whatsapp
      && (
      <WhatsappShareButton
        title={shareText}
        url={url}
        onClick={() => handleClick('Whatsapp')}
      >
        <IconButton style={{ padding: '5px 10px' }}>
          <WhatsappOutlinedIcon
            fontSize="large"
            style={{ color: 'green' }}
          />
        </IconButton>
      </WhatsappShareButton>
      )}
      {facebook
      && (
      <FacebookShareButton title={shareText} url={url} onClick={() => handleClick('Facebook')}>
        <IconButton style={{ padding: '5px 10px' }}>
          <FacebookIcon
            fontSize="large"
            style={{ color: '#3b5998' }}
          />
        </IconButton>
      </FacebookShareButton>
      )}
      {twitter
      && (
      <TwitterShareButton title={shareText} url={url} onClick={() => handleClick('X')}>
        <IconButton style={{ padding: '5px 10px' }}>
          <TwitterIcon
            fontSize="large"
            style={{ color: '#00acee' }}
          />
        </IconButton>
      </TwitterShareButton>
      )}
      {email
      && (
      <EmailShareButton title={shareText} url={url} onClick={() => handleClick('Email')}>
        <IconButton style={{ padding: '5px 10px' }}>
          <MailOutlineIcon
            fontSize="large"
          />
        </IconButton>
      </EmailShareButton>
      )}
      {telegram
      && (
      <TelegramShareButton title={shareText} url={url} onClick={() => handleClick('Telegram')}>
        <IconButton style={{ padding: '5px 10px' }}>
          <TelegramIcon
            fontSize="large"
            style={{ color: '#0088cc' }}
          />
        </IconButton>
      </TelegramShareButton>
      )}
      {linkedin
      && (
      <LinkedinShareButton title={shareText} url={url} onClick={() => handleClick('Linkedin')}>
        <IconButton style={{ padding: '5px 10px' }}>
          <LinkedInIcon
            fontSize="large"
            style={{ color: '#0088cc' }}
          />
        </IconButton>
      </LinkedinShareButton>
      )}
    </div>
  );
}
ShareButtons.defaultProps = {
  whatsapp: true,
  facebook: true,
  twitter: true,
  email: true,
  telegram: true,
  linkedin: true,
};
