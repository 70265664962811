import React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import { prettifyRut } from 'react-rut-formatter';
import { TransitionProps } from '@mui/material/transitions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import claveUnicaStyles from './styles';
import AlertDialog from '../AlertDialog';
import LoadingAvatar from '../LoadingAvatar';
import analytics from '../../utils/analytics';

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

interface ClaveUnicaDialogInterface {
  rut: string,
  action: (password: string) => Promise<void>,
  maximumTries: boolean,
  triggerSubmit: boolean,
  button: React.ReactElement|null,
  subtitle: string,
  origin: string,
  pageCall?: {category: string, name: string},
}
export default function ClaveUnicaDialog(props: ClaveUnicaDialogInterface) {
  const {
    rut, action, maximumTries, triggerSubmit, button, subtitle, origin, pageCall,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((o) => !o);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const classes = claveUnicaStyles();

  const handleClickOpen = () => {
    if (pageCall) {
      analytics.page(pageCall.category, pageCall.name);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const goToFogotPassword = () => {
    analytics.track('Click Link', {
      text: '¿Olvidaste tu ClaveÚnica?',
      initiator: 'usuario',
    });
    window.open('https://claveunica.gob.cl/recuperar');
  };
  const onSubmit = async () => {
    setLoading(true);
    setError(false);
    if (!checked || password === '') {
      setLoading(false);
      return setError(true);
    }
    try {
      await action(password);
      handleClose();
    } catch (e) {
      setError(true);
    }
    return setLoading(false);
  };
  React.useEffect(() => {
    if (triggerSubmit) {
      setOpen(true);
      setTimeout(() => onSubmit(), 1000);
    }
  }, [triggerSubmit]);
  const handleCheckBox = () => {
    analytics.track('Click Checkbox', {
      text: 'Acepto los TyC y política privacidad',
      initiator: 'usuario',
    });
    setChecked((o) => !o);
  };
  const handleClickTermsAndConditions = () => {
    analytics.track('Click Link', {
      text: 'términos y condiciones',
      initiator: 'usuario',
    });
  };
  const handleClickPrivacyPolicy = () => {
    analytics.track('Click Link', {
      text: 'política de privacidad',
      initiator: 'usuario',
    });
  };

  return (
    <>
      {button ? (
        React.cloneElement(button, {
          onClick: (e: React.SyntheticEvent) => { e.preventDefault(); handleClickOpen(); },
        })
      )
        : (
          <Button
            onClick={handleClickOpen}
            variant="contained"
          >
            Ingresar ClaveÚnica
          </Button>
        )}
      <Dialog
        className={classes.dialog}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="clave-unica-dialog"
        maxWidth="sm"
      >
        <DialogContent>
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            fontWeight="bold"
            marginTop={10}
          >
            Ingreso Clave Única
          </Typography>
          <Typography
            align="center"
            gutterBottom
            fontStyle="italic"
            color="text.secondary"
          >
            {subtitle}
          </Typography>
          <Typography
            color="textSecondary"
            gutterBottom
          >
            RUT
          </Typography>
          <TextField
            style={{ marginBottom: 10 }}
            value={prettifyRut(rut)}
            disabled
            variant="outlined"
          />
          <div style={{ display: 'flex', placeContent: 'space-between' }}>
            <Typography
              color="secondary"
              gutterBottom
            >
              Contraseña
            </Typography>
            <div
              onClick={goToFogotPassword}
              className={classes.passwordForgotten}
              aria-hidden
            >
              ¿Olvidaste tu ClaveÚnica?
            </div>
          </div>
          <TextField
            data-pw="cu-input"
            style={{ marginBottom: 20 }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            placeholder="Clave Única"
            variant="outlined"
            error={error && password === ''}
            helperText={(error && password === '') ? 'Por favor ingrese su contraseña' : ''}
            disabled={loading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>),
            }}
          />
          <FormControlLabel
            control={(
              <Checkbox
                data-pw="cu-optin"
                size="small"
                checked={checked}
                onClick={handleCheckBox}
                disabled={loading}
              />
            )}
            label={(
              <Typography variant="body2">
                Acepto
                {' '}
                <Link
                  onClick={handleClickTermsAndConditions}
                  style={{ textDecoration: 'underline' }}
                  href={`/terms-and-conditions?origin=${origin}&referrer=plr`}
                  target="_blank"
                  rel="noopener"
                >
                  términos y condiciones
                </Link>
                {' '}
                y
                {' '}
                <Link onClick={handleClickPrivacyPolicy} style={{ textDecoration: 'underline' }} href="https://www.banca.me/politicas-de-privacidad" target="_blank" rel="noopener">
                  política de privacidad
                </Link>
              </Typography>
            )}
          />
          <div style={{ textAlign: 'center', marginBottom: 5 }}>
            <Button
              data-pw="cu-submit-button"
              onClick={onSubmit}
              variant="contained"
              color="primary"
              disabled={loading || maximumTries}
            >
              {maximumTries ? 'Máximo de intentos permitidos' : 'Continuar'}
            </Button>
          </div>
          <Typography
            align="center"
            gutterBottom
            fontStyle="italic"
            color="text.secondary"
            style={{ paddingTop: '0.5em' }}
          >
            Utilizamos tecnología de encriptación bancaria AES 256 para la comunicación de datos.
            No se guardarán tus credenciales.
          </Typography>
          {(error && !checked) && (
            <Typography
              variant="caption"
              color="error"
              component="div"
              align="center"
              style={{ marginTop: 5 }}
            >
              Por favor acepte los términos y condiciones
            </Typography>
          )}
          <AlertDialog
            triggerClose={!loading}
            isTriggered={loading}
            button={<div />}
            hideCloseButton
            hideSubmitButton
            dialogContent={<LoadingAvatar loading={loading} />}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
            disabled={loading}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ClaveUnicaDialog.defaultProps = {
  pageCall: undefined,
};
