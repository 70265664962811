import React from 'react';
import {
  TextField,
  Grid,
  Typography,
  Paper,
  IconButton,
  InputAdornment,
  Button,
  Alert,
  Snackbar,
  Fade,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { checkPassword } from '../../../utils/helpers';
import jwtDecode from '../../../utils/jwtDecode';
import useStyles from './styles';
import { postResetPassword, postSignUpSetPassword, postClientVerify } from '../../../queries/account';
import analytics from '../../../utils/analytics';

type SetPasswordFormProps = {
    clientToken: string,
    idNumber: string,
    email: string
}

export default function SetPasswordForm(props: SetPasswordFormProps) {
  const classes = useStyles();
  const history = useHistory();
  const { clientToken, idNumber, email } = props;
  const { tokenIntention } = jwtDecode(clientToken);
  const [password, setPassword] = React.useState('');
  const [passwordTwo, setPasswordTwo] = React.useState('');
  const [warning, setWarning] = React.useState('');
  const [error, setError] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((o) => !o);
  const handleClickShowPasswordTwo = () => setShowPasswordTwo((o) => !o);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const validatePassword = (pswrd:string) => {
    setPasswordError(checkPassword(pswrd));
  };
  const submitPassword = async () => {
    setLoading(true);
    if (passwordError) {
      setError(true);
      setOpen(true);
      setLoading(false);
      return setWarning('La contraseña no cumple los requerimientos');
    }
    if (password !== passwordTwo) {
      setError(true);
      setOpen(true);
      setLoading(false);
      return setWarning('Las contraseñas deben coincidir');
    }
    if (tokenIntention === 'resetPassword') {
      try {
        await postResetPassword(password, clientToken);
        setTimeout(() => {
          history.push('/account');
        }, 1000);
      } catch (e) {
        if (e === 401) {
          setWarning('Probablemente ya utlizaste el link enviado a tu e-mail, por favor solicita cambiar tu clave nuevamente');
          setOpen(true);
        } if (e === 403) {
          setError(true);
          setOpen(true);
          setWarning('Token inválido');
        }
        return setLoading(false);
      }
    } if (tokenIntention === 'createAccount') {
      try {
        await postSignUpSetPassword(password, clientToken);

        try {
          await postClientVerify(idNumber, email, clientToken, window.location.hash.includes('UR') ? 'userReport' : 'verifyAccount');
          history.push('/account/verify-email');
        } catch (e) {
          return setError(true);
        }
      } catch (e) {
        setOpen(true);
        setLoading(false);
        if (e === 401) {
          setWarning('El cliente ya tiene usuario y contraseña.');
          setTimeout(() => {
            history.push('/account/login');
          }, 1500);
        } if (e === 403) {
          setError(true);
          return setWarning('Token inválido');
        }
        return setWarning('Ha ocurrido un error');
      }
    }
    return undefined;
  };

  React.useEffect(() => {
    analytics.page('PU - LOGIN', 'CREAR CONTRASEÑA');
  }, []);

  return (
    <Grid
      item
      xs={12}
      lg={5}
      md={6}
      sm={10}
    >
      <Fade
        in
        {...({ timeout: 1000 })}
      >
        <Paper className={classes.paper} elevation={1} style={{ borderRadius: '10px' }}>
          <div>
            <Typography variant="h6" align="center" style={{ marginBottom: 10, fontWeight: 'bold' }}>
              Ingresa tu nueva contraseña
            </Typography>
            <Typography variant="body2" align="center" style={{ marginBottom: 10 }}>
              Debe ser de 8 a 15 caracteres con al menos un número
              y símbolo dentro de ella.
            </Typography>
            <TextField
              onBlur={(e) => validatePassword(e.target.value)}
              style={{ marginBottom: 20 }}
              value={password}
              placeholder="Nueva contraseña"
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              disabled={loading}
              variant="outlined"
              error={error}
              helperText="Puede ser algún símbolo tal como !@#$%^&?|"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>),
              }}
            />
            <TextField
              style={{ marginBottom: 20 }}
              value={passwordTwo}
              placeholder="Confirme contraseña"
              onChange={(e) => setPasswordTwo(e.target.value)}
              type={showPasswordTwo ? 'text' : 'password'}
              variant="outlined"
              error={error}
              helperText={(error) ? warning : ''}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordTwo}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordTwo ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>),
              }}
            />
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              <Alert severity="warning">{warning}</Alert>
            </Snackbar>
            <Button
              variant="contained"
              color="primary"
              onClick={() => submitPassword()}
              disabled={loading || password.length < 8}
            >
              Crear contraseña
            </Button>
          </div>
        </Paper>
      </Fade>
    </Grid>
  );
}
