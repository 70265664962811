/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormikProps } from 'formik';
import DataFetching from './step/00_dataFetching.tsx';

export const loanRequetStep = {
  LOAN_SIMULATION: 1,
  SIGNATURE: 2,
  PAYMENT_SUBSCRIPTION: 3,
};

export function getStepContent(
  activeStep: number,
  currentData: any,
  refsObject: {LoadingFormRef: React.MutableRefObject<FormikProps<any>|null>},
  handleCurrentData: (data: any) => void,
  handleNext: () => void,
  handleStep: (step: number) => void,
  handleEnableLoading: (state: boolean) => void,
) {
  const componentProps = {
    formSchema: currentData,
    handleNext,
    handleStep,
    handleEnableLoading,
    handleCurrentData,
  };
  switch (activeStep) {
    case 0:
      return (
        <DataFetching
          formRef={refsObject.LoadingFormRef}
          {...componentProps}
        />
      );
    case loanRequetStep.LOAN_SIMULATION:
      return <p>Simulación de credito</p>;
    case loanRequetStep.SIGNATURE:
      return (
        <p>Signature</p>
      );
    case loanRequetStep.PAYMENT_SUBSCRIPTION:
      return (
        <p>Payment Subscription</p>
      );
    default:
      return <p>Last step</p>;
  }
}
