/* eslint-disable camelcase */
import fetcher from '../../../utils/fetcher';
import {
  FormSchemaV2,
} from '../../../types/preloan/v2';
import type { PLRDocument } from '../../../components/UploadDocuments';
import type { PostClaveUnicaApproveResponse } from '../../../types/preloan/v2';
import type { ClaveUnicaEndpointProps } from '../../../components/ClaveUnicaForm';

export async function postDeniedClaveUnica(token: string) {
  const res = await fetcher(`${process.env.REACT_APP_API_URL}/pre-loan-request/denied-clave-unica`, 'POST', null, token);
  return res;
}
export async function postClaveUnicaApprove(params: ClaveUnicaEndpointProps):
Promise<PostClaveUnicaApproveResponse> {
  const { token, password } = params;
  const response = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/clave-unica-approve/`,
    'POST',
    { password },
    token,
  );
  return response;
}

export async function patchPLRStep(
  newStep: 'ARCHIVED_FAIL'|'WAITING_COMPANY_AFFILIATION',
  rejectReasons:string[],
  token: string,
) {
  await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/step`,
    'PATCH',
    {
      newStep,
      rejectReasons: JSON.stringify(rejectReasons),
    },
    token,
  );
}

export async function postGenerateDocumentsURL(token: string): Promise<{data:{
  cotizacionUrl: PLRDocument | undefined,
  liquidacionUrl: PLRDocument | undefined
}
}> {
  const res = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/v2/upload-documents`,
    'POST',
    null,
    token,
  );
  return res.data;
}

export async function postPLRHubspotForm(companyInfo:{
  companyName: string,
  companyContactEmail: string,
  companyContactPhone:string,
  companyContactFirstName: string,
  companyContactLastName: string,
  applicantFirstName: string,
  applicantLastName: string,
  origin: string,
  applicantRut: string
}, requestLoanData: FormSchemaV2['requestLoanData']) {
  const data = {
    fields: [
      {
        name: 'company',
        value: companyInfo.companyName,
      },
      {
        name: 'email',
        value: companyInfo.companyContactEmail,
      },
      {
        name: 'firstname',
        value: companyInfo.companyContactFirstName,
      },
      {
        name: 'lastname',
        value: companyInfo.companyContactLastName,
      },
      {
        name: 'phone',
        value: companyInfo.companyContactPhone,
      },
      {
        name: 'plr_origin',
        value: companyInfo.origin,
      },
      {
        name: 'applicantfullname',
        value: `${companyInfo.applicantFirstName} ${companyInfo.applicantLastName}`,
      },
      {
        name: 'preapprovedloanamount',
        value: requestLoanData.amount,
      },
      {
        name: 'preapprovedloaninstallment',
        value: requestLoanData.installment,
      },
      {
        name: 'applicantrut',
        value: companyInfo.applicantRut,
      },
    ],
  };
  await fetch(
    `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/${process.env.REACT_APP_HUBSPOT_COMPANY_LEAD_FORM_ID}`,
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    },
  );
}
