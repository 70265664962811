import React from 'react';
import {
  Button,
  Typography,
} from '@mui/material';

type RefinanceBaseViewProps ={
    callback: () => void,
    firstMessage: string,
    secondMessage: string,
    buttonText: string,
}

export default function RefinanceBaseView(props: RefinanceBaseViewProps) {
  const {
    callback, firstMessage, secondMessage, buttonText,
  } = props;

  return (
    <>
      <Typography
        variant="h5"
        fontWeight="bold"
        gutterBottom
        style={{ margin: '10px' }}
      >
        {firstMessage}
      </Typography>

      <Typography
        variant="body1"
        gutterBottom
      >
        {secondMessage}
      </Typography>
      <Button
        style={{ margin: 5 }}
        variant="contained"
        onClick={callback}
      >
        {buttonText}
      </Button>
    </>
  );
}
