import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = '#00';
  return {
    backgroundColor,
    height: theme.spacing(5),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    borderRadius: '10px',
    transition: '0.5s',
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: emphasize(theme.palette.primary.main, 0.06),
      color: 'white',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.primary.main, 0.12),
      color: 'white',
    },
  };
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

interface CustomizedBreadcrumbsProps{
  elements: { label: string, hash: string, icon: any }[],
  separator: string
}

export default function CustomizedBreadcrumbs(props: CustomizedBreadcrumbsProps) {
  const { elements, separator } = props;
  const theme = useTheme();
  const history = useHistory();
  React.useEffect(() => {
    const hashes = elements.map((e) => `#${e.hash}`);
    if (!hashes.includes(window.location.hash)) {
      history.push(`#${elements[0].hash}`);
    }
  }, []);
  return (
    <div style={{ marginLeft: 5 }}>
      <Breadcrumbs separator={separator} aria-label="breadcrumb">
        {elements.map((el) => (
          <StyledBreadcrumb
            key={el.hash}
            style={{
              backgroundColor: window.location.hash.includes(el.hash)
                ? theme.palette.primary.main : undefined,
              color: window.location.hash.includes(el.hash)
                ? 'white' : undefined,
            }}
            component="a"
            href={`#${el.hash}`}
            label={el.label}
            icon={el.icon}
          />
        ))}
      </Breadcrumbs>
    </div>
  );
}
