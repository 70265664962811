/* eslint-disable camelcase */
import React from 'react';
import {
  Divider,
  Typography,
} from '@mui/material';
import { Formik, Form } from 'formik';
import type { PLRFormProps } from '../../../../../../types/preloan';
import { FormSchemaV3 } from '../../../../../../types/preloan/v3';
import Header from './components/Header';
import ResultLabels from './components/ResultsLabels';
import NoFilterResults from './components/NoFilterResults';
import NegotiableDebts from './components/NegotiableDebts';
import { getRedirectUrl } from '../07_lastStep/components/messageSwitches';
import { getOriginButtonLabel } from '../../formLogic';
import analytics from '../../../../../../utils/analytics';

export default function RejectedForm<T extends FormSchemaV3>(props: PLRFormProps<T>) {
  const {
    formRef,
    formSchema,
    handleEnableLoading,
  } = props;
  const [hasNegotiableDebts, setHasNegotiabeDebts] = React.useState(false);

  const handleHasNegotiableDebts = () => {
    if (formSchema.debts && formSchema.debts.length > 0) {
      return setHasNegotiabeDebts(true);
    }
    return setHasNegotiabeDebts(false);
  };

  React.useEffect(() => {
    handleHasNegotiableDebts();
    handleEnableLoading(false);
    if (formSchema.results) {
      formSchema.results.forEach((result) => {
        analytics.page(
          formSchema.isRedirect
            ? `PLR - ${formSchema.isRedirect}`
            : 'PLR - RESULTADO',
          'USUARIO RECHAZADO',
          {
            trigger: 'backend',
            productType: result.productType,
            risk_models: result.hardFilterResults.risk_models,
            lagunes: result.hardFilterResults.lagunes,
            max_age: result.hardFilterResults.max_age,
            min_age: result.hardFilterResults.min_age,
            punished_debt: result.hardFilterResults.punished_debt,
            alimony_debtor: result.hardFilterResults.alimony_debtor,
            min_emp_length: result.hardFilterResults.min_emp_length,
            min_net_salary: result.hardFilterResults.min_net_salary,
            min_brute_salary: result.hardFilterResults.min_brute_salary,
            leverage_total_debt: result.hardFilterResults.leverage_total_debt,
            max_financial_stress: result.hardFilterResults.max_financial_stress,
            product_availability: result.hardFilterResults.product_availability,
            leverage_consumer_debt: result.hardFilterResults.leverage_consumer_debt,
            min_loan_policy_amount: result.hardFilterResults.min_loan_policy_amount,
            max_dpp_financial_stress: result.hardFilterResults.max_dpp_financial_stress,
            max_mortgage_financial_stress: result.hardFilterResults.max_mortgage_financial_stress,
            protest_and_delinquency_1year: result.hardFilterResults.protest_and_delinquency_1year,
            protest_and_delinquency_5years: result.hardFilterResults.protest_and_delinquency_5years,
            external_score: result.hardFilterResults.external_score,
            pre_required_associated_product_approved: result.hardFilterResults
              .pre_required_associated_product_approved,
          },
        );
      });
    }
    if ((!formSchema.results || formSchema.results?.length === 0) && formSchema.adminComment) {
      const simulatorAvailability = !formSchema.adminComment.includes('capacidades de pago');
      const workerTypeAllowed = !formSchema.adminComment.includes('contrato de trabajo')
      && !formSchema.adminComment.includes('deshabilitada la evaluación');
      analytics.page(
        formSchema.isRedirect
          ? `PLR - ${formSchema.isRedirect}`
          : 'PLR - RESULTADO',
        'USUARIO RECHAZADO',
        {
          trigger: 'frontend',
          simulator_availability: simulatorAvailability,
          workerType_allowed: workerTypeAllowed,
          admin_rejection: !simulatorAvailability || !workerTypeAllowed,
        },
      );
    }
  }, []);
  return (
    <Formik
      innerRef={formRef}
      initialValues={formSchema}
      onSubmit={(values) => {
        analytics.track('Click Boton', {
          initiator: 'usuario',
          text: getOriginButtonLabel(formSchema.partner),
        });
        window.location.href = getRedirectUrl(values.partnerConfigurations, false, formSchema.rut);
      }}
    >
      {({
        values,
      }) => (
        <Form>
          <Header
            formSchema={values}
          />
          <div style={{
            maxHeight: '100%',
            marginTop: '20px',
            marginBottom: '5%',
          }}
          >
            <Typography
              gutterBottom
              variant="body2"
              fontWeight="bold"
            >
              ¿Cuáles son los motivos?
            </Typography>
            <Divider />
            <div style={{
              overflowY: 'auto',
              maxHeight: '200px',
              margin: 'auto',
              backgroundColor: 'whitesmoke',
            }}
            >
              {values.results && values.results.length > 0
            && (
            <Typography
              variant="body2"
              style={{ textDecoration: 'underline' }}
            >
              Resultados evaluación
            </Typography>
            )}
              <ResultLabels
                results={values.results}
                origin={{
                  originLabel: values.origin,
                  partner: values.partner,
                  partnerProducts: values.partnerProducts,
                }}
              />
            </div>
            {(!values.results || values.results?.length === 0)
          && (
          <NoFilterResults
            adminComment={values.adminComment}
          />
          )}
          </div>
          {!values.partnerProducts.some((product) => product === 'mortgage' || formSchema.origin === 'tenpo')
      && (
      <NegotiableDebts
        hasNegotiableDebts={hasNegotiableDebts}
        idNumber={values.rut}
        firstName={values.firstName}
        lastName={values.lastName}
      />
      )}

        </Form>
      )}
    </Formik>
  );
}

RejectedForm.defaultProps = {
  debts: undefined,
};
