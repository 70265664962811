import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  DialogTitle: {
    backgroundColor: theme.customPalette.opacity.primary,
    color: 'white',
  },
  Dialog: {
    '& .MuiDialog-container .MuiDialog-paper': {
      borderRadius: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-container .MuiDialog-paper': {
        margin: 0,
      },
    },
  },
  DialogContent: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },

  },
  avatar: {
    width: 90,
    [theme.breakpoints.down('sm')]: {
      width: 60,
    },

  },
  actionButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    textAlign: 'center',
    justifyItems: 'center',
    columnGap: 5,
    [theme.breakpoints.down('md')]: {
      rowGap: 15,
      gridTemplateColumns: '1fr',
    },
  },
}));

export default useStyles;
