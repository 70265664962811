/* eslint-disable camelcase */
import * as Yup from 'yup';
import { santiagoAndMobileAreaCodes, regionNumsAreaCodes, MailRegex } from '../../../utils/helpers';

const dataFormObject: {[key:string]: any} = {
  phone: Yup.object()
    .shape({
      value: Yup.string()
        .test(
          'num-required',
          'Ingrese un número de teléfono válido, 9 dígitos',
          (num: any) => {
            const cleanNum = num.replace(' ', '');
            if (cleanNum.length >= 3) {
              const schema = Yup.string().min(12).max(12);
              return schema.isValidSync(cleanNum);
            }
            return true;
          },
        )
        .test(
          'num-area-invalid',
          'Ingrese un código de área válido o celular en Chile',
          (num: any) => {
            const cleanNum = num.replace(' ', '');
            if (cleanNum.length === 12) {
              if (!santiagoAndMobileAreaCodes.includes(cleanNum[3])
            && !regionNumsAreaCodes.includes(cleanNum.slice(3, 5))) {
                return false;
              }
            }
            return true;
          },
        ),
    }),
  email: Yup.object()
    .shape({
      ok: Yup.bool(),
      value: Yup.string()
        .required('Por favor ingrese un E-mail válido')
        .matches(
          MailRegex,
          'Por favor ingrese un E-mail válido',
        )
        .test(
          'email-invalid',
          'Por favor ingrese un E-mail válido',
          (email: any, context) => {
            if (!email) {
              return false;
            }
            if (email && !context.parent.ok && email.includes('*')) {
              return false;
            }
            return true;
          },
        ),
    }),
};

const validationSchemaF = (keys: string[]) => {
  Object.keys(dataFormObject)
    .filter((key) => !keys.includes(key))
    .forEach((key) => delete dataFormObject[key]);

  const validationSchema = Yup.object().shape({ contactForm: Yup.object().shape(dataFormObject) });
  return validationSchema;
};

export default validationSchemaF;
