import React from 'react';

import useSWR from 'swr';
import fetcher from '../../utils/fetcher';
import type { ProductsData } from '../../types/products';

export default function getProducts() {
  const [productsData, setProductsData] = React.useState<ProductsData['data']|null>(null);
  const { data, error, isLoading }: {data: ProductsData,
    error?: boolean,
    isLoading: boolean} = useSWR([`${process.env.REACT_APP_API_URL}/product/user-report`, 'GET', null, null], ([url, method, body]) => fetcher(url, method, body, null));
  React.useEffect(() => {
    if (data) {
      setProductsData(data.data);
    }
  }, [data]);
  return {
    productsData,
    productsDataLoading: isLoading,
    productsDataError: error,
  };
}
