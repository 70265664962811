import React from 'react';
import { Typography } from '@mui/material';
import LoadingAvatar from '../../../../../components/LoadingAvatar';
import { SignatureFormProps } from './types';

export default function DataFetching<FormSchema>(props: SignatureFormProps<FormSchema>) {
  const { handleEnableLoading } = props;
  React.useEffect(() => handleEnableLoading(true), []);
  return (
    <div style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '90%',
    }}
    >
      <Typography variant="h5" fontWeight="bold">¡Espera un momento!</Typography>
      <br />
      <LoadingAvatar loading />
    </div>
  );
}
