/* eslint-disable camelcase */
import { ICardPaymentFormData, ICardPaymentBrickPayer } from '@mercadopago/sdk-react/bricks/cardPayment/type';
import fetcher from '../../utils/fetcher';
import type { MELIPaymentReponse } from '../../types/payment';

export default async function postMELIPayment(
  formData: ICardPaymentFormData<ICardPaymentBrickPayer>,
  productId: string,
  token: string,
) {
  const response = await fetcher(
    `${process.env.REACT_APP_API_URL}/pay/client`,
    'POST',
    { ...formData, payer: JSON.stringify(formData.payer), productId },
    token,
  ) as MELIPaymentReponse;
  return response;
}
