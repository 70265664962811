/* eslint-disable import/prefer-default-export */
import type { PostClientVerifySuccessResponse } from '../../types/verify';
import fetcher from '../../utils/fetcher';

export async function postClientVerifySuccess(userId: string, token: string) {
  const res = await fetcher(
    `${process.env.REACT_APP_API_URL}/verify/client/success`,
    'POST',
    { userId, token },
    token,
  ) as PostClientVerifySuccessResponse;
  return res;
}
