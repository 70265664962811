import React from 'react';
import {
  Grid,
} from '@mui/material';
import jwtDecode from '../../../utils/jwtDecode';
import AccountNavbar from '../../../components/AccountNavbar';
import accountStyles from '../styles';
import ReferralMenu from '../../../feature/account/ReferralMenu';
import { getReferralInfo } from '../../../queries/account';
import type { UserAccountComponent } from '../../../types/account';
import AccountDataLoading from '../../../components/DataLoading';

export default function ReferralsView(props: UserAccountComponent) {
  const classes = accountStyles();
  const { token } = props;
  const { hasLoan, hasInvestment } = jwtDecode(token);
  const { referralsData, referralsDataLoading, referralsDataError } = getReferralInfo(token);

  return (
    <>
      <AccountNavbar
        showLoan={hasLoan}
        showInvestment={hasInvestment}
        showWhatsapp={!referralsDataLoading}
        tab="Referidos"
      />
      <Grid
        container
        style={{ height: '100%' }}
      >
        {(referralsDataError || referralsDataLoading)
          && (
            <AccountDataLoading
              firstText="Estamos consultando tus referidos.."
              error={referralsDataError}
            />
          )}
        {!referralsDataError && !referralsDataLoading && referralsData
          && (
          <div className={classes.contentContainer}>
            <ReferralMenu
              referralsData={referralsData}
              token={token}
            />
          </div>
          )}
      </Grid>
    </>
  );
}
