import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackgroundPic from '../../assets/fondoweb_bancamines.webp';

const accountStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '101.5%',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${BackgroundPic})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat',
    [theme.breakpoints.down('sm')]: {
      height: '102vh',
    },
  },
  paper: {
    margin: theme.spacing(3, 15),
    padding: '30px',
    textAlign: 'center',
    minHeight: '58vh',
    maxHeight: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.customPalette.white.main,
    [theme.breakpoints.down('md')]: {
      padding: '5% 25px',
      margin: theme.spacing(3, 1),
    },
  },
  baseBackground: {
    height: '100%',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      backgroundSize: 0,
      textAlign: 'center',
    },
  },
  contentContainer: {
    marginTop: '2%',
    width: '100%',
  },
  backButton: {
    marginTop: '5px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '0.5s',
    '&:hover': {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
  },
  pacGrid: {
    paddingTop: 10,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 100,
    },
  },
}));

export default accountStyles;
