import React from 'react';
import { ReactSession } from 'react-client-session';
import {
  Grid,
} from '@mui/material';
import SetPasswordForm from '../../../feature/account/SetPasswordForm';

import accountStyles from '../styles';

export default function SetPaswordView() {
  const classes = accountStyles();
  const userData = ReactSession.get('User');
  if (!userData) {
    window.location.href = '/account/login';
  }
  const { clientToken, email, idNumber } = JSON.parse(userData);

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="center"
      alignItems="center"
    >
      <SetPasswordForm
        clientToken={clientToken}
        email={email}
        idNumber={idNumber}
      />
    </Grid>
  );
}
