import React from 'react';
import {
  Route, Redirect, Switch,
} from 'react-router-dom';
import PreLoanIndex from '../../pages/preloan';

function PreloanRoutes() {
  return (
    <Route
      render={
        ({ location }) => (
          <Switch location={location}>
            <Route exact path="/preloan" component={PreLoanIndex} />
            <Route path="/">
              <Redirect to="/preloan" />
            </Route>
          </Switch>
        )
}
    />
  );
}

export default PreloanRoutes;
