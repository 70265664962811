import React from 'react';
import { Typography, Tooltip, Theme } from '@mui/material';
import {
  makeStyles,
} from '@mui/styles';

const tooltipStyle = makeStyles((theme:Theme) => ({
  arrow: {
    color: theme.customPalette.opacity.primary,
  },
  tooltip: {
    backgroundColor: theme.customPalette.opacity.primary,
  },
}));

interface SimulatorTooltipInterface{
  children: React.ReactElement<any, any>,
  amount: number,
  originCost: number,
  totalCost: number,
  taxes: number,
}

export default function SimulatorTooltip(props:SimulatorTooltipInterface) {
  const {
    children, amount, originCost, totalCost, taxes,
  } = props;

  const classes = tooltipStyle();

  return (
    <Tooltip
      classes={classes}
      placement="top"
      arrow
      enterTouchDelay={window.innerWidth > 960 ? 500 : 0} // TODO: change: mobile=0, desktop=500
      title={(
        <>
          <Typography align="center" variant="subtitle2" component="h5">
            <span style={{ fontWeight: 700, color: 'white' }}>Costo total producto:</span>
          </Typography>
          <table>
            <tbody>
              <tr key="1">
                <td align="left" style={{ paddingRight: '8px' }}>
                  <Typography align="center" variant="body2">
                    <span style={{ color: 'white' }}>Monto otorgado</span>
                  </Typography>
                </td>
                <td align="right">
                  <Typography align="center" variant="body2">
                    <span style={{ color: 'white' }}>{amount.toLocaleString()}</span>
                  </Typography>
                </td>
              </tr>
              <tr key="2">
                <td align="left" style={{ paddingRight: '8px' }}>
                  <Typography align="center" variant="body2">
                    <span style={{ color: 'white' }}>Intereses</span>
                  </Typography>
                </td>
                <td align="right">
                  <Typography align="center" variant="body2">
                    <span style={{ color: 'white' }}>{(totalCost - amount - originCost).toLocaleString()}</span>
                  </Typography>
                </td>
              </tr>
              <tr key="3">
                <td align="left" style={{ paddingRight: '8px' }}>
                  <Typography align="center" variant="body2">
                    <span style={{ color: 'white' }}>Operación</span>
                  </Typography>
                </td>
                <td align="right">
                  <Typography align="center" variant="body2">
                    <span style={{ color: 'white' }}>{originCost.toLocaleString()}</span>
                  </Typography>
                </td>
              </tr>
              <tr key="4">
                <td align="left" style={{ paddingRight: '8px' }}>
                  <Typography align="center" variant="body2">
                    <span style={{ color: 'white' }}>Impuestos</span>
                  </Typography>
                </td>
                <td align="right">
                  <Typography align="center" variant="body2">
                    <span style={{ color: 'white' }}>{taxes.toLocaleString()}</span>
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    >
      {children}
    </Tooltip>
  );
}
