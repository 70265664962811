import React from 'react';
import {
  Grid,
} from '@mui/material';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import RefinanceOverview from '../../../feature/account/RefinanceOverview';
import useStyles from '../styles';
import AccountNavbar from '../../../components/AccountNavbar';
import { LoanOverViewType, PrepayParams, UserAccountComponent } from '../../../types/account';
import jwtDecode from '../../../utils/jwtDecode';
import { getLoan } from '../../../queries/account';
import AccountDataLoading from '../../../components/DataLoading';

export default function RefinanceView(props: UserAccountComponent) {
  const { token } = props;
  const { hasLoan, hasInvestment } = jwtDecode(token);
  const { loanId } = useParams<PrepayParams>();
  const { loanData, loanDataLoading, loanDataError } = getLoan(token, true);
  const classes = useStyles();
  const history = useHistory();
  const [type, setType] = React.useState<'refinanceRP' | 'cashout'| undefined>(undefined);
  const location = useLocation().pathname.split('/').pop();

  React.useEffect(() => {
    if (loanData) {
      if (loanDataError) {
        history.push('/account');
      }
      const foundLoan = loanData.find(
        (data: LoanOverViewType) => data.loan.id === loanId,
      );
      if (foundLoan) {
        const under75percentPaid = (foundLoan.unpaidAmount / foundLoan.totalAmount) > 0.25;
        if (!location || (location === 'cashout' && under75percentPaid)) {
          history.push('/account');
        }
        if (location === 'rate-period') {
          setType('refinanceRP');
        }
        if (location === 'cashout') {
          setType('cashout');
        }
      }
    }
  }, [loanData]);

  return (
    <>
      <AccountNavbar
        tab="Tu crédito"
        showLoan={hasLoan}
        showInvestment={hasInvestment}
      />
      <Grid
        container
        style={{ height: '100%' }}
        className={classes.baseBackground}
      >
        {(loanDataLoading)
          && (
            <AccountDataLoading
              firstText="Estamos consultando tus datos..."
            />
          )}
        {loanData && type && (
        <RefinanceOverview
          token={token}
          type={type}
        />
        )}
      </Grid>
    </>
  );
}
